<template>
    <div style="height: 100%">
        <el-container class="home_container">
            <el-header class="max_width phone_el_head">
                <router-link to="/">
                    <img class="head_top_left phone_page_bg" src="../assets/img/csiweb.png" alt="csi" />
                    <img class="head_top_left computer_right_cont_box" src="../assets/img/logo.png" alt="csi" />
                </router-link>

                <span class="head_sign" @click="loginPop()" v-if="!loginStatus">
                    <div v-show="window.innerWidth < 821" placement="top" width="0" popper-class="">
                        <div class="phone_head_sin" @click="loginPop">
                            {{ loginStatus ? 'Sign out' : 'Sign in' }}
                            <img style="height: 20px; margin-left: 10px" src="../assets/img/liebiao2.png" alt="" />
                        </div>
                    </div>
                    <div v-show="window.innerWidth > 821" placement="top" width="0" popper-class="">
                        <div class="head_login sinin" slot="reference">
                            <span class="switch_txt" @click="loginPop">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                    </div>
                </span>
                <template v-else>
                    <el-popover placement="top" width="230" popper-class="pop_top_account">
                        <!-- my Account -->
                        <div class="head_login" :class="userRole == 'company' ? 'company_role' : 'person_role'" slot="reference">
                            <span class="switch_txt">{{ loginStatus ? 'Sign out' : 'Sign in' }}</span>
                        </div>
                        <div class="head_account">
                            <!-- <div class="top_usage flex_center_between_box"> -->
                            <div class="top_usage">
                                <div class="top_usage-head">
                                    <div class="usage_ic">
                                        <img v-if="userRole == 'company'" src="../assets/img/head_switch_company_ic.png" />
                                        <img v-else src="../assets/img/head_switch_person_ic.png" />
                                    </div>
                                    <span class="ellipsistext" style="font-size: 14px; color: #022955">{{ ucemail }}</span>
                                </div>
                                <div class="usage_prog">
                                    <div class="flex_center_between_box">
                                        <div class="usage_txt">Usage</div>
                                        <div class="usage_total">{{ account.used | FilterNum }} used</div>
                                    </div>
                                    <el-progress v-if="account.total > 0" :show-text="false" :stroke-width="5" define-back-color="#ffffff" :color="customColorMethod" :percentage="parseFloat(((1 - account.used / (account.total + account.used)) * 100).toFixed(2))"></el-progress>

                                    <div class="usage_used" style="display: flex; justify-content: space-between" :class="{ parnterr: percentageerr }">
                                        <div>{{ account.total | FilterNum }}/{{ account.used | FilterNum }}</div>
                                        <div v-if="account.total == 0 && account.used == 0">0</div>
                                        <div v-else>BL: {{ parseFloat((account.total / (account.total + account.used)) * 100).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top_acc_menu">
                                <ul class="top_acc_menu_box">
                                    <div class="computer_right_cont_box">
                                        <li v-for="(menu, index) in accountMenuList" :key="index" class="userinfo-li">
                                            <router-link :to="menu.url" v-if="menu.type != 0" :class="{ parnterr: menu.name == 'Add Credit' && percentageerr, parnterr1: menu.name == 'Add Credit' && !percentageerr }">
                                                <div class="usage_icone usage_ic">
                                                    <img :src="menu.img" />
                                                </div>
                                                {{ menu.name }}
                                            </router-link>
                                        </li>
                                    </div>
                                    <div class="phone_page_bg">
                                        <li v-for="(menu, index) in accountMenuList" :key="index" class="userinfo-li" v-show="menu.name == 'Add Credit' || menu.name == 'My Database' || menu.name == 'FAQ'">
                                            <router-link :to="menu.url" v-if="menu.type != 0" :class="{ parnterr: menu.name == 'Add Credit' && percentageerr, parnterr1: menu.name == 'Add Credit' && !percentageerr }">
                                                <div class="usage_icone usage_ic">
                                                    <img :src="menu.img" />
                                                </div>
                                                {{ menu.name }}
                                            </router-link>
                                        </li>
                                    </div>
                                    <li class="acc_signout" @click="signOutAccount">
                                        <div class="usage_icone usage_ic">
                                            <img src="../assets/img/user-icons/1.png" />
                                        </div>
                                        Sign out
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </el-popover>
                </template>
            </el-header>
            <!-- 内容 -->
            <el-main :style="{ backgroundImage: `url(${imgUrl})` }">
                <div class="max_width home-phone">
                    <h1 class="title">Bringing Global Business Into Focus</h1>
                    <h4 class="subtitle">Search 500+ million companies in 200+ countries and territories</h4>
                    <!-- 条件  -->
                    <div class="search_option flex_center phone_home">
                        <el-radio-group v-model="searchOption" @change="handleRadioChange" class="phoneflex_center">
                            <el-radio-button :label="item.name" v-for="(item, index) in searchOptionList" :key="index" :class="item.bgIco">
                                {{ item.name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="search_box">
                        <div class="search flex_center" :class="{ search2: seachdoudong }" ref="input">
                            <!-- 选择国家 -->
                            <div class="search_left" :class="countryImg ? 'on' : ''" v-show="searchOption != 'Search Products' && searchOption != 'Search Sanctions'">
                                <div class="country_flag">
                                    <img :class="countryImg ? 'country_flag_img' : 'none'" :src="countryImg" />
                                    <!-- <span class="sel_place" v-show="countryName ? false : true">{{ searchOption== 'Search Products'?'Search Products': 'Select Country'}}</span> -->
                                    <!-- <span class="sel_value line_2" v-show="countryName ? true : false">{{ countryName }}</span> -->
                                </div>
                                <el-select v-model="countryCode" clearable @clear="clearCountry" ref="mySelect" placeholder="Select Country" popper-class="" @change="selectCountry" no-match-text="No data" no-data-text="No data">
                                    <div class="search_sel_container">
                                        <el-tabs tab-position="left" v-model="tabActiveName">
                                            <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                                                <div slot="label" class="flex_center_between_box">
                                                    <span class="left_txt">{{ tab.continent.enName }}</span>
                                                    <!-- <span class="left_txt" v-if="index==1">Latin America and the Caribbean</span> -->
                                                    <span class="right_txt">{{ tab.continent.countryNumStr }}</span>
                                                </div>
                                                <div class="right">
                                                    <el-option v-for="item in tab.countryList" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode" @click.native="labelClick(item.nameEn)">
                                                        <div class="right_item flex_box">
                                                            <div class="img flex_center">
                                                                <img :src="item.urlBanner" />
                                                            </div>
                                                            <div class="rBox">
                                                                <div class="coun_title">{{ item.nameEn }}</div>
                                                                <div class="coun_num" v-if="show == 0">{{ item.companyNumStr }}</div>
                                                                <div class="coun_num" v-if="show == 2">{{ item.tradeNumStr }}</div>
                                                                <div class="coun_num" v-if="show == 3">{{ item.peopleNum }}</div>
                                                            </div>
                                                        </div>
                                                    </el-option>
                                                </div>
                                            </el-tab-pane>
                                        </el-tabs>
                                    </div>
                                </el-select>
                            </div>
                            <!-- 选择产品 -->
                            <div class="search_left phoneseach_left" v-if="searchOption == 'Search Products'">
                                <!-- <div class="country_flag">
                  <span class="sel_place" v-show="productChose.length != 0 ? false : true">{{ searchOption }}</span>
                </div> -->
                                <el-cascader clearable class="pro_cas" ref="cascaderProducts" @change="handleChange" placeholder="Select Products" collapse-tags v-model="productChose" :options="productOptions" :props="optionsProps" :show-all-levels="false" popper-class="sel_product">
                                    <template slot-scope="{ data }">
                                        <el-tooltip popper-class="totip" effect="dark" placement="top" :content="data.nameEn">
                                            <span>{{ data.nameEn }}</span>
                                        </el-tooltip>

                                        <!-- <span>{{ data }}</span> -->
                                    </template>
                                </el-cascader>
                            </div>
                            <!-- 制裁搜索 -->
                            <div class="search_left" v-show="searchOption == 'Search Sanctions'">
                                <el-select class="search_left_sactions" v-model="sanctionsvalue" slot="prepend" placeholder="Enter company name">
                                    <el-option v-for="item in searchoptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <!-- 搜索 -->
                            <div v-show="show == 0" class="search_right flex_center">
                                <input type="text" v-model="searchName" placeholder="Enter company name" class="search_input" @keyup.enter="searchResult()" />
                            </div>
                            <div v-if="show == 1" class="search_right flex_center product">
                                <el-autocomplete ref="autocomplete" class="search_input" :highlight-first-item="true" :debounce="300" :trigger-on-focus="false" :autofocus="true" v-model="searchProName" :fetch-suggestions="querySearch" placeholder="Enter product name" @select="handleSelect"></el-autocomplete>
                                <!-- <input type="text" v-model="searchProName" placeholder="Enter product name" class="search_input" @keyup.enter="searchResult()" /> -->
                            </div>
                            <div v-show="show == 2" class="search_right flex_center">
                                <input type="text" v-model="searchName" placeholder="Enter keyword" class="search_input" @keyup.enter="searchResult()" />
                            </div>
                            <div v-show="show == 3" class="search_right flex_center">
                                <input type="text" v-model="searchName" placeholder="Enter person name" class="search_input" @keyup.enter="searchResult()" />
                            </div>
                            <div v-show="show == 4" class="search_right flex_center">
                                <!-- :readonly="true" -->
                                <input type="text" v-model="searchName" placeholder="Enter country name" class="search_input" @input="searchResult()" @click="searchResult()" />
                            </div>
                            <div v-show="show == 5" class="search_right flex_center">
                                <input type="text" v-model="searchName" placeholder="Enter keyword" class="search_input" @keyup.enter="searchResult()" />
                            </div>
                            <div v-show="show == 6" class="search_right flex_center">
                                <input type="text" v-model="searchName" placeholder="Enter Sactions name" class="search_input" @keyup.enter="searchResult()" />
                            </div>
                            <div v-if="show == 1" class="search_btn phone_btn flex_center" @click="productsearch">
                                <img :src="window.innerWidth < 821 ? require('../assets/img/head_search_ic.png') : require('../assets/img/home_search_ic.png')" />
                                <b v-if="window.innerWidth > 821">Search</b>
                            </div>
                            <div v-else class="search_btn phone_btn flex_center" @click="searchResult">
                                <img :src="window.innerWidth < 821 ? require('../assets/img/head_search_ic.png') : require('../assets/img/home_search_ic.png')" />
                                <b v-if="window.innerWidth > 821">Search</b>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 手机端引流内容 -->
                <div class="max_width phone_page_bg">
                    <div class="intelligence">
                        <div class="intelligence-title">A powerful search engine for global business intelligence.</div>
                        <div class="intelligence-content">
                            <ul>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Identify companies instantly
                                </li>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Find out company’s contact info
                                </li>

                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Verify company’s registration records
                                </li>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Understand ownership info
                                </li>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Review business activities including impor and
                                    <br />
                                    <span class="intelligence-right">export records</span>
                                </li>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Performance and credit evaluation
                                </li>
                                <li>
                                    <span class="intelligence-left">✓</span>
                                    Available public info
                                </li>
                            </ul>
                        </div>
                        <div class="intelligence-imgone" style="width: 85%; margin: auto">
                            <img src="../assets/img/caiwu.png" alt="" />
                        </div>
                        <div class="intelligence-industries">
                            <div class="intelligence-title">A useful resource for organizations of all sizes in most industries.</div>
                            <div class="intelligence-industries-content">
                                <ul>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Financial Institutions
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Insurance
                                    </li>

                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Investment Management
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Manufacture
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Trade
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Commerce
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Data Management
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Education
                                    </li>
                                    <li>
                                        <span class="intelligence-left">✓</span>
                                        Organizations
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="intelligence-imgone" style="width: 85%; margin: auto">
                            <img src="../assets/img/caiwu2.png" alt="" />
                        </div>
                        <div class="intelligence-data-content">
                            <div class="intelligence-title">Data engineering</div>

                            <ul>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>We capture more than 500 million companies covering more than 200 countries and territories</span>
                                </li>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>We collect data from all available sources, and work with reliable partners all around the world.</span>
                                </li>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>We process and analyze a large amount of data to make data meaningful information.</span>
                                </li>
                            </ul>
                        </div>
                        <div class="intelligence-industries">
                            <div class="intetitle-box">
                                <h2 class="intetitle1">500+m</h2>
                                <span>Companies</span>
                            </div>
                            <div class="intetitle-box">
                                <h2 class="intetitle2">200+m</h2>
                                <span>Countries and territories</span>
                            </div>
                            <div class="intetitle-box">
                                <h2 class="intetitle3">150+m</h2>
                                <span>Performance</span>
                            </div>
                            <div class="intetitle-box">
                                <h2 class="intetitle4">340+m</h2>
                                <span>Relationships</span>
                            </div>
                            <div class="intetitle-box">
                                <h2 class="intetitle5">220+m</h2>
                                <span>KYC</span>
                            </div>
                            <div>
                                <h2 class="intetitle6">1.2+b</h2>
                                <span>Import & Export</span>
                            </div>
                        </div>
                    </div>
                    <div class="intelligence-data-anywhere">
                        <div class="anywhere-box">
                            <div class="intelligence-titleone">Mobility, security and transparency</div>
                            <div class="intelligence-title intelligence-titletwo">Access CSI Platform from anywhere</div>
                            <div class="intelligence-imgone intelligence-adding">
                                <img src="../assets/img/app1.png" alt="" />
                                <!-- <img class="inter-imgstore" src="../assets/img/applestore.png" alt="" />
                                <img class="inter-imgtwo" src="../assets/img/goolepay.png" alt="" /> -->
                                <div class="intelligence-imgtitle">With the native iOS and Android apps, enable your clients to interact with your firm from any location. Using their phones, users can search, view, save, download, export, order, manage accounts and requires services from the client portal or the mobile app.</div>
                            </div>
                        </div>
                    </div>
                    <div class="intelligence-data-anywhere">
                        <div class="anywhere-box">
                            <div class="intelligence-titleone">Client service</div>
                            <div class="intelligence-title intelligence-titletwo">We focus on a customer-centric approach</div>
                            <ul class="appproach">
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>Leading business information partner to high quality client base.</span>
                                </li>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>Client-aligned corporate structure, ensuring each client receives total care from service-oriented staff..</span>
                                </li>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span>Offer best in class service coverage, information reliability, speedy delivery, competitive pricing, and value added services.</span>
                                </li>
                                <li class="data-content-li">
                                    <span class="intelligence-left">✓</span>
                                    <span class="intelligence-color">More</span>
                                </li>
                            </ul>
                        </div>
                        <div class="max_width phone_csi phone_page_bg">
                            <div class="foot_left">
                                <b style="color: #1290c9">©</b>
                                Copyright 2023 Company Search, Inc. All Rights Reserved
                                <li class="phone_csi_li"><router-link to="/help/aboutUs">About</router-link></li>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 刻度 -->
                <div class="line max_width phonemax_width">
                    <div class="line_item" v-for="(item, index) in lineScale" :key="index">
                        <div class="scale"></div>
                        <div class="scale_num">{{ item.num }}</div>
                        <div class="scale_txt">{{ item.country }}</div>
                    </div>
                    <div class="line_item last_line">
                        <div class="scale"></div>
                    </div>
                </div>
            </el-main>

            <!-- 底部 -->
            <el-footer>
                <div class="max_width foot_box">
                    <div class="foot_left">Copyright 2023 Company Search, Inc. & CSIbizSearch.com v.1.0.1 All Rights Reserved</div>
                    <ul class="foot_right flex_box">
                        <li><router-link to="/help/aboutUs" class="link">About</router-link></li>
                        <li><router-link to="/help/privacy#my-anchor" class="link">Privacy</router-link></li>
                        <li><router-link to="/help/terms" class="link">Terms</router-link></li>
                    </ul>
                </div>
            </el-footer>
        </el-container>

        <Login :loginAndCreatePop.sync="loginDialog"></Login>
        <Phonetocsi v-if="window.innerWidth < 821" :suoClose="moreclose" @closeCloseDialog="closmoreDialog($event)"></Phonetocsi>
    </div>
</template>
<script>
import { LoginOut } from '@/api/api.js';
import { getCountryCollection } from '@/api/companyApi.js';
import { selectProductTypeCode, getProductTreeList } from '@/api/searchApi.js';
import bus from '@/components/bus';
import Login from '@/components/login';
import { mixins1 } from '@/mixins/index';
import Phonetocsi from '@/components/phonetocsi.vue';
export default {
    mixins: [mixins1],
    components: { Login, Phonetocsi },
    data() {
        return {
            code: '',
            imgUrl: require('../assets/img/bg1.png'),
            imgUrltwo: require('../assets/img/headbg.png'),
            searchOption: 'Search Company',
            show: 0,
            seachdoudong: false,
            percentageerr: false,
            currentUrl: window.location.href,
            window: window,
            searchOptionList: [
                { bgIco: 'bgIco1', name: 'Search Company' },
                { bgIco: 'bgIco2', name: 'Search Products' }, //选择搜索条件
                { bgIco: 'bgIco3', name: 'Search Trade' },
                // { bgIco: 'bgIco4', name: 'Search Person' },

                { bgIco: 'bgIco5', name: 'Search Country' },
                { bgIco: 'bgIco6', name: 'Search News' },
                { bgIco: 'bgIco7', name: 'Search Sanctions' },
            ],
            lineScale: [
                //    { num: '-12', country: '' },
                // { num: '-11', country: 'Lisbon' },
                { num: '-11', country: 'Hawaii' },
                { num: '-10', country: 'Alaska' },
                { num: '-9', country: 'Vancouver' }, //刻度
                { num: '-8', country: 'Phoenix' },
                { num: '-7', country: 'Chicago' },
                { num: '-6', country: 'New York' },
                { num: '-5', country: 'Santiago' },
                { num: '-4', country: 'Rio' },
                { num: '-3', country: 'Greenland' },
                { num: '-2', country: 'Praia' },
                { num: '-1', country: 'Lisbon' },
                { num: '0', country: 'London' },
                { num: '+1', country: 'Berlin' },
                { num: '+2', country: 'Cairo' },
                { num: '+3', country: 'Moscow' },
                { num: '+4', country: 'Dubai' },
                { num: '+5', country: 'New Delhi' },
                { num: '+6', country: 'Naypyidaw' },
                { num: '+7', country: 'Bangkok' },
                { num: '+8', country: 'Beijing' },
                { num: '+9', country: 'Tokyo' },
                { num: '+10', country: 'Sydney' },
                { num: '+11', country: 'Anadyr' },
                { num: '+12', country: 'Wellington' },
                // { num: '-11', country: '' },
            ],
            loginDialog: false, //登录弹框
            searchName: '',
            searchProName: '',
            //国家分类
            tabCountryList: [],
            countryName: '', //选中国家
            countryImg: '', //选中国家的国旗
            tabActiveName: '', //选择的区域
            productChose: '',
            productOptions: [],
            optionsProps: {
                label: 'nameEn', //名称展示
                value: 'displayCode', //值展示
                // checkStrictly: true,     //是否严格的遵守父子节点不互相关联
                expandTrigger: 'click', //次级菜单的展开方式
                emitPath: false,
            },
            countryCode: '',
            countryCode1: '',
            account: { total: '', used: '' },
            loginStatus: false, //登录状态
            menuInfos: [],
            token: localStorage.getItem('token'),
            userRole: {},
            searchoptions: [
                {
                    value: 'Entity',
                    label: 'Company',
                },
                {
                    value: 'Person',
                    label: 'Person',
                },
            ],
            sanctionsvalue: '',
            ucemail: JSON.parse(localStorage.getItem('userInfo'))?.userInfo.loginName,
            moreclose: false,
        };
    },
    destroyed() {
        bus.$off('handleLogin');
    },
    created() {
        this.getAllCountry();
        //登陆状态
        this.token ? (this.loginStatus = true) : (this.loginStatus = false);
        //登陆状态
        bus.$on('handleLogin', loginCheck => {
            this.loginStatus = loginCheck;
            const user = JSON.parse(localStorage.getItem('userInfo'));
            this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
            //account
            this.getAccountMsg();
        });
        if (this.token) {
            const user = JSON.parse(localStorage.getItem('userInfo'));
            this.userRole = user.roleInfos[0].sysRoleInfo.name == '企业' || user.roleInfos[0].sysRoleInfo.name == '集团' ? 'company' : 'person';
            //account
            this.getAccountMsg();
        }
    },
    mounted() {
        this.padaun();
    },

    methods: {
        handleBlur() {
            if (this.searchName == '') {
                this.seachdoudong = true;
                this.$refs.input.classList.add('shake');
                setTimeout(() => {
                    this.$refs.input.classList.remove('shake');
                }, 500);
            }
        },
        padaun() {
         
            if(this.$route.query.id==undefined){
                this.searchOption='Search Company'
                this.show=0
            }else{
                this.searchOption=this.$route.query.id
                this.show=this.$route.query.show
            }
            
            if (this.account.total == 0) {
                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);
            }
        },
        // 控制积分进度条颜色
        customColorMethod(percentage) {
            if (percentage < 5 || this.account.total == 0) {
                this.percentageerr = true;
                this.$emit('parterr', this.percentageerr);
                return '#f56c6c';
            } else if (percentage < 10) {
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#e6a23c';
            } else {
                this.percentageerr = false;
                this.$emit('parterr', this.percentageerr);
                return '#1290c9';
            }
        },
        closmoreDialog(value) {
            this.moreclose = value;
        },
        // 产品选择搜索跳转
        handleSelect(item) {
            this.code = item.display_code;

            this.searchResult();
        },
        //   处理产品点击搜索无参数问题
        productsearch() {
            this.code = this.$refs.autocomplete.suggestions[0].display_code;
            this.searchProName = this.$refs.autocomplete.suggestions[0].value;
            this.searchResult();
        },
        querySearch(queryString, cb) {
            if (queryString !== '') {
                const param = {
                    keyword: this.searchProName,
                    pageNum: 1,
                    pageSize: 369,
                };
                selectProductTypeCode(param)
                    .then(res => {
                        const data = res.data;
                        if (res.code) {
                            data.rows.forEach(item => {
                                item.val = item.display_code;
                                item.value = item.en;

                                // item.val=item.value
                            });
                            cb(data.rows);
                        } else {
                            cb([]);
                        }
                    })
                    .catch(() => {
                        cb([]);
                    });
            }
        },
        //获取products 菜单
        async handleRadioChange() {
            this.seachdoudong = false;

            this.tabCountryList = JSON.parse(localStorage.getItem('countryList'));

            if (JSON.parse(localStorage.getItem('countryList'))) {
                let list = this.tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        // let countryImg = this.getCountryImgUrl(it.countryCode)
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        // let flag = require('../../static' + countryImg);
                        it.urlBanner = flag;
                    });
                });
            }
            let page = this.searchOption;
            if (page == 'Search Company') {
                this.show = 0;
            }
            if (page == 'Search Products') {
                this.show = 1;
                // 处理清除缓存问题 产品为空
                this.productOptions = JSON.parse(localStorage.getItem('ProductTreeList'));
                this.$store.state.productOptions = JSON.parse(localStorage.getItem('ProductTreeList'));
            }
            if (page == 'Search Trade') {
                this.show = 2;
            }
            if (page == 'Search Person') {
                this.show = 3;
            }
            if (page == 'Search Country') {
                this.show = 4;
            }
            if (page == 'Search News') {
                this.show = 5;
            }
            if (page == 'Search Sanctions') {
                this.show = 6;
            }
            if (this.window.innerWidth < 821) {
                if (this.searchOption == 'Search Trade' || this.searchOption == 'Search Sanctions') {
                    this.searchOption = 'Search Company';
                    this.show = 0;
                    this.moreclose = true;
                }
            }
        },
        //获取国家
        async getAllCountry() {
            // 处理缓存问题
            this.tabCountryList = JSON.parse(localStorage.getItem('countryList'));

            if (JSON.parse(localStorage.getItem('countryList'))) {
                let list = this.tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        // let countryImg = this.getCountryImgUrl(it.countryCode)
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        //    console.log(countryImg);
                        //    let flag = require('../../static' + countryImg);
                        let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        // let flag = require('../../static' + countryImg);
                        it.urlBanner = flag;
                    });
                });
            }

            // 处理清除缓存问题 国家为空
            let res = await getCountryCollection();

            if (res && res.code) {
                if( JSON.parse(localStorage.getItem('countryList')) == null){
                        let countryImgList = res.data.rows;
                localStorage.setItem('countryList',JSON.stringify(countryImgList))
                }
                this.$store.state.countryImgList = JSON.parse(localStorage.getItem('countryList'));
                this.tabCountryList = JSON.parse(localStorage.getItem('countryList')) == null ? res.data.rows : JSON.parse(localStorage.getItem('countryList')); //res.data.rows
                let list = this.tabCountryList;

                list.forEach((item, index) => {
                    item.countryList.forEach((it, index) => {
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        // let flag = require('../../static' + countryImg);
                        let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                    });
                });
            }
            // 处理缓存问题
            this.productOptions = JSON.parse(localStorage.getItem('ProductTreeList'));
            this.$store.state.productOptions = JSON.parse(localStorage.getItem('ProductTreeList'));
            if (JSON.parse(localStorage.getItem('ProductTreeList')) == null) {
                let res1 = await getProductTreeList('deep=4');
                if (res1 && res1.code) {
                    localStorage.setItem('ProductTreeList',JSON.stringify(res1.data))
                    this.productOptions = JSON.parse(localStorage.getItem('ProductTreeList')) == null ? res.data1 : JSON.parse(localStorage.getItem('ProductTreeList')); //res.data.rows
                }
            }
        },
        //清除选择的国家
        clearCountry() {
            this.countryImg = '';
            this.countryCode = '';
            this.countryCode1 = '';
        },
        //下拉选择国家
        selectCountry(val) {
            let countryImg = this.getCountryImgUrl(val);
            // let flag = require('../../static' + countryImg);
            let flag = '';
            try {
                flag = require('../../static' + countryImg);
            } catch (error) {
                countryImg = '/imagev2/zanwutupian.png';
                // console.error('Error loading country image:', error);
                // 提供一个默认值或者其他处理方式
                flag = require('../../static' + countryImg);
            }
            this.countryImg = flag;

            this.countryCode = val;

            let newscountry = this.tabCountryList[0].countryList;
            newscountry.map(item => {
                if (item.countryCode == val) {
                    this.countryCode1 = item.dowjonesCountrycode;
                }
            });
        },
        labelClick(label) {
            this.countryName = label;
            if (this.searchOption == 'Search Country') {
                if (this.countryCode) {
                    this.$router.push({ path: '/country', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Please Select Country',
                        center: true,
                        type: 'error',
                    });
                }
            }
        },
        //登录弹窗
        loginPop() {
            this.loginDialog = true;
        },
        //产品
        handleChange() {
            this.searchProName = '';

            this.searchResult();
        },
        //搜索按钮
        searchResult() {
            this.handleBlur();

            let page = this.searchOption;
            if (page == 'Search Company') {
                if (this.searchName) {
                    this.$router.push({ path: '/search/searchCompany', query: { searchname: this.searchName, companyCountry: this.countryCode, keword: 1, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter company name',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Products') {
                let labName = this.$refs['cascaderProducts'].getCheckedNodes()[0] ? this.$refs['cascaderProducts'].getCheckedNodes()[0].value : '';
                let labNameselect = this.$refs['cascaderProducts'].getCheckedNodes()[0] ? this.$refs['cascaderProducts'].getCheckedNodes()[0].label : '';

                if (this.searchProName) {
                    labName = '';
                    this.$router.push({ path: '/search/searchProduct', query: { searchname: this.code, searchcode: labName, seach: this.searchProName, companyCountrydqs: this.countryCode1, selectlable: labNameselect } });
                } else if (this.productChose) {
                    this.$router.push({ path: '/search/searchProduct', query: { searchname: this.code, searchcode: labName, seach: this.searchProName, companyCountrydqs: this.countryCode1, selectlable: labNameselect } });
                } else {
                    this.code = '';
                    this.$message({
                        message: 'Please Select Products',
                        center: true,
                        type: 'error',
                    });
                }
                // selproduct: productName
            } else if (page == 'Search Trade') {
                if (this.searchName) {
                    this.$router.push({ path: '/search/searchTrade', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Person') {
                if (this.searchName) {
                    this.$router.push({ path: '/search/searchPerson', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter person name',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Country') {
                this.filterMethod(this.searchName, this.tabActiveName);

                // if (this.countryCode) {
                //     this.$router.push({ path: '/country', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                // } else {
                //     this.$message({
                //         message: 'Please Select Country',
                //         center: true,
                //         type: 'error',
                //     });
                // }
            } else if (page == 'Search News') {
                if (this.searchName) {
                    this.$router.push({ path: '/search/searchNews', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1 } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            } else if (page == 'Search Sanctions') {
                if (this.searchName) {
                    this.$router.push({ path: '/search/searchSanctions', query: { searchname: this.searchName, companyCountry: this.countryCode, companyCountrydqs: this.countryCode1, type: this.sanctionsvalue } });
                } else {
                    this.$message({
                        message: 'Enter keyword',
                        center: true,
                        type: 'error',
                    });
                }
            }
        },
        filterMethod(query, index) {
            const select = this.$refs.mySelect;

            // 处理缓存问题
            let tabCountryList = JSON.parse(localStorage.getItem('countryList'));

            if (JSON.parse(localStorage.getItem('countryList'))) {
                let list = tabCountryList;
                list.forEach((item, index) => {
                    item.countryList.forEach(it => {
                        // let countryImg = this.getCountryImgUrl(it.countryCode)
                        let countryImg = '';
                        // /imagev2/zanwutupian.png
                        if (it.urlBanner == null) {
                            countryImg = '/imagev2/zanwutupian.png';
                        } else {
                            countryImg = it.urlBanner.split('/static')[1];
                        }
                        console.log(countryImg);
                        // let flag = require('../../static' + countryImg);
                        let flag = '';
                        try {
                            flag = require('../../static' + countryImg);
                        } catch (error) {
                            countryImg = '/imagev2/zanwutupian.png';
                            // console.error('Error loading country image:', error);
                            // 提供一个默认值或者其他处理方式
                            flag = require('../../static' + countryImg);
                        }
                        it.urlBanner = flag;
                        // if (countryCode && it.countryCode == countryCode) {
                        //     this.countryName = it.nameEn;
                        //     this.countryImg = it.urlBanner;
                        // }
                    });
                });
            }

            this.tabCountryList[index].countryList = tabCountryList[index].countryList.filter(item => {
                return item.nameEn.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            let that = this;
            this.$nextTick(() => {
                that.$refs.mySelect.visible = true;
            });
        },
        //Sign out 登出
        signOutAccount() {
            const userCode = JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode;

            LoginOut({ username: userCode }).then(res => {
                if (res.code) {
                    this.$message({
                        message: 'Exit successfully',
                        center: true,
                        type: 'success',
                    });
                    sessionStorage.clear();
                    localStorage.removeItem('token');
                    window.location.href = '/';
                }
            });
        },
    },
};
</script>
<style>
/* .el-select-dropdown__empty {
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 20px;
  color: #022955;
} */
</style>
<style lang="css" scoped>
/* 登录 */
.parnterr {
    color: red !important;
}
.product .search_input /deep/ .el-input__inner {
    border: none !important;
    padding-left: 0 !important;
    font-size: 16px;
}
.head_login {
    position: relative;
    margin-left: 30px;
    width: 98px;
    height: 34px;
    /* background-color: r(12,17,15); */
    /* background: #006a9a;
     */
    background: #8ec9e4;
    /* box-shadow: inset 0px 1px 2px 0px #054562; */
    border-radius: 17px;
}
.head_login::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
}

.head_login .el-switch {
    height: 34px;
    line-height: 34px;
}

.head_login /deep/ .el-switch__core {
    width: 98px !important;
    height: 34px;
    background: #006a9a;
    box-shadow: inset 0px 1px 2px 0px #054562;
    border-radius: 17px;
    border: none;
}
.head_login /deep/ .el-switch__core:after {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    background: #ffffff;
}
.head_login.person_role::after,
.head_login.company_role::after {
    left: 67px;
    /* background-color: #ff7600; */
    background-color: rgb(0, 49, 7);
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: center;
}
.head_login.company_role::after {
    background-image: url(../assets/img/head_switch_company_ic.png);
}
.head_login.person_role::after {
    background-image: url(../assets/img/head_switch_person_ic.png);
}
.head_login.company_role .switch_txt,
.head_login.person_role .switch_txt {
    left: 12px;
}
.switch_txt {
    position: absolute;
    left: 35px;
    top: 10px;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
}
/* 菜单 */
.head_menu_box {
    width: 100%;
    height: 48px;
    background: #fff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.head_menu_list {
    display: flex;
    justify-content: center;
}
.head_menu_list /deep/ .el-radio-button__inner {
    padding: 16px 0px 12px;
}
.head_menu_list /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #1290c9;
    background-color: #fff;
    border-color: #1290c9;
    box-shadow: none;
}
.head_menu_item /deep/ .el-radio-button__inner {
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    line-height: 18px;
    font-size: 14px;
    color: #022955;
}
.head_menu_item {
    line-height: 20px;
    font-size: 14px;
    /* padding: 18px 30px 10px 30px; */
    text-align: center;
    /* margin: 0 30px; */
    padding: 0 30px;
}
.search_option /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none !important;
}
.top_usage {
    padding: 15px 10px;
    background: #f2f5f9;
    border-radius: 14px;
    margin-bottom: 7px;
}
.usage_prog {
    flex: 1;
}
.usage_ic {
    width: 26px;
    height: 26px;
    line-height: 26px;
    /* background: #ffffff; */
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
    background-color: rgb(0, 49, 7);
}
.usage_ic img {
    width: 12px;
    height: 12px;
}
.usage_icone {
    background: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usage_icone img {
    width: 16px;
    height: 16px;
}
.usage_txt {
    font-size: 14px;
    color: #022955;
    margin-bottom: 8px;
}
.usage_total {
    font-size: 12px;
    color: #8497ab;
}
.usage_used {
    margin-top: 8px;
    font-size: 12px;
    color: #1290c9;
}
.acc_signout,
.top_acc_menu_box li a {
    display: block;
    padding: 8px 10px;
    font-size: 14px;
    color: #022955;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover,
.top_acc_menu a.router-link-exact-active.router-link-active {
    color: #1290c9;
}
.acc_signout:hover,
.top_acc_menu_box li a:hover {
    background: #ebf2ff;
    border-radius: 14px;
}
.head_login,
.acc_signout {
    cursor: pointer;
}

.el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px !important;
    background-color: #fff;
    margin: auto;
    padding: 0;
}
.head_sign {
    cursor: pointer;
    color: #022955;
    line-height: 50px;
}
.head_top_left {
    width: 162px;
    height: 26px;
    cursor: pointer;
}
.el-main {
    height: 72vh;
    min-height: 750px;
    background-size: cover !important;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    padding: 0;
    overflow: visible;
}
.title {
    margin: 126px 0 20px;
    font-size: 46px;
    font-family: 'Arial Bold';
    color: #ffffff;
    line-height: 54px;
    text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.85);
    text-align: center;
}
.subtitle {
    font-size: 18px;
    color: #ffffff;
    line-height: 21px;
    margin-bottom: 84px;
    text-align: center;
}
.search_box {
    width: 1200px;
    height: 120px;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 20px;
    margin: auto;
    padding: 25px 30px;
}
.search {
    width: 100%;
    height: 70px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #dddddd;
    /* border: 1px solid red;  */
    color: #022955;
}
.search2 {
    border: 1px solid red !important;
    transition: border-color 0.3s ease-in-out;
    /* color: #022955; */
}
.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}
.search_right {
    flex: 1;
    position: relative;
}
.search_input {
    width: 100%;
    word-wrap: break-word;
    line-height: 30px;
    outline: none;
    border: none;
    padding: 0 20px;
    font-size: 16px;
}
.search_btn {
    width: 160px;
    height: 70px;
    margin-right: -7px;
    background: #1290c9;
    border-radius: 0px 20px 20px 0px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
}
.search_btn img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.el-radio-button {
    width: 160px;
    height: 40px;
    background: rgba(32, 57, 77, 0.73);
    border-radius: 12px;
    margin: 0 5px 24px;
}
.search_option /deep/.el-radio-button__inner {
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 15px;
}
.search_option /deep/ .el-radio-button:first-child .el-radio-button__inner {
    border: none;
}
.search_option /deep/.el-radio-button__inner {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: #ffffff;
    padding-left: 36px;
}
.bgIco1 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_ic1.png);
}
.bgIco2 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_ic2.png);
}
.bgIco3 /deep/.el-radio-button__inner {
    background-position: 27px;
    padding-left: 49px;
    background-image: url(../assets/img/seach_ic3.png);
}
.bgIco4 /deep/.el-radio-button__inner {
    background-position: 23px;
    padding-left: 44px;
    background-image: url(../assets/img/seach_ic4.png);
}
.bgIco5 /deep/.el-radio-button__inner {
    background-position: 21px;
    padding-left: 43px;
    background-image: url(../assets/img/seach_ic5.png);
}
.bgIco6 /deep/.el-radio-button__inner {
    background-position: 28px;
    padding-left: 49px;
    background-image: url(../assets/img/seach_ic6.png);
}
.bgIco7 /deep/.el-radio-button__inner {
    background-position: 17px;
    padding-left: 38px;
    background-image: url(../assets/img/782041.png);
}

.el-radio-button.is-active {
    background-image: url(../assets/img/home_seach_bg.png);
    background-size: 160px 47px;
    background-repeat: no-repeat;
    background-color: transparent;
    height: 47px;
}
.search_option /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: transparent;
    border: none;
    color: #1290c9;
}
.el-radio-button.is-active.bgIco1 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic1.png);
}
.el-radio-button.is-active.bgIco2 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic2.png);
}
.el-radio-button.is-active.bgIco3 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic3.png);
}
.el-radio-button.is-active.bgIco4 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic4.png);
}
.el-radio-button.is-active.bgIco5 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic5.png);
}
.el-radio-button.is-active.bgIco6 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/seach_on_ic6.png);
}
.el-radio-button.is-active.bgIco7 /deep/.el-radio-button__inner {
    background-image: url(../assets/img/zhcai.png);
}
.line {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: -35px;
    margin-left: 50%;
    transform: translate(-50%, 0);
    z-index: 9;
}
.line_item {
    position: relative;
    flex-direction: column;
    flex: 1;
}
.last_line {
    flex: 0;
}
.scale {
    width: 1px;
    height: 6px;
    background: #8497ab;
}
.scale_txt,
.scale_num {
    width: 100%;
    text-align: center;
}
.scale_num {
    font-size: 12px;
    color: #022955;
    line-height: 14px;
    margin-bottom: 4px;
}
.scale_txt {
    font-size: 10px;
    color: #1290c9;
    line-height: 11px;
}
.el-footer {
    width: 100%;
    height: 300px !important;
    padding: 0;
}
.foot_box {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
    font-size: 14px;
}
.foot_box::before {
    content: '';
    position: absolute;
    top: 0;
    left: -64px;
    width: 1590px;
    height: 100%;
    background: url(../assets/img/foot_bg.png) no-repeat;
    background-size: cover;
}
.foot_left {
    z-index: 1;
}
.foot_right li {
    position: relative;
    padding: 0 10px;
}
.foot_right li:not(:last-child)::after {
    position: absolute;
    right: 0;
    top: 4px;
    content: '';
    width: 1px;
    height: 10px;
    background-color: #dddddd;
}
/* 选择下拉 */
.pro_cas,
.search_left {
    width: 172px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}
.search_left::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    width: 1px;
    height: 30px;
    background-color: #dddddd;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-input__inner {
    border: none;
    font-size: 16px;
}
.country_flag {
    display: flex;
    align-items: center;
}
.country_flag_img {
    width: 36px;
    height: 22px;
    margin: 22px 6px 24px 20px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.sel_value {
    width: 74px;
    font-size: 16px;
    word-break: break-all;
}
.pro_cas,
.search_left .el-select {
    position: absolute;
    /* top: 0; */
    display: flex;
    align-items: center;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-select .el-input__inner {
    background-color: transparent;
    /* height: 68px !important; */
    /* opacity: 0; */
    padding-left: 18px;
}
.search_left.on /deep/ .el-select .el-input__inner {
    padding-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
    color: #022955;
}
.sel_place {
    width: 100%;
    line-height: 68px;
    text-align: center;
    display: inline-block;
}
.el-select-dropdown__wrap {
    max-height: 375px !important;
}
.el-select-dropdown__list {
    padding: 10px 0 !important;
}
.search_sel_container {
    width: 1220px;
    /* width: 1200px; */
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
    width: auto;
    min-width: 240px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background-color: #eee;
}
.search_sel_container /deep/ .el-tabs__active-bar {
    display: none;
}
.search_sel_container .right {
    /* width: 959px; */
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 340px;
    align-content: flex-start;
    margin-right: 1px;
    padding: 0 12px;
    overflow: auto;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
    height: 42px;
    line-height: 42px;
    margin-bottom: 8px;
    padding: 0 10px !important;
}
.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
    background: rgba(18, 144, 201, 0.1);
}
.el-select-dropdown__item:hover {
    background: rgba(18, 144, 201, 0.1);
}

.search_sel_container /deep/ .el-tabs__item:hover {
    color: #1290c9;
    font-weight: normal;
}
.search_sel_container .left_txt {
    font-size: 16px;
}
.search_sel_container .right_txt {
    font-size: 12px;
    color: #8497ab;
}
.search_sel_container .right .el-select-dropdown__item {
    width: 222px;
    height: 56px;
    padding: 0 10px;
    margin: 10px 5px;
    border: 1px solid #fff;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
    background: rgba(18, 144, 201, 0.1);
    border-radius: 14px;
    border: 1px solid #1290c9;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
    color: #022955;
    font-weight: normal;
}
.right_item {
    height: 100%;
    width: 100%;
}
.search_sel_container .right .img {
    width: 54px;
    height: 100%;
    margin-right: 5px;
}
.search_sel_container .right .img img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.search_sel_container .right .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search_sel_container .right .rBox .coun_title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search_sel_container .right .rBox .coun_num {
    font-size: 12px;
    line-height: 1;
    color: #8497ab;
}
.search_option /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    box-shadow: none;
}

@media (max-width: 1600px) {
    .line {
        bottom: -46px;
    }
    .foot_box::before {
        width: 1390px;
        background-size: 100%;
    }
}
</style>
<style>
.el-icon-arrow-down:before {
    color: #022955 !important;
    font-weight: 600 !important;
}

.pro_cas .el-cascader__tags {
    left: 10px;
}
.pro_cas .el-cascader__tags .el-tag {
    margin: 2px 0 2px 2px;
    max-width: 72%;
}
.pro_cas .el-tag.el-tag--info {
    color: #022955;
    height: 30px;
    padding: 0 4px;
    font-size: 16px;
}
.el-cascader-menu {
    width: 238px;
    color: #022955;
    border-color: #eee;
}
.el-cascader-menu__wrap {
    height: 380px;
}
.el-cascader-node {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
}
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
    color: #1290c9;
    font-weight: 500;
}
.sel_product .el-checkbox__inner {
    width: 20px;
    height: 20px;
}
.sel_product .el-checkbox__inner::after {
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 12px;
    left: 6px;
    top: 1px;
    width: 5px;
}
.sel_product .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    top: 7px;
}
.sinin {
    background: #bbbbbb !important;
}
.sinin .switch_txt {
    position: absolute;
    left: 42px;
    top: 10px;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
}
.head_login.sinin::after {
    left: 3px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: center;
}
.top_usage-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.userinfo-li {
    list-style: none;
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */
}
.parnterr1 {
    color: rgb(85, 180, 25) !important;
}
.el-select-dropdown__item {
    color: #022955;
}
/* [v-cloak]{
display:none !important;
} */
.totip {
    z-index: 9999999 !important;
}
@media (max-width: 821px) {
    .search_box {
        width: 98% !important;
        padding: 0 15px !important;
        background: none !important;
        /* height: auto !important; */
    }
    .home_container {
        height: 100% !important;
    }
    .home-phone .title {
        font-size: 16px !important;
        margin-top: 46px !important;
        font-family: math !important;
        font-style: italic;
    }
    .subtitle {
        font-size: 16px !important;
    }
    .phoneflex_center {
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
        justify-content: center;
        margin-left: 280px;
        /* overflow: auto; */
    }
    .phone_btn {
        font-size: 12px !important;
        width: 15% !important;
        background-color: #fff !important;
        height: 49px !important;
    }
    .phone_btn img {
        width: 12px !important;
        height: 12px !important;
    }
    .search_left {
        width: 30% !important;
    }
    .country_flag_img {
        width: 22px !important;
        height: 12px !important;
        margin: 22px 6px 24px 10px !important;
    }
    .search_sel_container {
        width: 100% !important;
    }
    .search_left.on .el-select .el-input__inner {
        padding-left: 38px !important;
    }
    .search_left .el-input__inner {
        border: none !important;
        font-size: 12px !important;
    }
    .search_sel_container .el-tabs__nav-wrap.is-left {
        width: 170px !important;
    }
    .search_sel_container .right {
        width: 100% !important;
    }
    .right .rBox .coun_title {
        font-size: 12px !important;
        max-width: 95px !important;
    }
    .search_sel_container .right .img {
        width: 30px !important;
    }
    .phoneseach_left {
        display: none !important;
    }
    .el-autocomplete-suggestion {
        width: 336px !important;
    }
    .search_option {
        /* display: none; */
        overflow-x: scroll;
    }
    .search_option::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0;
    }

    .search_sel_container .right .el-select-dropdown__item {
        height: 36px !important;
    }
    .search_input {
        font-size: 14px !important;
    }
    .phone_el_head {
        background-image: url('../assets/img/headbg.png');
        width: 100% !important;
    }
    .head_top_left {
        width: 112px !important;
        height: 56px !important;
    }
    .phone_head_sin {
        font-size: 14px;
        color: rgb(255, 255, 255);
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .subtitle {
        margin-bottom: 44px !important;
        width: 230px;
        margin: auto;
    }
    .phone_home .el-radio-button.is-active {
        background-size: 160px 105px !important;
        background-position-x: center !important;
        height: 80px !important;

        /* background-size: 145px 47px !important; */
    }
    .phone_home .el-radio-button.is-active::after {
        content: '';
        position: absolute;
        /* background-color: #fff; */
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #fff;
        /* border:8px solid transparent;
    border-top-color: #fff; */
    }
    .search_option .el-radio-button__inner {
        padding: 35px 12px !important;
        white-space: pre-wrap !important;
    }
    .phone_home .el-radio-button {
        width: 95px !important;
        height: 80px !important;
    }
    .head_login {
        /* background: rgb(66 66 69/70%) !important; */
        background: transparent !important;

        /* background-image: url('../assets/img/anjiubeijing.png'); */
    }
    .search {
        height: 50px !important;
        border-radius: 20px !important;
    }
    .search_left::after {
        top: 10px !important;
    }
    .el-radio-button.is-active.bgIco1 .el-radio-button__inner {
        background-image: url(../assets/img/company.png) !important;
        background-position: 40px 10px;
        background-size: 22px !important;
    }
    .el-radio-button.is-active.bgIco2 .el-radio-button__inner {
        background-image: url(../assets/img/seach_on_ic2.png) !important;
        background-position: 40px 10px;
    }
    .el-radio-button.is-active.bgIco3 .el-radio-button__inner {
        background-image: url(../assets/img/youlun.png) !important;
        background-position: 40px 10px;
        background-size: 22px !important;
    }
    .el-radio-button.is-active.bgIco4 .el-radio-button__inner {
        background-image: url(../assets/img/icon_website_huabanfuben.png);
        background-position: 40px 10px;
        background-size: 22px !important;
    }
    .el-radio-button.is-active.bgIco5 .el-radio-button__inner {
        background-image: url(../assets/img/seach_on_ic5.png);
        background-position: 40px 10px;
    }
    .el-radio-button.is-active.bgIco6 .el-radio-button__inner {
        background-image: url(../assets/img/seach_on_ic6.png);
        background-position: 40px 10px;
    }
    .el-radio-button.is-active.bgIco7 .el-radio-button__inner {
        background-image: url(../assets/img/zhcai.png);
        background-position: 47px 10px;
    }
    .bgIco1 .el-radio-button__inner {
        background-position: 40px 10px !important;
        background-image: url(../assets/img/company1.png) !important;
        background-size: 22px !important;
    }
    .bgIco2 .el-radio-button__inner {
        background-image: url(../assets/img/seach_ic2.png);
        background-position: 40px 10px !important;
    }
    .bgIco3 .el-radio-button__inner {
        background-position: 40px 10px !important;
        background-image: url(../assets/img/youlun1.png) !important;
        background-size: 22px !important;
    }
    .bgIco4 .el-radio-button__inner {
        background-position: 23px;
        padding-left: 44px;
        background-image: url(../assets/img/icon_website_huabanfuben1.png) !important;
        background-size: 22px !important;
    }
    .bgIco5 .el-radio-button__inner {
        background-position: 40px 10px !important;
        background-image: url(../assets/img/seach_ic5.png);
    }
    .bgIco6 .el-radio-button__inner {
        background-position: 40px 10px !important;
        background-image: url(../assets/img/seach_ic6.png);
    }
    .bgIco7 .el-radio-button__inner {
        background-position: 40px 10px !important;
        background-image: url(../assets/img/782041.png);
    }
    .phone_csi {
        /* position: absolute;
        bottom: 40px; */
        font-size: 12px !important;
        /* color: #fff; */
        padding: 20px 10px;
    }
    .phone_csi_li {
        float: right;
    }
    .phone_csi_li a {
        /* color: #1290c9; */
        color: #022955;
    }
    .search_box .search_left .el-select {
        width: 100% !important;
    }
    .intelligence {
        padding: 0px 20px !important;
    }
    .intelligence-title {
        font-size: 20px;
        color: #1290c9;
    }
    .intelligence-content {
        font-size: 14px !important;
        color: white;
        margin: 60px 0px;
    }
    .intelligence-content li {
        line-height: 20px;
    }
    .intelligence-left {
        margin-right: 10px;
    }
    .intelligence-right {
        margin-left: 25px;
    }
    .intelligence-imgone {
        width: 100%;
    }
    .intelligence-industries {
        text-align: center;
        margin: 80px auto;
    }
    .intelligence-industries-content {
        font-size: 14px !important;

        text-align: left;

        display: flex;
        justify-content: center;
    }
    .intelligence-industries-content li {
        line-height: 20px;
    }
    .intelligence-data-content {
        margin: 80px 0px;
    }
    .data-content-li {
        display: flex;
        font-size: 14px !important;
        line-height: 25px;
    }
    .intetitle1 {
        color: #1290c9;
        font-size: 42px !important;
    }
    .intetitle2 {
        color: #ffbf00;
        font-size: 42px !important;
    }
    .intetitle3 {
        color: #fe4951;
        font-size: 42px !important;
    }
    .intetitle4 {
        color: #7dd432;
        font-size: 42px !important;
    }
    .intetitle5 {
        color: #006fc6;
        font-size: 42px !important;
    }
    .intetitle6 {
        color: #808080;
        font-size: 42px;
    }
    .intetitle-box {
        margin-bottom: 20px;
    }
    .intelligence-titleone {
        color: #1290c9;
    }
    .intelligence-titletwo {
        color: #000000;
        margin: 10px 0px;
    }
    .anywhere-box {
        padding: 20px !important;
        padding-top: 40px !important;
    }
    .intelligence-data-anywhere {
        background-color: #e6f1fc !important;
    }
    .intelligence-imgtitle {
        font-size: 14px !important;
        line-height: 25px;
        background-color: #fff;
        position: absolute;
        bottom: 35px;
        padding: 20px;
        height: 200px;
    }
    .intelligence-adding {
        position: relative;
    }
    .intelligence-adding img {
        height: 475px;
        width: 100%;
    }
    .appproach {
        margin-bottom: 370px !important;
    }
    .inter-imgtwo,
    .inter-imgstore {
        width: 60px !important;
        height: 25px !important;
    }
    .inter-imgstore {
        position: absolute;
        left: 60px;
        bottom: 4%;
        z-index: 9;
    }
    .inter-imgtwo {
        position: absolute;
        right: 60px;
        bottom: 4%;
        z-index: 9;
    }
    .search_btn {
        border-radius: 0px 40px 40px 0px !important;
    }
}
</style>
