<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu" v-if="isFadedIn">
            <button class="btn_Retr" @click="RetrievalResult">
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>
            <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()">
                <el-collapse-item title="Country" name="2">
                    <template slot="title">
                        Country
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <input type="text" v-model="cascInptCountry" v-show="showInptCountry" class="cas_inpt" placeholder="Select Country" @input="inptCountrySearch" />
                        <el-cascader ref="Country" placeholder="Select Country" :key="cascaderKey" v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" :props="{ multiple: true }" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                    <span class="casca_lab">
                                        <span ref="sty">{{ data.label }}</span>
                                    </span>
                                </el-tooltip>

                                <!-- <span class="casca_lab">{{ data.label }}</span> -->
                                <!-- <span class="casca_count">({{ data.count }})</span> -->
                                <el-tooltip class="casca_count" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.count | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>

                <el-collapse-item title="Industry" name="3">
                    <template slot="title">
                        Industry
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt" placeholder="Select Industry" @input="inptIndSearch" />
                        <el-cascader ref="inptIndSearch" v-model="industryCheckList" clearable :key="cascaderKey" :options="industryList" :props="optionsProps" @change="menuCheck('Industry')" placeholder="Select Industry" popper-class="cas_loc_box indystry">
                            <template slot-scope="{ data }">
                                <el-tooltip class="" effect="dark" :content="data.name" placement="top-start">
                                    <span class="casca_lab">{{ data.name }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Type" name="4">
                    <template slot="title">
                        Company Type
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companyTypeCheckList" @change="menuCheck('companyType')">
                            <el-checkbox v-for="(child, i) in companyTypeList" :key="i" :label="child.name">
                                <!-- <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span> -->
                                <el-tooltip :disabled="isShowTooltip" class="line_1 coll_child_name" effect="dark" :content="child.name" placement="top-start">
                                    <div @mouseover="onMouseOver(child.name, i)" class="line_1 coll_child_name">
                                        <span ref="str">{{ child.name }}</span>
                                    </div>
                                </el-tooltip>
                                <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum" placement="top-start">
                                    <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                </el-tooltip>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Size" name="5">
                    <template slot="title">
                        Company Size
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                            <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>

                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Status" name="6">
                    <template slot="title">
                        Company Status
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                            <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Listed Company" name="7">
                    <template slot="title">
                        Listed Company
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="listedCompanyCheckList" @change="menuCheck('listedCompany')">
                            <el-checkbox v-for="(child, i) in listedCompanyList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Years in Business" name="8">
                    <template slot="title">
                        Years in Business
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                            <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.name">
                                <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <div class="right_box">
            <!-- 筛选 -->
            <div class="top_condition flex_center_between_box">
                <div class="condition_lab_box" style="display: flex">
                    <i class="zhankai" @click="applefilters" style="margin-right: 10px"><img src="../../assets/img/guolv.png" alt="" /></i>
                    <div :class="{ condition_labb: true, condition_lab: isActive }" class="computer_right_cont_box" @click="tabshow">
                        <template>
                            <el-tag v-for="tag in countryTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'countryTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in arrProvTag" :key="tag + '_countryTag'" @close="handleClose(tag, 'arrProvTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in industryTag" :key="tag.value + '_industryTag'" @close="handleClose(tag, 'industryTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyTypeTag" :key="tag.value + '_companyTypeTag'" @close="handleClose(tag, 'companyTypeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companySizeTag" :key="tag.value + '_companySizeTag'" @close="handleClose(tag, 'companySizeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyStatusTag" :key="tag.value + '_companyStatusTag'" @close="handleClose(tag, 'companyStatusTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in listedCompanyTag" :key="tag.value + '_listedCompanyTag'" @close="handleClose(tag, 'listedCompanyTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in timeTag" :key="tag.value + '_timeTag'" @close="handleClose(tag, 'timeTag')" closable>{{ tag.name }}</el-tag>
                        </template>
                        <!-- <span class="clear_lab" v-if="isActive" @click="tabshow()" style="margin-right: 20px ;font-size: 16px; ">......</span> -->
                        <span class="clear_lab" @click="clearAllTags">Clear All</span>
                    </div>
                </div>
                <div class="flex_center">
                    <div class="top_ic_btn no_login" v-show="show == 0 || show == 3 ? true : false" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div>

                    <div class="btn_export computer_right_cont_box flex_center" v-show="show == 0 || show == 3 ? true : false" @click="exportResult">
                        <img src="../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                    <div class="btn_export flex_center" style="margin-left: 10px; width: 160px" v-show="show == 0 || show == 3 ? true : false" @click="findbusines">Find Business Partners</div>
                </div>
            </div>
            <div class="right_tabcard" v-if="findtablelist">
                <div class="right_tabcard_box">
                    <!-- :disabled="kaiguan" -->
                    <button :class="index === show ? 'tabcard_box_xuan' : 'tabcard_box_text'" v-for="(item, index) in tabcardlist" :key="index" @click="tabcard(index)">{{ item }}</button>
                </div>

                <!-- <span @click="aaaa()" class="tmp"></span> -->
            </div>
            <!-- 电脑端-->
            <!-- Search Results数据 -->
            <div v-loading="loading" v-show="show == 0" class="right_cont_box computer_right_cont_box">
                <div class="table_box">
                    <!-- <div class="tbl_search_box" v-show="true">
                        <div class="tbl_search">
                            <el-autocomplete class="table_add" @change="xuanze(state)" :trigger-on-focus="false" :autofocus="true" v-model="state" :fetch-suggestions="querySearch" placeholder="enter product" @select="handleSelect" @blur="Losefocus">
                                <i slot="suffix" @click="xuanze(state)" class="el-input__icon el-icon-search"></i>
                            </el-autocomplete>
                        </div>
                    </div> -->
                    <el-table ref="treeTable" :data="parentTable" border style="width: 100%">
                        <!-- 空数据状态的插槽 -->
                        <template v-if="ret" slot="empty"><div style="padding: 20px; text-align: left; line-height: 40px">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div></template>
                        <template v-else slot="empty">
                            <noDate />
                        </template>

                        <el-table-column label="Company Name" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <div v-show="showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                    <img @click="gotocountry(scope.row)" style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                    {{ scope.row.company_name_en }}
                                </div>
                                <div v-show="!showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                    <img @click="gotocountry(scope.row)" style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                    {{ scope.row.company_name }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Location" width="200" align="left" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div style="width: 180px" v-show="showTranslate" class="line_1">
                                    {{ scope.row.city_en }}
                                    <i v-show="scope.row.province_en && scope.row.province_en != ' ' && scope.row.province_en != '' && scope.row.city_en != '' && scope.row.city_en && scope.row.city_en != ' '">,</i>
                                    {{ scope.row.province_en }}
                                </div>
                                <div style="width: 180px" v-show="!showTranslate" class="line_1">
                                    {{ scope.row.city_cn }}
                                    <i v-show="scope.row.province && scope.row.province != ' ' && scope.row.province != '' && scope.row.city_cn != '' && scope.row.city_cn != ' ' && scope.row.city_cn">,</i>
                                    {{ scope.row.province }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Products" width="180" :prop="showTranslate == true ? 'company_product_en' : 'company_product_en'" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div style="width: 160px" class="line_1">
                                    {{ scope.row.productsseach }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Co.Size" width="100" :prop="showTranslate == true ? 'scale_en' : 'scale_en'" align="center" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Revenue" width="140" prop="revenue_en" align="center" show-overflow-tooltip :resizable="false" />

                        <el-table-column label="" prop="operation" align="center" width="80" :resizable="false">
                            <template slot-scope="scope">
                                <div class="table_oper on" v-if="scope.row.isFavorite" @click="addToList(scope.row)">
                                    <img src="../../assets/img/search/table_ic1.png" />
                                </div>
                                <div class="table_oper" v-else @click="addToList(scope.row)">
                                    <img src="../../assets/img/search/table_ic2.png" />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page_i_box">
                        <Page :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>

                        <div class="search_rsult_txt">
                            {{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "
                            <div class="line_3">{{ $route.query.seach == '' ? $route.query.selectlable : $route.query.seach }}</div>
                            "
                        </div>
                    </div>
                </div>
            </div>
            <div v-loading="loading" v-show="show == 1" class="computer_right_cont_box">
                <div class="right_cont_box1">
                    <div class="table_box4">
                        <div style="padding: 20px 10px 20px 20px; width: 100%">
                            <el-table ref="treeTable" :data="TopCountry" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">
                                    <noDate />
                                </template>
                                <el-table-column label="#" type="index" align="center" show-overflow-tooltip :resizable="false" />

                                <el-table-column label="Country" align="left" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span @click="gotocountry1(scope.row)" exact class="tbl_company line_1">{{ scope.row.countryEn }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Transactions" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="%" prop="percent" align="center" show-overflow-tooltip :resizable="false" />
                            </el-table>
                        </div>
                        <div class="echart">
                            <div ref="trendChart1" class="trendEchartBox1" />
                            <div class="map">
                                <div ref="trendChart2" class="trendEchartBox1" />
                                <!-- <GmapMap :center='center' :zoom='12' ref="mapRef" class="map-main">
        <GmapMarker @dragend="updateMaker" :key="index" v-for="(marker, index) in markers" :position="marker.position" :clickable="true" :icon="marker.markerData" @click="center=marker.position" class="map-marker" :label="marker.label"></GmapMarker>
      </GmapMap> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-loading="loading" v-show="show == 2" class="computer_right_cont_box">
                <div class="right_cont_box1">
                    <div class="table_box4">
                        <div style="padding: 20px 10px 20px 20px; width: 100%">
                            <el-table ref="treeTable" :data="CompanySize" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">
                                    <noDate />
                                </template>
                                <el-table-column label="#" type="index" align="center" show-overflow-tooltip :resizable="false" />

                                <el-table-column label="Company Size" align="left" :prop="showTranslate == true ? 'companySizeEn' : 'companySizeEn'" show-overflow-tooltip :resizable="false"></el-table-column>
                                <el-table-column label="Transactions" prop="transcations" align="left" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.transcations | FilterNum }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="%" prop="percent" align="center" show-overflow-tooltip :resizable="false" />
                            </el-table>
                        </div>
                        <div class="echart">
                            <div ref="trendChart" class="trendEchartBox1" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-loading="loading" v-show="show == 3" class="computer_right_cont_box">
                <div class="right_cont_box">
                    <div class="table_box">
                        <el-table ref="treeTable" :data="players" border style="width: 100%">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <noDate />
                            </template>
                            <el-table-column label="#" type="index" align="center" width="80" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Company Name" width="400" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div v-show="showTranslate" @click="setid1(scope.row)" class="tbl_company line_1">{{ scope.row.companyNameEn }}</div>
                                    <div v-show="!showTranslate" @click="setid1(scope.row)" class="tbl_company line_1">{{ scope.row.companyName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Country" align="center" :prop="showTranslate == true ? 'countryEn' : 'countryCn'" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <!-- <span v-show="!showTranslate" @click="gotocountry1(scope.row)"  exact class="tbl_company line_1">{{ scope.row.countryCn }}</span> -->
                                    <span @click="gotocountry1(scope.row)" exact class="tbl_company line_1">{{ scope.row.countryEn }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Inc.Date" prop="incDate" align="right" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Transaction" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.transcations | FilterNum }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- 手机端 -->

            <div class="phone_page_bg" v-if="findtablelist" v-loading="loading">
                <div class="phone_right_cont_box  line_2 phone_page_bg" v-if="show == 0" style="margin-bottom:0px">
                    {{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "
                    {{ $route.query.seach == '' ? $route.query.selectlable : $route.query.seach }}
                    "
                </div>
                <div class="phone_right_cont_box">
                    <div v-if="show == 0" class="table_box">
                        <ul class="phone_table_box">
                            <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                                <img style="margin-top: 5px" :src="item.flag" alt="" />
                                <div class="phone_table_box_item_right">
                                    <h1 class="tbl_company1 line_1" v-show="showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                        <div class="line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name_en }}</div>

                                        <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                            <img src="../../assets/img/search/table_ic1.png" />
                                        </div>
                                        <div class="table_oper" v-else @click="addToList(item)">
                                            <img src="../../assets/img/search/table_ic2.png" />
                                        </div>
                                    </h1>
                                    <h1 class="tbl_company1 line_1" v-show="!showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                        <div class="line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name }}</div>

                                        <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                            <img src="../../assets/img/search/table_ic1.png" />
                                        </div>
                                        <div class="table_oper" v-else @click="addToList(item)">
                                            <img src="../../assets/img/search/table_ic2.png" />
                                        </div>
                                    </h1>
                                    <h2 class="line_1">
                                        <b style="color: #69c867">{{ item.status_en }}</b>
                                        | {{ item.productsseach }}
                                    </h2>
                                    <h2 class="line_1">
                                        <div v-show="!showTranslate" class="line_1">
                                            {{ item.city_cn }}
                                            <span class="tbl_dot" v-show="item.province && item.province != ' ' && item.province != '' && item.city_cn != '' && item.city_cn != ' ' && item.city_cn">,</span>
                                            {{ item.province }}
                                        </div>
                                        <div v-show="showTranslate" class="line_1">
                                            {{ item.city_en }}
                                            <span class="tbl_dot" v-show="item.province_en && item.province_en != ' ' && item.province_en != '' && item.city_en != '' && item.city_en != ' ' && item.city_en">,</span>
                                            {{ item.province_en }}
                                        </div>
                                    </h2>
                                    <h2 class="line_1" @click="gotourl(item.web_site)">
                                        Website:
                                        <i style="text-decoration: underline; color: #1290c9; cursor: pointer">{{ item.web_site }}</i>
                                    </h2>
                                    <h2 class="line_1">
                                        <span>Revenue:{{ item.revenue_en }}</span>
                                    </h2>
                                    <h2 class="line_1">
                                        <span>Sales:({{ item.update_year }}) {{ item.value_sales_en }}</span>
                                        <span style="float: right">{{ item.incorporation_date }}</span>
                                    </h2>
                                </div>
                            </li>
                                <div v-if="ret && parentTable.length==0" style="padding: 20px; text-align: left; line-height: 40px">if the search results are not as you expected, please revise your search keywords and use advanced selection criteria in the left panel to enhance your search result. Meanwhile, you can contact our service team to report errors or for assistance.</div>
                        </ul>
                        <div class="page_i_box">
                            <Page :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                        </div>
                    </div>
                    <div v-show="show == 1" class="table_box">
                        <div class="right_cont_box1">
                            <div class="table_box4" style="flex-wrap: wrap">
                                <div style="width: 100%">
                                    <el-table ref="treeTable" :data="TopCountry" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <template slot="empty">
                                            <noDate />
                                        </template>
                                        <el-table-column label="#" type="index" align="center" show-overflow-tooltip :resizable="false" />

                                        <el-table-column label="Country" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span @click="gotocountry1(scope.row)" exact class="tbl_company1 line_1">{{ scope.row.countryEn }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Transactions" prop="transcations" align="center" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.transcations | FilterNum }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="%" prop="percent" align="center" show-overflow-tooltip :resizable="false" />
                                    </el-table>
                                </div>
                                <div class="echart">
                                    <div ref="trendChartphone1" class="trendEchartBox1" />
                                    <div class="map">
                                        <div ref="trendChartphone2" class="trendEchartBox1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="show == 2" class="table_box">
                        <div class="right_cont_box1">
                            <div class="table_box4" style="flex-wrap: wrap">
                                <div style="width: 100%">
                                    <el-table ref="treeTable" :data="CompanySize" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <template slot="empty">
                                            <noDate />
                                        </template>
                                        <el-table-column label="#" type="index" align="center" show-overflow-tooltip :resizable="false" />

                                        <el-table-column label="Company Size" align="left" :prop="showTranslate == true ? 'companySizeEn' : 'companySizeEn'" show-overflow-tooltip :resizable="false"></el-table-column>
                                        <el-table-column label="Transactions" prop="transcations" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span>{{ scope.row.transcations | FilterNum }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="%" prop="percent" align="center" show-overflow-tooltip :resizable="false" />
                                    </el-table>
                                </div>
                                <div class="echart">
                                    <div ref="trendphoneChart" class="trendEchartBox1" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="show == 3" class="table_box">
                        <div class="right_cont_box">
                            <div class="table_box">
                                <ul class="phone_table_box">
                                    <li class="phone_table_box_item" v-for="(item, index) in players" :key="index">
                                        <div class="phone_table_box_item_right">
                                            <h1 class="tbl_company line_1" @click="setid(item)">
                                                 <div class="line_1" style="max-width: 255px" @click="setid(item)">{{ item.companyNameEn }}</div>
                                            </h1>

                                            <h2 class="line_1">Country: {{ item.countryEn }}</h2>
                                            <h2 class="line_1">
                                                <span>Revenue:{{ item.revenue_en }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Transaction:({{ item.transcations }})</span>
                                                <span style="float: right">{{ item.incDate }}</span>
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 寻找伙伴列表 -->
            <div class="phone_page_bg" v-else v-loading="loading">
                <div class="table_box phone_table_box">
                    <ul class="phone_table_box" v-loading="findloding">
                        <li class="phone_table_box_item" v-for="(item, index) in findpartnerTable" :key="index">
                            <img :src="item.flag" alt="" />
                            <div class="phone_table_box_item_right">
                                <h1 class="tbl_company1 line_1" v-show="showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                    <div class="line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name_en }}</div>

                                    <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic1.png" />
                                    </div>
                                    <div class="table_oper" v-else @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic2.png" />
                                    </div>
                                </h1>
                                <h1 class="tbl_company1 line_1" v-show="!showTranslate" style="display: flex; justify-content: space-between; align-items: center">
                                    <div class="line_1" style="max-width: 255px" @click="setid(item)">{{ item.company_name }}</div>

                                    <div class="table_oper on phpne" v-if="item.isFavorite" @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic1.png" />
                                    </div>
                                    <div class="table_oper" v-else @click="addToList(item)">
                                        <img src="../../assets/img/search/table_ic2.png" />
                                    </div>
                                </h1>
                                <h2 class="line_1">
                                    <b style="color: #69c867">{{ item.status_en }}</b>
                                    | {{ item.industry_en }}
                                </h2>
                                <h2 class="line_1">
                                    <div v-show="showTranslate" class="line_1">
                                        {{ item.city_cn }}
                                        <span class="tbl_dot" v-show="item.province && item.province != ' ' && item.province != '' && item.city_cn != '' && item.city_cn != ' ' && item.city_cn">,</span>
                                        {{ item.province }}
                                    </div>
                                    <div v-show="!showTranslate" class="line_1">
                                        {{ item.city_en }}
                                        <span class="tbl_dot" v-show="item.province_en && item.province_en != ' ' && item.province_en != '' && item.city_en != '' && item.city_en != ' ' && item.city_en">,</span>
                                        {{ item.province_en }}
                                    </div>
                                </h2>
                                <h2 class="line_1" @click="gotourl(item.web_site)">
                                    Website:
                                    <i style="text-decoration: underline; color: #1290c9; cursor: pointer">{{ item.web_site }}</i>
                                </h2>
                                <h2 class="line_1">
                                    <span>Revenue:{{ item.revenue_en }}</span>
                                </h2>
                                <h2 class="line_1">
                                    <span>Sales:({{ item.update_year }}) {{ item.value_sales_en }}</span>
                                    <span style="float: right">{{ item.incorporation_date }}</span>
                                </h2>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- add to List -->
        <Collection :addList.sync="addList" :fromPage="fromPage" @getCollData="getCollData" @childCloseDialog="closeDialog1(arguments)" :addPop="addPopIndex" :collecObj="collecObj"></Collection>

        <!-- 点击导入 -->
        <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog" :append-to-body="true">
            <div class="large_detail_cont" v-loading="exportLoding">
                <div class="dialog_detail_title dialog_detail_export">Please confirm your export infromation</div>
                <div class="company_box" v-if="show != 3">
                    <div class="company-head">Filter Information:</div>
                    <div>
                        <ul class="company-body">
                            <li class="company-item condition_labb">
                                <div style="width: 60px">Country :</div>

                                <el-tag v-for="tag in countryTag" :key="tag">{{ tag }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 105px">Total Records :</div>
                                <span v-if="show == 0">{{ page.total | FilterNum }}</span>
                                <span v-else>10</span>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 60px">Industry :</div>
                                <div style="display: flex">
                                    <el-tag v-for="tag in industryTag" :key="tag.code + '_imporindustryTagtTag'">{{ tag.name }}</el-tag>
                                </div>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 120px">Company Type :</div>
                                <el-tag v-for="tag in companyTypeTag" :key="tag.code + '_companyTypeTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 110px">Company Size :</div>
                                <div style="display: flex">
                                    <el-tag v-for="tag in companySizeTag" :key="tag.code + '_companySizeTag'">{{ tag.name }}</el-tag>
                                </div>
                            </li>

                            <li class="company-item condition_labb">
                                <div style="width: 120px">Company Status:</div>
                                <el-tag v-for="tag in companyStatusTag" :key="tag.code + '_companyStatusTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 110px">Listed Company:</div>
                                <el-tag v-for="tag in listedCompanyTag" :key="tag.code + '_listedCompanyTag'">{{ tag.name }}</el-tag>
                            </li>
                            <li class="company-item condition_labb">
                                <div style="width: 120px">Years in Business:</div>
                                <el-tag v-for="tag in timeTag" :key="tag.code + '_Business'">{{ tag.name }}</el-tag>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="company-head">Data Information :</div>
                <div style="color: #333333; margin-left: 15px">
                    The maximum you can export is
                    <span style="color: red">200</span>
                    records. Should you need to export entire records, please click "Request Data Service"
                    <br />
                    for further assistance.
                </div>
                <div class="btn_comp_box">
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="show == 3 ? json_fields : json_field" :name="show == 3 ? 'Top Players' : 'Search Results.xls'">
                        <button class="btn_export" @click="exportCompany">Export(Max200)</button>
                    </download-excel>
                    <div class="btn_export flex_center" style="margin-left: 20px; width: 160px">
                        <a href="mailto:request@csibizinfo.com" style="color: #ffffff">Request Data Service</a>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 寻找伙伴 -->
        <el-dialog :visible.sync="FindeDialog" center :close-on-click-modal="false" custom-class="find_dialog" :append-to-body="true">
            <div class="large_detail_cont">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center">
                        <div class="title_txt">
                            <img src="https://global-eagle.cn/_nuxt/img/findPartners.941b5b1.png" />
                            Find Business Partners
                        </div>
                        <el-tooltip class="casca_count" effect="dark" content="The system calculates and automatically filters the list of matching enterprises based on the user submitted question and answer data" placement="top-start">
                            <i class="el-icon-warning" style="color: #1290c9; margin-left: 3px"></i>
                        </el-tooltip>
                    </div>

                    <div class="title_txt-right" style="display: flex">
                        <download-excel v-if="!Problemisactive" class="export-excel-wrapper" :data="findoartherForm" :fields="findoartherjson_field" header="Find Business Partners" name="Find Business Partners.xls">
                            <button class="btn_export computer_right_cont_box" style="margin-right: 15px">Export</button>
                        </download-excel>
                        <i class="zhankai" @click="phonefindsave = !phonefindsave" style="margin-right: 10px; margin-top: 10px"><img src="../../assets/img/liebiao2.png" alt="" /></i>
                        <button class="btn_export" v-if="!Problemisactive && suggesti" @click="precisepushdiolag()" style="margin-right: 15px; margin-left: 15px">More Suggestions</button>
                        <button class="btn_export" @click="recreate()">Recreate</button>
                    </div>
                </div>
                <div class="condition_labb computer_right_cont_box" v-if="dynamicTagsshow">
                    <el-tag v-for="(tag, index) in dynamicTags" ref="elTagComponent" :closable="tag.orderCode == null ? true : false" :key="index" :class="istag == index && isactie ? 'isactive-tag' : ''" :disable-transitions="false" @click="cklicktag(tag, index)" @close="handleClosefind(tag)">
                        {{ tag.conditionName }}
                    </el-tag>
                </div>
                <div class="condition_labb phone_page_bg" v-if="dynamicTagsshow && phonefindsave">
                    <el-tag v-for="(tag, index) in dynamicTags" ref="elTagComponent" :closable="tag.orderCode == null ? true : false" :key="index" :class="istag == index && isactie ? 'isactive-tag' : ''" :disable-transitions="false" @click="cklicktag(tag, index)" @close="handleClosefind(tag)">
                        {{ tag.conditionName }}
                    </el-tag>
                </div>
                <div v-show="Problemisactive" class="block-con">
                    <div class="block-con-head">
                        <div class="question-head">
                            <!-- <p class="title_txt">Find Business Partners</p> -->
                            <div v-if="!isstart" class="question-head-step computer_right_cont_box">
                                <img v-if="currentIndex > 0" src="../../assets/img/question-back.png" alt="" @click="goToNextSlide1()" />
                                <p>{{ currentIndex + 1 }} / 6</p>
                            </div>
                        </div>
                    </div>
                    <div class="block-con-body">
                        <div v-if="isstart" class="block-con-body-text">
                            <p>"Find Partners" is a tool for business users to connect with potential customers or vendors. Powered by advanced machine learning algorithms, our system analyzes vast amounts of data to provide personalized and accurate recommendations.</p>
                            <p>Our system leverages user’s objectives, market preferences, risk appetites and historical data to identify products or services that align with their needs and preferences.</p>
                            <p>We continually learns and adapts, ensuring that its recommendations stay up-to-date and accurate. It can providing businesses with valuable insights and saving them time and effort in identifying the best potential customers or vendors.</p>
                            <p>In order for the system to recommend a list of companies, we need you to answer few questions...</p>
                        </div>
                        <div v-if="isstart" class="justify-center question-step-next">
                            <button class="btn_export" @click="Start()">Let’s begin</button>
                        </div>
                        <div v-else class="huandeng">
                            <el-carousel ref="carousel" :interval="0" :trigger="trigger" direction="vertical" :loop="false" @change="handleCarouselChange">
                                <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
                                    <div style="text-align: left">
                                        <div v-if="index == 0" class="question-step-con">
                                            <p class="question-step-question">What are your main products and services?</p>
                                            <el-autocomplete :style="[findewidhe]" ref="autocomplete" v-loading="productloding" :highlight-first-item="true" class="search_input" :trigger-on-focus="false" :autofocus="true" v-model="searchProName" :fetch-suggestions="querySearch" placeholder="Search your products or services" @select="handleSelectprodct"></el-autocomplete>
                                            <p style="margin-top: 20px" class="question-step-question">Describe more about your products and services:</p>
                                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="Write key words about your products or services" v-model="textarea1"></el-input>
                                        </div>
                                        <div v-if="index == 1" class="question-step-con">
                                            <p class="question-step-question">Select what are applied below?</p>
                                            <el-checkbox-group v-model="checkList">
                                                <el-checkbox style="display: block" label="We are manufacture"></el-checkbox>
                                                <el-checkbox style="display: block" label="We are distributors"></el-checkbox>
                                                <el-checkbox style="display: block" label="We do import and export"></el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                        <div v-if="index == 2" class="question-step-con">
                                            <p class="question-step-question">Which countries are you interested in doing business?</p>
                                            <div class="search_box">
                                                <div class="search">
                                                    <!-- 选择国家 -->
                                                    <div class="" :class="countryImg ? 'on' : ''">
                                                        <el-select clearable v-model="countryCode" multiple placeholder="Choose your countries" ref="select" filterable popper-class="sel_box" @change="selectCountry" no-match-text="No data" no-data-text="No data">
                                                            <div class="search_sel_container">
                                                                <el-tabs tab-position="left" v-model="tabActiveName">
                                                                    <el-tab-pane v-for="(tab, index) in tabCountryList" :key="index">
                                                                        <div slot="label" class="flex_center_between_box">
                                                                            <span class="left_txt">{{ tab.continent.enName }}</span>
                                                                            <span class="right_txt">{{ tab.continent.countryNumStr }}</span>
                                                                        </div>
                                                                        <div class="right">
                                                                            <el-option v-for="item in tab.countryList" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode">
                                                                                <div class="right_item flex_box">
                                                                                    <div class="img flex_center">
                                                                                        <img :src="item.urlBanner" />
                                                                                    </div>
                                                                                    <div class="rBox">
                                                                                        <div class="coun_title">{{ item.nameEn }}</div>
                                                                                        <div class="coun_num" v-if="show == 0">{{ item.companyNumStr }}</div>
                                                                                        <div class="coun_num" v-if="show == 2">{{ item.tradeNumStr }}</div>
                                                                                        <div class="coun_num" v-if="show == 3">{{ item.population }}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </el-option>
                                                                        </div>
                                                                    </el-tab-pane>
                                                                </el-tabs>
                                                            </div>
                                                        </el-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <p style="margin-top: 20px" class="question-step-question">Describe more about the countries or markets you are interested:</p>
                                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="Write key words about countries or markets" v-model="textarea2"></el-input>
                                        </div>
                                        <div v-if="index == 3" class="question-step-con">
                                            <div class="create_item">
                                                <div class="create_item_lab">
                                                    <span>Which companies do you like to do business with?</span>
                                                </div>
                                                <div class="name_ser">
                                                    <el-autocomplete :style="[findewidhe]" class="inline-input" ref="autocomplete" v-model="enName" :fetch-suggestions="querySearchcompany" :trigger-on-focus="false" placeholder="Select companies" @select="handleSelectcompany">
                                                        <template slot-scope="{ item }" v-if="item">
                                                            <el-radio :label="item.aaa_id">{{ item.company_name_en }}</el-radio>
                                                        </template>
                                                        <i slot="suffix" class="ser_icon" @click="serchName('enName')"></i>
                                                    </el-autocomplete>
                                                </div>
                                            </div>
                                            <p style="margin-top: 20px" class="question-step-question">Describe more about your expectations about the potential companies you like to work with:</p>
                                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="Write key words about companies" v-model="textarea3"></el-input>
                                        </div>
                                        <div v-if="index == 4" class="question-step-con">
                                            <div class="create_item">
                                                <div class="create_item_lab">
                                                    <span>Who are your major competitors?</span>
                                                </div>
                                                <div class="name_ser">
                                                    <el-autocomplete class="inline-input" :style="[findewidhe]" ref="autocomplete" v-model="CompetitiveenName" :fetch-suggestions="querySearchcompany" :trigger-on-focus="false" placeholder="Select companies" @select="handleSelectcompanytwo">
                                                        <template slot-scope="{ item }" v-if="item">
                                                            <el-radio :label="item.aaa_id">{{ item.company_name_en }}</el-radio>
                                                        </template>
                                                        <i slot="suffix" class="ser_icon" @click="serchName('CompetitiveenName')"></i>
                                                    </el-autocomplete>
                                                </div>
                                            </div>
                                            <p style="margin-top: 20px" class="question-step-question">Describe more about your competitors:</p>
                                            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="Write key words about companies" v-model="textarea4"></el-input>
                                        </div>
                                        <div v-if="index == 5" class="question-step-con" v-loading="findloding">
                                            <p class="question-step-question">Who are you looking for?</p>
                                            <el-checkbox-group v-model="checkList1">
                                                <el-checkbox style="display: block" label="Potential customers for our products"></el-checkbox>
                                                <el-checkbox style="display: block" label="Potential vendors"></el-checkbox>
                                                <el-checkbox style="display: block" label="Possible competitors in the markets"></el-checkbox>
                                                <el-checkbox style="display: block" label="I like to understand the markets"></el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                        <div v-if="index < 5">
                                            <button style="float: left" class="btn_export" v-if="index > 0" @click="goToNextSlide1()">Back</button>
                                            <button style="float: right" class="btn_export" @click="goToNextSlide(index)" :disabled="index == 0 ? (searchProName == '' ? true : false) : index == 1 ? (checkList.length > 0 ? false : true) : index == 2 ? (countryCode.length > 0 ? false : true) : index == 3 ? (enName.length > 0 ? false : true) : index == 4 ? (CompetitiveenName.length > 0 ? false : true) : index == 5 ? (checkList1.length > 0 ? false : true) : false">Next</button>
                                        </div>
                                        <div v-else>
                                            <button style="margin-right: 15px; width: 160px" :disabled="!checkList1.length > 0" class="btn_export" @click="Savecondition(index)">Save my answers</button>
                                            <button class="btn_export" style="width: 160px; float: right" :disabled="!checkList1.length > 0" @click="obtaioCompany()">Done</button>
                                            <button class="btn_export phone_find_export" style="width: 160px; float: right; margin-right: 20px" @click="FindeDialog = false">Cancel</button>
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
                <div v-if="!Problemisactive" class="block-con">
                    <div class="right_cont_box computer_right_cont_box">
                        <div class="table_box">
                            <el-table ref="treeTable" :data="findpartnerTable" border style="width: 100%" @selection-change="handleSelectionChange" v-loading="findloding">
                                <template slot="empty">
                                    <noDate />
                                </template>
                                <el-table-column type="selection"></el-table-column>
                                <el-table-column label="Company Name" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div v-show="showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                            <img @click="gotocountry(scope.row)" style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                            {{ scope.row.company_name_en }}
                                        </div>
                                        <div v-show="!showTranslate" @click="setid(scope.row)" exact class="tbl_company line_1">
                                            <img @click="gotocountry(scope.row)" style="height: 22px; width: 32px; margin-right: 5px" :src="scope.row.flag" />
                                            {{ scope.row.company_name }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Location" width="200" align="left" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div style="width: 180px" v-show="showTranslate" class="line_1">
                                            {{ scope.row.city_en }}
                                            <i v-show="scope.row.province_en && scope.row.province_en != ' ' && scope.row.province_en != '' && scope.row.city_en != '' && scope.row.city_en && scope.row.city_en != ' '">,</i>
                                            {{ scope.row.province_en }}
                                        </div>
                                        <div style="width: 180px" v-show="!showTranslate" class="line_1">
                                            {{ scope.row.city_cn }}
                                            <i v-show="scope.row.province && scope.row.province != ' ' && scope.row.province != '' && scope.row.city_cn != '' && scope.row.city_cn != ' ' && scope.row.city_cn">,</i>
                                            {{ scope.row.province }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Products" width="180" :prop="showTranslate == true ? 'company_product_en' : 'company_product_en'" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div style="width: 160px" class="line_1">
                                            {{ scope.row.industry_en }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Co.Size" width="100" :prop="showTranslate == true ? 'scale_en' : 'scale_en'" align="center" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Revenue" width="140" prop="revenue_en" align="center" show-overflow-tooltip :resizable="false" />

                                <el-table-column label="" prop="operation" align="center" width="80" :resizable="false">
                                    <template slot-scope="scope">
                                        <div class="table_oper on" v-if="scope.row.isFavorite" @click="addToList(scope.row)">
                                            <img src="../../assets/img/search/table_ic1.png" />
                                        </div>
                                        <div class="table_oper" v-else @click="addToList(scope.row)">
                                            <img src="../../assets/img/search/table_ic2.png" />
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <div class="pageBox"></div>

                                <div class="search_rsult_txt">
                                    {{ searchProNametotal }} results for "
                                    <div class="line_3">{{ searchProName }}</div>
                                    "
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 手机端 -->
                </div>
                <!-- <button class="btn_export" @click="exportCompany">Export</button> -->
            </div>
        </el-dialog>
        <!-- 保存条件弹框 -->
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <div class="dialog_detail_title">Please name your condition</div>
                <div class="detail_cont">
                    <el-form :model="formData" :rules="formRules" ref="form">
                        <el-form-item label="" prop="saveitem">
                            <el-input v-model="formData.saveitem"></el-input>
                        </el-form-item>
                        <!-- <el-button @click="submitForm">Submit</el-button> -->
                    </el-form>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box" style="justify-content: center; margin-top: 25px">
                <el-button class="btn_export" @click="validateFormsumit">OK</el-button>
            </div>
        </el-dialog>
        <!-- 精准推送 -->
        <el-dialog :visible.sync="precise" :close-on-click-modal="false" custom-class=" precise_dialog" :append-to-body="true">
            <div class="dialog_detail_title">More Suggestions</div>
            <div v-if="preciseheader && precise">
                <div class="block-con-body-text">
                    <p>”More Suggestion" is a consulting service that our local consultants will analyze your needs, research and provide a report that meet your requirements.</p>
                    <p>This report will include all the background information, detail contact info, trade records, business description and more on the suggested potential customer or vendor. You can choose how many companies, how often, how to receive this company report.</p>
                    <p>If you are interested in this service, please choose your settings...</p>
                </div>
                <div class="justify-center question-step-next">
                    <button class="btn_export" @click="letsbing()">Let’s begin</button>
                </div>
            </div>
            <div v-if="!preciseheader">
                <div>
                    <div class="">
                        <div class="detail_cont-one">
                            <h1>What types of info do you like to include in your report?</h1>
                            <div>
                                <el-checkbox-group v-model="checkedCities">
                                    <el-checkbox label="Main business activities">Main business activities</el-checkbox>
                                    <el-checkbox label="Business scale">Business scale</el-checkbox>
                                    <el-checkbox label="Contact info">Contact info</el-checkbox>
                                    <el-checkbox label="Potential vendors">Potential vendors</el-checkbox>
                                    <el-checkbox label="Industry info">Industry info</el-checkbox>
                                    <el-checkbox label="Trade records">Trade records</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </div>
                        <div class="detail_cont-one">
                            <h1>How often do you want us to send you this report?</h1>
                            <div>
                                <el-radio-group v-model="radio" @input="radiochang()">
                                    <el-radio label="One time">One time</el-radio>
                                    <el-radio label="Monthly">Monthly</el-radio>
                                    <el-radio label="Quarterly">Quarterly</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="detail_cont-one" v-if="radio != 'One time' || radio == 'Monthly' || radio == 'Quarterly'">
                            <h1>How long do you want to keep this service?</h1>
                            <div>
                                <el-radio-group v-model="radio2" @input="radiochang()">
                                    <el-radio label="One time">One time</el-radio>
                                    <el-radio label="3 months">3 months</el-radio>
                                    <el-radio label="6 months">6 months</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="detail_cont-one">
                            <h1>How many companies do you want us to recommend? It cost 4 units per company.</h1>
                            <div>
                                <el-input-number v-model="num" @change="handleChange" :min="0" label=""></el-input-number>
                            </div>
                        </div>
                        <div class="detail_cont-one">
                            <h1>Choose how to deliver you the report?</h1>
                            <div class="form-email">
                                <el-form :model="formData" :rules="formRules" ref="form">
                                    <el-form-item style="display: flex; flex-wrap: wrap" label="Email:" prop="email">
                                        <el-input style="width: 350px" v-model="formData.email" @blur="tuisumint"></el-input>
                                    </el-form-item>
                                    <!-- <el-button @click="submitForm">Submit</el-button> -->
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex_center_between_box" style="justify-content: end; margin-bottom: 20px">
                    <button class="btn_export" style="float: right; margin-right: 20px" @click="closePreciseDialog">Cancel</button>
                    <el-button class="btn_export" style="float: right" :disabled="erroemail" @click="gettuisumint">Order</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 精准推送扣点 -->
        <el-dialog :visible.sync="serivce" :close-on-click-modal="false" custom-class=" precise_dialog" :append-to-body="true">
            <div class="block-con-body-text">
                <div class="dialog_detail_title">More Suggestions</div>

                <h3 class="koudian">
                    <span class="koudian-title">Products / services:</span>
                    <span class="koudianwidth">{{ searchProName }}</span>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">You are:</span>
                    <ul>
                        <li v-for="(item, index) in checkList" :key="index">{{ item }}</li>
                    </ul>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Countries interested:</span>
                    <span v-for="(item, index) in countryCode" :key="index" style="margin-right: 7px">{{ getCountry(item) }}</span>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Companies interested:</span>
                    <ul>
                        <li style="margin-bottom: 5px">{{ enName }}</li>
                        <li class="koudianwidth">{{ CompetitiveenName }}</li>
                    </ul>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Look for:</span>
                    <ul>
                        <!-- <li style="margin-bottom: 5px;width: 500px;color: #C0C4CC;">{{ enName }}</li> -->
                        <li v-for="(item, index) in checkList1" :key="index">{{ item }}</li>
                    </ul>
                    <!-- <span style="width:500px ;">{{ checkList1.join(',') }}</span> -->
                </h3>
                <h3 class="koudian">
                    <span class="koudian-title">Like to receive:</span>
                    <span class="block-con-body-text koudianwidth">
                        <span class="xuanzhong">{{ radio }}</span>
                        report recommending
                        <span>{{ num }}</span>
                        companies in each report by
                        <span>email</span>
                        for&nbsp;{{ radio2 }}
                    </span>
                </h3>
                <h3 class="koudian">
                    <span class="koudian-title">Total:</span>
                    <ul>
                        <li style="margin-bottom: 5px">{{ num }} companies recommended</li>
                        <li>{{ Calculation }} units</li>
                    </ul>
                </h3>
            </div>
            <div class="flex_center_between_box" style="justify-content: end; margin-bottom: 20px">
                <button class="btn_export" style="float: right; margin-right: 20px" @click="canslse()">Cancel</button>
                <el-button class="btn_export" style="float: right" :disabled="erroemail" @click="gettorder">OK</el-button>
            </div>
        </el-dialog>
        <!-- Thanks for your order -->
        <el-dialog :visible.sync="ThanksDialog" center :close-on-click-modal="false" @close="ThanksDialog = false" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <div class="dialog_detail_title dialog_detail_title-order">Thanks for your order!</div>

                <div class="block-con-body-text">
                    <p style="margin-top: 15px">You will receive a confirmation of the order complete in your email.</p>
                    <p style="margin-top: 15px">You can also find this order info in My Account/My Database.</p>
                    <p style="margin-top: 15px">
                        Should you have any questions, or decide to modify or cancel this order, please send your request within 24 hours to:
                        <a href="mailto:service@csibizinfo.com" class="link">service@csibizinfo.com</a>
                    </p>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box" style="justify-content: center; margin-top: 25px">
                <el-button class="btn_export" @click="orderok">OK</el-button>
            </div>
        </el-dialog>
        <!-- phone _apply afiilter -->
        <el-drawer title="" :visible.sync="drawer" size="300px" :with-header="false" direction="ltr">
            <transition name="fade" :duration="500">
                <div class="phoneappl_filters" v-loading="loading">
                    <div class="left_menu">
                        <button class="btn_Retr" @click="RetrievalResult">
                            <img src="../../assets/img/search/btn_ic1.png" />
                            Apply Filters
                        </button>
                        <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()">
                            <el-collapse-item title="Country" name="2">
                                <template slot="title">
                                    Country
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <input type="text" v-model="cascInptCountry" v-show="showInptCountry" class="cas_inpt" placeholder="Select Country" @input="inptCountrySearch" />
                                    <el-cascader ref="Country" placeholder="Select Country" :key="cascaderKey" v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" :props="{ multiple: true }" collapse-tags popper-class="cas_loc_box ticket_ccCascader">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                                <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                                <span class="casca_lab">
                                                    <span ref="sty">{{ data.label }}</span>
                                                </span>
                                            </el-tooltip>

                                            <!-- <span class="casca_lab">{{ data.label }}</span> -->
                                            <!-- <span class="casca_count">({{ data.count }})</span> -->
                                            <el-tooltip class="casca_count" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                                <span class="casca_count">{{ data.count | FilterNum }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>

                            <el-collapse-item title="Industry" name="3">
                                <template slot="title">
                                    Industry
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="cas_panel cas_loc">
                                    <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt" placeholder="Select Industry" @input="inptIndSearch" />
                                    <el-cascader ref="inptIndSearch" v-model="industryCheckList" clearable :key="cascaderKey" :options="industryList" :props="optionsProps" @change="menuCheck('Industry')" placeholder="Select Industry" popper-class="cas_loc_box indystry">
                                        <template slot-scope="{ data }">
                                            <el-tooltip class="" effect="dark" :content="data.name" placement="top-start">
                                                <span class="casca_lab">{{ data.name }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-cascader>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Type" name="4">
                                <template slot="title">
                                    Company Type
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companyTypeCheckList" @change="menuCheck('companyType')">
                                        <el-checkbox v-for="(child, i) in companyTypeList" :key="i" :label="child.name">
                                            <!-- <div class="line_1 coll_child_name">{{ child.name }}</div>
                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span> -->
                                            <el-tooltip :disabled="isShowTooltip" class="line_1 coll_child_name" effect="dark" :content="child.name" placement="top-start">
                                                <div @mouseover="onMouseOver(child.name, i)" class="line_1 coll_child_name">
                                                    <span ref="str">{{ child.name }}</span>
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="coll_child_num line_1" effect="dark" :content="child.count | FilterNum" placement="top-start">
                                                <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                            </el-tooltip>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Size" name="5">
                                <template slot="title">
                                    Company Size
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                                        <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>

                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Company Status" name="6">
                                <template slot="title">
                                    Company Status
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                                        <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Listed Company" name="7">
                                <template slot="title">
                                    Listed Company
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="listedCompanyCheckList" @change="menuCheck('listedCompany')">
                                        <el-checkbox v-for="(child, i) in listedCompanyList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                            <el-collapse-item title="Years in Business" name="8">
                                <template slot="title">
                                    Years in Business
                                    <i class="header-icon el-icon-lock hedr-icon-ydong" v-if="!powerResult[0]?.list[0]?.power"></i>
                                </template>
                                <div class="coll_child">
                                    <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                                        <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.name">
                                            <div class="line_1 coll_child_name">{{ child.name }}</div>
                                            <span class="coll_child_num line_1">{{ child.count | FilterNum }}</span>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </transition>
        </el-drawer>
    </div>
</template>
<script>
import { getoptionsProduct, getCompanyDetailMenuList, ordersAdd, getIsCollect, getPrice } from '../../api/companyApi';
import { getPdfPurchase } from '@/api/userApi.js';
import { searchproduct, searchCompany, selectGroup, getProductThirdCodeList, getProductAggregationResult, selectProductTypeCode, findPartnerDetail, saveFindPartner, getFindPartner, deletePartnerById, updateFindPartnerById, precisePush } from '@/api/searchApi.js';
import noDate from '@/components/tableNoDate';
import Page from '@/components/pagetree';
import { mixins1 } from '@/mixins/index';
import Collection from '@/components/collection';
import bus from '../../components/bus';
// import { json } from 'd3';
export default {
    mixins: [mixins1],
    components: { noDate, Page, Collection },
    data() {
        return {
            drawer: false,
            isFadedIn: true,
            loading: true,
            seach: '',
            loadig: true,
            collecObj: {},
            isActive: false,
            filterCountry: '',
            timeTag: [],
            arrProvTag: [],
            // 下载
            title: 'Products',
            showTranslate: true,
            powerResult: [],
            kaiguan: false,
            province: [],
            findloding: false,
            json_fields: {
                'Company Name': 'companyNameEn',
                Country: 'countryEn',
                IncDate: 'incDate',
                Transcations: 'transcations',
            },
            json_field: {
                'Company Name': 'company_name_en',
                Location: 'province_en',
                'Compony Size': 'scale_en',
                Products: 'productsseach',
                // searchProName
                Revenue: 'revenue_en',
            },
            DetailsForm: [],
            state: '', //table 搜索内容
            restaurants: [], //table 搜索
            dian: ',',
            industry: [], //所属行业
            companyType: [], //公司类型
            geographicalLocation: [], //地理位置
            yearsOfEstablishment: [], //成立年限
            regStatus: [],
            tabcardlist: ['Search Results', 'Top Countries', 'Company Size', 'Top Players'],
            show: 0,
            fromPage: 'company',
            searchName: '',
            players: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            optionsProps: {
                label: 'name', //名称展示
                value: 'value', //值展示
                expandTrigger: 'click', //次级菜单的展开方式
            },
            CompanySize: [],
            TopCountry: [],
            isShowTooltip: false,
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            filterName: '',
            addPopIndex: false,
            industryCheck: '',

            checkCounList: [],
            location: '',
            locList: [], //地区
            locOptions: [],
            //表
            parentTable: [],
            addDialog: false, //添加列表弹框
            index: -1,
            addList: [],
            createDialog: false, //新增分组弹框
            createNewName: '',
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            countryCheckList: [],
            // 添加收藏
            radioGroupName: [],
            productThirdKey: '',
            groupName: '',
            rowObj: {},
            searchProduct: '', //table搜索内容
            companyList: [],
            code: '',
            countryList: [],
            countryPreList: [],

            countryTag: [],
            openMenu: [],

            industryPreList: [],
            industryList: [],
            industryCheckList: [],
            industryTag: [],
            companyTypeList: [],
            companyTypeCheckList: [],
            companyTypeTag: [],
            companyTypeCheck: '',
            companySizeList: [],
            companySizeCheckList: [],
            companySizeCheck: '',
            companySizeTag: [],
            companyStatusList: [],
            companyStatusCheckList: [],
            companyStatusCheck: '',
            companyStatusTag: [],

            listedCompanyList: [],
            listedCompanyCheckList: [],
            listedCompanyTag: [],
            listedCompanyCheck: '',
            timePeriodList: [],
            timePeriodCheckList: [],
            timePeriodCheck: '',
            token: localStorage.getItem('token'),
            city: [],
            istag: '',
            cascaderKey: '',
            cascInptCountry: '',
            showInptCountry: true,

            cascInptIndustry: '',
            showInptIndustry: true,
            // 寻找伙伴
            FindeDialog: false,
            carouselItems: ['', '', '', '', '', ''],
            trigger: 'manual',
            currentIndex: 0,
            isstart: true,
            questionpeoduct: '',
            checkList: [],
            checkList1: [],
            searchProName: '',
            productloding: false,
            // 国家选择
            tabCountryList: [],
            countryName: '', //选中国家
            countryImg: '', //选中国家的国旗
            tabActiveName: '', //选择的区域
            enName: '', //选择的公司
            enNamecompany: '',
            CompetitiveenNameen: '',
            countryCode: [],
            CompetitiveenName: '',
            searchProNamecode: '',
            isdisbale: false,
            newDialog: false,

            // 竞争企业行业code
            competitiveIsicCode: '',
            // 寻找伙伴行业code
            expectedIsicCode: '',
            formData: {
                saveitem: '',
                email: '',
            },
            formRules: {
                saveitem: [{ required: true, message: 'Please input a value', trigger: 'blur' }],
                email: [
                    { required: true, message: 'Email is required', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email format', trigger: 'blur' },
                ],
            },
            searchProNametotal: '',
            dynamicTags: [],
            isactie: false,
            getfinclalistdialog: false,
            // 寻找伙伴列表
            findpartnerTable: [],
            Problemisactive: true,
            precise: false,

            checkedCities: ['Main business activities', 'Business scale', 'Contact info', 'Potential vendors', 'Industry info', 'Trade records'],
            erroemail: true,
            radio: 'One time',
            radio2: '',
            saveid: '',
            findoartherjson_field: {
                'Company Name': 'company_name_en',
                Location: 'province_en',
                'Compony Size': 'scale_en',
                Products: 'company_product_en',
                Revenue: 'revenue_en',
            },
            findoartherForm: [],
            serivce: false,
            num: 0,
            consume: '',
            textarea1: '',
            textarea2: '',
            textarea4: '',
            textarea3: '',
            Calculation: '',
            svaerecreate: false,
            suggesti: false,
            preciseheader: false,
            ThanksDialog: false,
            dynamicTagsshow: true,
            ret: false,
            exportLoding: false,
            findewidhe: {
                width: window.innerWidth < 821 ? '100%' : '811px',
            },
            findtablelist: true,
            phonefindsave: false, //移动端点击展开寻找伙伴保存条件

            // account: { total: '', used: '' },
        };
    },

    computed: {},
    updated() {
        this.$nextTick(function () {
            this.formatCC();
            //   this.showInptCountry=true
        });
    },
    created() {
        // this.getlist();
        let that = this;
        that.$EventBus.$on('searchpromae', item => {
            that.seach = item;
        });
        // 重复请求优化
        if (!this.token) {
            this.getPower();
        }
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        this.$EventBus.$off('searchpromae');
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        // this.getFindPartnerlist();
        this.trigger = 'click';
        document.querySelectorAll('.el-cascader-node__label').forEach(el => {
            el.onclick = function () {
                if (this.previousElementSibling) this.previousElementSibling.click();
            };
        });

        this.xuanze();
        window.addEventListener('setItem', () => {
            this.token = sessionStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('handleShowLogin');
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.openMenu = ['2', '3', '4', '5', '6', '7', '8'];

                    this.getlist();
                    this.getPower();
                }
                // this.loadig=false
            },
            immediate: true,
        },
        countryList(val) {
            this.cascaderKey++;
        },
        industryList(val) {
            this.cascaderKey++;
        },
    },

    methods: {
        applefilters() {
            console.log(window.innerWidth);
            this.isFadedIn = !this.isFadedIn;
            if (window.innerWidth < 821) {
                this.drawer = !this.drawer;
            }
        },
        // 判断是否为phone
        handleResize() {},
        gotourl(row) {
            if (row.includes('http')) {
                let routeData = row;
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row;
                window.open(routeData, '_blank');
            }
        },
        findbusines() {
            this.FindeDialog = true;
            this.getFindPartnerlist();
        },
        gettorder() {
            let params = {
                companyName: 'Precise push',
                aaaId: this.saveid,
                method: '0',
                methodType: '0',
                amount: this.Calculation,
                num: this.num,
                type: 21,
            };

            let form = {
                productName: this.searchProName,
                productNameDesc: this.textarea1,
                productChannel: this.checkList.join(','),
                targetCountry: this.countryCode.join('@@'),
                targetCountryDesc: this.textarea2,
                expectedCompany: this.enName,
                competitiveEnterprise: this.CompetitiveenName,
                competitiveEnterpriseDesc: this.textarea4,
                partner: this.checkList1.join(','),
                conditionName: this.formData.saveitem,
                expectedCompanyId: this.enNamecompany,

                competitiveEnterpriseId: this.CompetitiveenNameen,
                radio: this.radio,
                radio2: this.radio2,
                expectedCompanyDesc: this.textarea3,
                email: this.formData.email,
                checkedCities: this.checkedCities.join(','),
                inputnum: this.num,
            };

            ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    this.$message({
                        message: data.msg,
                        type: 'success',
                        // duration: 5000,
                    });

                    let userCode = JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode;
                    let upparams = {
                        userCode: userCode,
                        productCode: this.searchProNamecode,
                        productName: this.searchProName,
                        productNameDesc: this.textarea1,
                        productChannel: this.checkList.join(','),
                        targetCountry: this.countryCode.join('@@'),
                        targetCountryDesc: this.textarea2,
                        expectedCompany: this.enName,
                        competitiveEnterprise: this.CompetitiveenName,
                        competitiveEnterpriseDesc: this.textarea4,
                        partner: this.checkList1.join(','),
                        conditionName: this.formData.saveitem,
                        expectedCompanyId: this.enNamecompany,
                        expectedIsicCode: this.expectedIsicCode,
                        competitiveEnterpriseId: this.CompetitiveenNameen,
                        competitiveIsicCode: this.competitiveIsicCode,
                        expectedCompanyDesc: this.textarea3,
                    };
                    let newsparams = {
                        id: this.saveid,
                        ...upparams,
                        type: 9,
                        orderCode: data.data.orderCode,
                    };
                    updateFindPartnerById(newsparams).then(res => {});
                    precisePush({
                        findPartnerId: params.aaaId,
                        demand: JSON.stringify(form),
                    }).then(res => {
                        if (res.code) {
                            this.suggesti = false;
                            this.serivce = false;
                            this.precise = false;
                            this.ThanksDialog = true;
                            this.$message({
                                message: res.msg,
                                type: 'success',
                                // duration: 5000,
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error',
                                // duration: 5000,
                            });
                        }
                    });

                    this.dynamicTagsshow = false;

                    this.getFindPartnerlist();
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 1000);
                }
            });
        },
        //点击raido切换
        radiochang() {
            if (this.radio == 'One time') {
                this.radio2 = 'One time';
            }
            this.handleChange(this.num);
        },
        // 精准推送计算价格
        handleChange(value) {
            getPrice({ num: value, no: 1000000033 }).then(res => {
                if (res.code) {
                    this.consume = res.data;
                    if (this.radio == 'One time' || (this.radio == 'Monthly' && this.radio2 == 'One time') || (this.radio == 'Quarterly' && this.radio2 == 'One time')) {
                        this.Calculation = this.consume * 1;
                    } else if (this.radio == 'Monthly' && this.radio2 == '3 months') {
                        this.Calculation = this.consume * 3;
                    } else if (this.radio == 'Monthly' && this.radio2 == '6 months') {
                        this.Calculation = this.consume * 6;
                    } else if (this.radio == 'Quarterly' && this.radio2 == '3 months') {
                        this.Calculation = this.consume * 1;
                    } else if (this.radio == 'Quarterly' && this.radio2 == '6 months') {
                        this.Calculation = this.consume * 2;
                    }
                } else {
                    this.consume = '';
                }
            });
        },
        // 多选导出
        handleSelectionChange(val) {
            this.findoartherForm = val;
        },
        gettuisumint() {
            this.handleChange();
            // this.getAccountMsg(1);
            this.serivce = true;
        },
        closePreciseDialog() {
            this.precise = false;
        },
        orderok() {
            (this.ThanksDialog = false), (this.FindeDialog = false);
        },
        letsbing() {
            this.preciseheader = false;
        },
        canslse() {
            this.serivce = false;
        },
        // 验证邮箱
        tuisumint() {
            this.$refs.form.validateField('email', errorMsg => {
                if (errorMsg) {
                    this.erroemail = true;
                    // 校验不通过，处理错误信息
                } else {
                    this.erroemail = false;
                }
            });
        },
        // 精准推送按钮
        precisepushdiolag() {
            this.precise = true;
            this.preciseheader = true;
        },
        // 重新创建
        recreate() {
            this.Problemisactive = true;
            this.findpartnerTable = [];
            this.isactie = false;
            this.isstart = true;
            this.searchProNamecode = '';
            this.searchProName = '';
            this.checkList = [];
            this.countryCode = [];

            this.enName = '';
            this.CompetitiveenName = '';
            this.checkList1 = [];
            this.formData.saveitem = '';
            this.enNamecompany = '';
            this.expectedIsicCode = '';
            this.CompetitiveenName = '';
            this.CompetitiveenNameen = '';
            this.competitiveIsicCode = '';
            this.textarea1 = '';
            this.textarea2 = '';
            this.textarea3 = '';
            this.textarea4 = '';
            // this.$refs.carousel.setActiveItem(0);
        },
        // 点击寻找伙伴保存条件
        cklicktag(tag, index) {
            this.istag = index;
            this.isactie = true;
            this.saveid = tag.id;
            this.currentIndex = 0;
            this.getAllCountry();
            this.isstart = false;
            this.searchProNamecode = tag.productCode;

            this.searchProName = tag.productName;
            this.enName = tag.expectedCompany;
            this.checkList = tag.productChannel.split(',');
            this.countryCode = tag.targetCountry.split('@@').map(code => code.trim());

            // this.textarea1=tag.productNameDesc
            // this.textarea2=tag.targetCountryDesc
            // this.textarea3=tag.expectedCompanyDesc
            // this.textarea4=tag.productNameDesc

            this.CompetitiveenName = tag.competitiveEnterprise;
            this.checkList1 = tag.partner.split(',');
            this.formData.saveitem = tag.conditionName;
            this.enNamecompany = tag.expectedCompanyId;
            this.expectedIsicCode = tag.expectedIsicCode;
            this.CompetitiveenName = tag.competitiveEnterprise;
            this.CompetitiveenNameen = tag.competitiveEnterpriseId;
            this.competitiveIsicCode = tag.competitiveIsicCode;
            console.log(`tag`, tag);
            if (tag.orderCode == null) {
                this.Problemisactive = true;
            } else {
                this.obtaioCompany();
                this.Problemisactive = false;
            }

            setTimeout(() => {
                this.$refs.carousel.setActiveItem(0);
            }, 50);
            getPdfPurchase('aaaId=' + tag.id).then(res => {
                if (res.code && res.data.competence.id) {
                    this.suggesti = false;
                } else {
                    this.suggesti = true;
                }
            });
        },
        // 删除tag
        handleClosefind(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
            deletePartnerById({ id: tag.id }).then(Res => {
                if (Res.code) {
                    this.$message({
                        message: Res.msg,
                        type: 'success',
                    });
                }
            });
        },
        //获取保存条件列表
        getFindPartnerlist() {
            getFindPartner().then(res => {
                if (res.code) {
                    this.dynamicTags = res.data;
                    if (this.svaerecreate) {
                        this.istag = 0;
                        this.isactie = true;
                        this.saveid = this.dynamicTags[this.istag].id;
                        this.suggesti = true;
                        let that = this;
                        this.dynamicTagsshow = true;
                        this.$nextTick(() => {
                            that.dynamicTags = res.data;
                            // 在DOM更新后执行的回调函数
                            const elTagComponent = that.$refs.elTagComponent;
                            // 检查el-tag组件的更新状态
                        });
                    }
                    this.dynamicTagsshow = true;
                } else {
                    this.dynamicTagsshow = true;
                    this.dynamicTags = [];
                }
            });
        },
        // 保存条件
        Savecondition() {
            this.newDialog = true;
        },
        // 保存条件提交
        validateFormsumit() {
            this.$refs.form.validateField('saveitem', errorMsg => {
                if (errorMsg) {
                    // 校验不通过，处理错误信息
                } else {
                    const userCode = JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode;
                    let params = {
                        userCode: userCode,
                        productCode: this.searchProNamecode,
                        productName: this.searchProName,
                        productNameDesc: this.textarea1,
                        productChannel: this.checkList.join(','),
                        targetCountry: this.countryCode.join('@@'),
                        targetCountryDesc: this.textarea2,
                        expectedCompany: this.enName,
                        competitiveEnterprise: this.CompetitiveenName,
                        competitiveEnterpriseDesc: this.textarea4,
                        partner: this.checkList1.join(','),
                        conditionName: this.formData.saveitem,
                        expectedCompanyId: this.enNamecompany,
                        expectedIsicCode: this.expectedIsicCode,
                        competitiveEnterpriseId: this.CompetitiveenNameen,
                        competitiveIsicCode: this.competitiveIsicCode,
                        expectedCompanyDesc: this.textarea3,
                    };
                    if (this.isactie) {
                        let newsparams = {
                            id: this.saveid,
                            ...params,
                            type: 9,
                        };
                        updateFindPartnerById(newsparams).then(res => {
                            if (res.code) {
                                this.$message({
                                    message: res.msg,
                                    type: 'success',
                                    // duration: 5000,
                                });
                                this.newDialog = false;
                                this.obtaioCompany();
                                this.svaerecreate = false;
                                this.getFindPartnerlist();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error',
                                    // duration: 5000,
                                });
                            }
                        });
                    } else {
                        saveFindPartner(params).then(res => {
                            if (res.code) {
                                this.$message({
                                    message: res.msg,
                                    type: 'success',
                                    // duration: 5000,
                                });
                                this.newDialog = false;
                                this.obtaioCompany();
                                this.svaerecreate = true;
                                this.getFindPartnerlist();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error',
                                    // duration: 5000,
                                });
                            }
                        });
                        // 校验通过
                    }
                }
            });
        },
        // 获取推荐名单
        obtaioCompany() {
            let params = {
                targetCountry: this.countryCode.join('@@'),
                competitiveIsicCode: this.competitiveIsicCode,
                competitiveEnterpriseId: this.CompetitiveenNameen,
                productCode: this.searchProNamecode,
                expectedCompanyId: this.enNamecompany,
                expectedIsicCode: this.expectedIsicCode,
            };
            this.loading = false;
            this.findloding = true;
            findPartnerDetail(params).then(res => {
                if (res && res.code) {
                    this.findloding = false;
                    // this.FindeDialog = false;
                    this.searchProNametotal = res.data.length;
                    this.Problemisactive = false;

                    let list = (this.findpartnerTable = res.data);
                    if (window.innerWidth < 821) {
                        this.findtablelist = false;
                        this.FindeDialog = false;
                        this.findpartnerTable = this.findpartnerTable.slice(0, 20);
                    }
                    //查找本地图片
                    list = list.map((item, index) => {
                        let countryImg = this.getCountryImgUrl(item.company_country);
                        let flag = require('../../../static' + countryImg);
                        this.findpartnerTable[index].flag = flag;
                        this.findpartnerTable[index].searchProName = this.searchProName;
                        this.refresh++;
                    });
                    // this.$message({
                    //     message: res.msg,
                    //     type: 'success',
                    // });
                } else {
                    this.loading = false;
                    this.findloding = false;

                    this.findpartnerTable = [];
                }
            });
        },
        // 点击选择公司
        async serchName(val) {
            let companyname = '';
            if (val == 'enName') {
                companyname = this.enName;
            } else {
                companyname = this.CompetitiveenName;
            }

            var list = [];
            this.$refs.autocomplete.suggestions = [];
            let countryCode = this.countryCode.join('@');
            let params = {
                company_name: companyname,
                company_country: countryCode,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
            };
            let res = await searchCompany(params);
            if (res && res.code) {
                this.page.total = res.data.total;
                this.restaurants = res.data.rows;
                for (let i of this.restaurants) {
                    i.value = i.company_name_en; //将想要展示的数据作为value
                }
                list = this.restaurants;
                this.$refs.autocomplete.suggestions = list;
            }
        },
        // 寻找伙伴选择公司
        handleSelectprodct(item) {
            this.searchProNamecode = item.display_code;
        },
        // 寻找伙伴选中公司
        handleSelectcompany(item) {
            this.enNamecompany = item.aaa_id;
            this.expectedIsicCode = item.industry_code;
            // this.addDisabled = false;
        },
        // 竞争企业
        handleSelectcompanytwo(item) {
            this.CompetitiveenNameen = item.aaa_id;
            this.competitiveIsicCode = item.industry_code;
            // this.addDisabled = false;
        },
        // 寻找伙伴下拉选择公司
        async querySearchcompany(queryString, callback) {
            let countryCode = this.countryCode.join('@');
            var list = [];
            this.$refs.autocomplete.suggestions = [];
            let params = {
                company_name: queryString,
                // company_country: countryCode,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
            };
            let res = await searchCompany(params);
            if (res && res.code) {
                this.page.total = res.data.total;
                this.restaurants = res.data.rows;
                for (let i of this.restaurants) {
                    i.value = i.company_name_en; //将想要展示的数据作为value
                }
                list = this.restaurants;
                callback(list);
            } else {
                this.restaurants = [];
                list = [];
                callback(list);
            }
        },
        // 寻找伙伴获取下拉产品
        querySearch(queryString, cb) {
            this.productloding = true;
            if (queryString !== '') {
                const param = {
                    keyword: this.searchProName,
                    pageNum: 1,
                    pageSize: 369,
                    // productKeyword: this.$route.query.searchcode,
                    // productThirdKeyword: queryString,
                    // company_country: 'CN@@US',
                };
                selectProductTypeCode(param)
                    .then(res => {
                        const data = res.data;
                        if (res.code) {
                            this.productloding = false;
                            data.rows.forEach(item => {
                                item.val = item.display_code;
                                item.value = item.en;
                                if (this.searchProName != item.en) {
                                    this.code = '';
                                }
                                // item.val=item.value
                            });
                            if (data.rows == []) {
                                this.code = '';
                            }
                            cb(data.rows);
                        } else {
                            this.productloding = false;
                            this.code = '';
                            cb([]);
                        }
                    })
                    .catch(() => {
                        this.productloding = false;
                        this.code = '';
                        cb([]);
                    });
            }
        },
        // 寻找伙伴选择国家
        async getAllCountry() {
            // let res = await getCountryCollection()

            // if (res && res.code) {
            this.tabCountryList = JSON.parse(localStorage.getItem('countryList')); //res.data.rows
            // this.$store.state.countryImgList = res.data.rows
            let list = this.tabCountryList;

            list.forEach((item, index) => {
                item.countryList.forEach(it => {
                    let countryImg = it.urlBanner.split('/static')[1];
                    let flag = require('../../../static' + countryImg);
                    it.urlBanner = flag;
                });
            });
            // }
        },
        //下拉选择国家
        selectCountry(val) {
            let countryImg = this.getCountryImgUrl(val);
            let flag = require('../../../static' + countryImg);
            this.countryImg = flag;
            this.countryCode = val;

            this.getAllCountry();
        },
        Start() {
            this.getAllCountry();
            this.isstart = false;
            this.currentIndex = 0;
            // this.$refs.carousel.setActiveItem(0);
        },
        handleCarouselChange(index) {
            this.currentIndex = index;
        },
        goToNextSlide(index) {
            if (index === this.currentIndex) {
                this.$refs.carousel.next();
            }
        },
        goToNextSlide1() {
            this.$refs.carousel.prev();
        },
        // 产品methods
        zhankai() {
            let hasPower = this.powerResult[0].list[0].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },
        // Company type 超出省略 显示
        onMouseOver(str, i) {
            const tag = this.$refs.str[i];
            const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
            const contentWidth = tag.offsetWidth; // 获取元素可视宽度
            this.isShowTooltip = contentWidth <= parentWidth;
        },
        //  国家下啦增加删除
        formatCC() {
            let htmladd = document.createElement('bottom');
            let htmladd2 = document.createElement('bottom2');

            htmladd.innerHTML = 'Confirm';
            htmladd.className = 'htmladd';
            htmladd2.innerHTML = 'Delete';
            htmladd2.className = 'htmladd2';
            htmladd.style.cssText = 'cursor: pointer;position: absolute;bottom: -35px;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);';
            htmladd2.style.cssText = 'cursor: pointer;position: absolute;bottom: -35px;right: 0;height: 35px;line-height:35px;width:50%;background:#fff;text-align: center;color:#606266;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);';
            let that = this;
            var el_l = document.querySelectorAll('.el-popper.el-cascader__dropdown.ticket_ccCascader');
            if (el_l.length > 0 && el_l.length == 1) {
                var el = el_l[0];

                if (!el.querySelector('.htmladd')) {
                    el.appendChild(htmladd);
                    var bo = el.querySelectorAll('.htmladd')[0];
                    bo.onclick = function () {
                        that.$refs.Country.dropDownVisible = false;
                        that.getlist();
                    };
                }

                var el1 = el_l[0];
                if (!el1.querySelector('.htmladd2')) {
                    el1.appendChild(htmladd2);
                    var bo1 = el1.querySelectorAll('.htmladd2')[0];
                    bo1.onclick = function () {
                        that.countryCheckList = [];
                        that.countryTag = [];
                        that.province = '';
                        that.arrProvTag = [];
                        that.countryCheck = '';
                        that.$refs.Country.dropDownVisible = false;
                        that.showInptCountry = true;
                        that.getlist();
                    };
                }
            }
        },
        //   省略展示
        tabshow() {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译】和【详情】
            const token = localStorage.getItem('token');
            if (!token) {
                if (!(e.target?._prevClass === 'no_login' || e.target._prevClass === 'tbl_company line_1' || e.target._prevClass === 'tbl_company1 line_1')) {
                    // bus.$emit('headLogFlg', true); //是否显示登录
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },
        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            this.getPowerMenu().then(async () => {
                let hasPower = this.powerResult[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    this.init();
                    this.getCollData();
                    let power = this.powerResult[1].power;
                    if (power) {
                        this.kaiguan = false;
                    } else {
                        this.kaiguan = true;
                    }
                }
            });
        },
        //权限目录
        async getPowerMenu() {
            let params = 'type=2';
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data;
            }
        },

        // 获取左边菜单栏
        async getlist() {
            this.loadig = true;
            const searchName = this.$route.query.searchcode ? this.$route.query.searchcode : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;

            const params = {
                company_country: this.searchCode,
                productKeyword: this.$route.query.searchcode == '' ? this.$route.query.searchname : this.$route.query.searchcode,

                // isDevSource:true
                province_en: this.province,
                city_en: this.city,
                industry_en: this.industryCheck,
                company_class_code: this.companyTypeCheck,
                searchType: this.keywords,
                scale_code: this.companySizeCheck,
                reg_status: this.companyStatusCheck,
                listing_status_code: this.listedCompanyCheck,
                years: this.timePeriodCheck,
            };
            getoptionsProduct(params)
                .then(res => {
                    if (res && res.code) {
                        this.loadig = false;
                        this.countryList = res.data.geographicalLocation;
                        this.industryList = res.data.industry;
                        this.industryPreList = res.data.industry;
                        this.companyTypeList = res.data.companyTypeList;
                        this.companySizeList = res.data.companySizeList;
                        this.companyStatusList = res.data.regStatus;
                        this.listedCompanyList = res.data.listingStatusList;
                        this.timePeriodList = res.data.yearsOfEstablishment;

                        let list = this.countryList;
                        if (list.length > 0) {
                            list.map((item, index) => {
                                item.label = item.companyCountry.name;
                                item.value = item.companyCountry.value;
                                item.count = item.companyCountry.count;
                                if (item.companyProvince) {
                                    item.children = item.companyProvince;
                                    item.children.map(it => {
                                        it.label = it.name;
                                    });
                                }
                            });
                        }
                        this.countryPreList = list;
                    } else {
                        this.loadig = false;
                    }
                })
                .catch(res => {
                    this.loadig = false;
                });
        },

        //国家级联搜索不区分大小写
        inptCountrySearch() {
            let queryString = this.cascInptCountry;
            var countryList = this.countryPreList;
            this.$nextTick(() => {
                this.$refs.Country.dropDownVisible = true;
            });
            var results = queryString ? countryList.filter(this.createFilter(queryString)) : countryList;
            this.countryList = results;
        },
        createFilter(queryString) {
            return countryList => {
                return countryList.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        //行业级联搜索不区分大小写
        inptIndSearch() {
            let queryString = this.cascInptIndustry;
            var industryList = this.industryPreList;
            this.$nextTick(() => {
                this.$refs.inptIndSearch.dropDownVisible = true;
            });
            var results = queryString ? industryList.filter(this.createFilterInd(queryString)) : industryList;
            this.industryList = results;
        },
        createFilterInd(queryString) {
            return industryList => {
                return industryList.name.toLowerCase().indexOf(queryString.toLowerCase()) > -1;
            };
        },
        //二次查询
        RetrievalResult() {
            let hasPower = this.powerResult[0].list[0].power;
            if (hasPower) {
                console.log(`11`, 11);
                this.init();
            } else {
                this.message();
            }
        },

        //菜单checkbox选择
        menuCheck(val) {
            if (val === 'country') {
                const checkedNodes = this.$refs.Country.getCheckedNodes();
                const allNodes = this.$refs.Country.getCheckedNodes(true);
                const isAllSelected = checkedNodes.length === allNodes.length;
                let arrCountry = [];
                let arrProv = [];
                let arrCountryTag = [];
                let arrCountrycheck = [];
                this.countryCheckList.forEach(item => {
                    if (isAllSelected) {
                        if (item.length == 1) {
                            if (!arrCountry.includes(item[0])) {
                                this.countryList.forEach(ite => {
                                    if (ite.value == item[0]) {
                                        arrCountryTag.push(ite.label); //展示
                                    }
                                });

                                arrCountry.push(item[0]); //传值
                            }
                        } else {
                            arrProv.push(item[1]);
                        }
                    } else {
                        if (!arrCountry.includes(item[0])) {
                            this.countryList.forEach(ite => {
                                if (ite.value == item[0]) {
                                    arrCountryTag.push(ite.label); //展示
                                }
                            });

                            arrCountry.push(item[0]); //传值
                        }
                    }

                    if (!arrCountrycheck.includes(item[0])) {
                        arrCountrycheck.push(item[0]); //传值
                    }
                });
                this.countryTag = arrCountryTag;
                this.arrProvTag = arrProv;
                this.countryCheck = arrCountrycheck.join('@@');
                // this.countryCheck = arrCountry.join('@@');
                this.province = arrProv.join('@@');

                //显示隐藏国家级联选择器的输入框
                if (this.countryTag.length > 0 || this.arrProvTag.length > 0) {
                    this.showInptCountry = false;
                    this.cascInptCountry = '';
                } else {
                    this.showInptCountry = true;
                }
            } else {
                if (val === 'Industry') {
                    let arr = [];
                    this.industryCheckList.forEach(item => {
                        this.industryList.forEach(it => {
                            if (it.value == item) {
                                arr.push(it);
                            }
                        });
                    });
                    this.industryTag = arr;
                    this.industryCheck = this.industryCheckList[1];
                    //显示隐藏行业级联选择器的输入框
                    if (this.industryCheckList.length > 0) {
                        this.showInptIndustry = false;
                        this.cascInptIndustry = '';
                    } else {
                        this.showInptIndustry = true;
                    }
                }
                // if (val === 'companyType') {
                let arr = [];
                this.companyTypeCheckList.forEach(item => {
                    this.companyTypeList.forEach(it => {
                        if (it.name == item) {
                            arr.push(it);
                        }
                    });
                });
                this.companyTypeTag = arr;
                let arrlist = [];
                arr.map(item => {
                    arrlist.push(item.value);
                });
                // this.companyTypeTag = arr;
                this.companyTypeCheck = arrlist.join('@@');
                // }
                // if (val === 'companySize') {
                let arr1 = [];
                this.companySizeCheckList.forEach(item => {
                    this.companySizeList.forEach(it => {
                        if (it.name == item) {
                            arr1.push(it);
                        }
                    });
                });
                let arrlist1 = [];
                arr1.map(item => {
                    arrlist1.push(item.value);
                });
                this.companySizeTag = arr1;
                this.companySizeCheck = arrlist1.join('@@');
                // }
                // if (val === 'listedCompany') {
                let arr2 = [];
                this.listedCompanyCheckList.forEach(item => {
                    this.listedCompanyList.forEach(it => {
                        if (it.name == item) {
                            arr2.push(it);
                        }
                    });
                });
                let arrlist2 = [];
                arr2.map(item => {
                    arrlist2.push(item.value);
                });
                this.listedCompanyTag = arr2;
                this.listedCompanyCheck = arrlist2.join('@@');
                // }
                // if (val === 'companyStatus') {
                let arr3 = [];
                this.companyStatusCheckList.forEach(item => {
                    this.companyStatusList.forEach(it => {
                        if (it.name == item) {
                            arr3.push(it);
                        }
                    });
                });
                let arrlist3 = [];
                arr3.map(item => {
                    arrlist3.push(item.value);
                });
                this.companyStatusTag = arr3;
                this.companyStatusCheck = arrlist3.join('@@');
                // }
                // if (val === 'timePeriod') {
                let arr4 = [];
                this.timePeriodCheckList.forEach(item => {
                    this.timePeriodList.forEach(it => {
                        if (it.name == item) {
                            arr4.push(it);
                        }
                    });
                });
                let arrlist4 = [];
                arr4.map(item => {
                    arrlist4.push(item.value);
                });
                this.timeTag = arr4;
                this.timePeriodCheck = arrlist4.join('@@');
                // }

                this.getlist();
            }
        },
        //清除标签
        handleClose(tag, name) {
            // }
            if (name === 'countryTag') {
                this.countryTag.splice(this.countryTag.indexOf(tag), 1);
                let tagcountry = '';
                this.countryCheckList.forEach((item, index) => {
                    this.countryList.forEach(ite => {
                        if (ite.label == tag) {
                            tagcountry = ite.value;
                        }
                    });

                    if (item.includes(tagcountry)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });

                if (this.countryTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                // 全选变成只选择国家
                let countryTag1 = [];

                this.countryTag.map(item => {
                    this.countryList.forEach(ite => {
                        if (ite.label == item) {
                            countryTag1.push(ite.value);
                        }
                    });
                });
                this.countryCheck = countryTag1.join('@@');
                if (countryTag1.length == 0) {
                    this.countryCheckList = [];
                }
                // this.countryCheck = this.countryTag.join('@@');
            }

            if (name === 'arrProvTag') {
                this.arrProvTag.splice(this.arrProvTag.indexOf(tag), 1);
                this.countryCheckList.forEach((item, index) => {
                    if (item.includes(tag)) {
                        this.countryCheckList.splice(index, 1);
                    }
                });

                if (this.arrProvTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                this.province = this.arrProvTag.join('@@');
            }
            if (name === 'industryTag') {
                this.industryTag.splice(this.industryTag.indexOf(tag), 1);
                this.industryCheckList = [];
                this.industryCheck = '';
                //显示隐藏行业级联选择器的输入框
                if (this.industryCheckList.length > 0) {
                    this.showInptIndustry = false;
                    this.cascInptIndustry = '';
                } else {
                    this.showInptIndustry = true;
                }
            }
            if (name === 'companyTypeTag') {
                this.companyTypeTag.splice(this.companyTypeTag.indexOf(tag), 1);
                this.companyTypeCheckList.splice(this.companyTypeCheckList.indexOf(tag.value), 1);
                // this.companyTypeCheck = this.companyTypeCheckList.join('@@');
                let arr1 = [];
                this.companyTypeTag.map(item => {
                    arr1.push(item.value);
                });
                this.companyTypeCheck = arr1.join('@@');
            }
            if (name === 'companySizeTag') {
                this.companySizeTag.splice(this.companySizeTag.indexOf(tag), 1);
                this.companySizeCheckList.splice(this.companySizeCheckList.indexOf(tag.value), 1);
                // this.companySizeCheck = this.companySizeCheckList.join('@@');
                let arr2 = [];
                this.companySizeTag.map(item => {
                    arr2.push(item.value);
                });
                this.companySizeCheck = arr2.join('@@');
            }
            if (name === 'companyStatusTag') {
                this.companyStatusTag.splice(this.companyStatusTag.indexOf(tag), 1);
                this.companyStatusCheckList.splice(this.companyStatusCheckList.indexOf(tag.value), 1);
                // this.companyStatusCheck = this.companyStatusCheckList.join('@@');
                let arr3 = [];
                this.companyStatusTag.map(item => {
                    arr3.push(item.value);
                });
                this.companyStatusCheck = arr3.join('@@');
            }
            if (name === 'listedCompanyTag') {
                this.listedCompanyTag.splice(this.listedCompanyTag.indexOf(tag), 1);
                this.listedCompanyCheckList.splice(this.listedCompanyCheckList.indexOf(tag.value), 1);
                // this.listedCompanyCheck = this.listedCompanyCheckList.join('@@');
                let arr4 = [];
                this.listedCompanyTag.map(item => {
                    arr4.push(item.value);
                });
                this.listedCompanyCheck = arr4.join('@@');
            }
            if (name === 'timeTag') {
                this.timeTag.splice(this.timeTag.indexOf(tag), 1);
                this.timePeriodCheckList.splice(this.timePeriodCheckList.indexOf(tag.value), 1);
                // this.timePeriodCheck = this.timePeriodCheckList.join('@@');
                let arr5 = [];
                this.timeTag.map(item => {
                    arr5.push(item.value);
                });
                this.timePeriodCheck = arr5.join('@@');
            }
            this.getlist();
        },
        // 清楚所有标签

        clearAllTags() {
            this.countryTag = [];
            this.industryTag = [];
            this.companyTypeTag = [];
            this.companySizeTag = [];
            this.companyStatusTag = [];
            this.listedCompanyTag = [];
            this.timeTag = [];
            this.province = '';
            this.arrProvTag = [];
            this.countryCheckList = [];
            this.industryCheckList = [];
            this.companyTypeCheckList = [];
            this.companySizeCheckList = [];
            this.companyStatusCheckList = [];
            this.listedCompanyCheckList = [];
            this.timeTag = [];
            this.countryCheck = '';
            this.industryCheck = '';
            this.companyTypeCheck = '';
            this.companySizeCheck = '';
            this.companyStatusCheck = '';
            this.listedCompanyCheck = '';
            this.timePeriodCheck = '';
            this.showInptCountry = true;
            this.getlist();
        },
        // 添加
        handleCheckedCitiesChange(value) {},
        //导出
        async exportCompany() {
            if (this.show == 3) {
                let hasPower = this.powerResult[3].list[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    this.DetailsForm = this.companyList;
                }
            } else {
                this.DetailsForm = this.companyList;
            }
        },
        // table 点击搜索
        xuanze() {
            if (this.$route.query.searchname || this.$route.query.searchcode) {
                this.gerpeclist();
            }
        },

        handleSelect(item) {
            this.code = item.val;
        },
        /**
         *用户购买报告 "type": 17 ：buyers/suppliers
         */
        getOrdersAdd() {
            const type = 16;
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            //1  :线上报告
            let params = {
                companyName: this.$route.query.searchcode == '' ? this.$route.query.selproduct : this.$route.query.searchcode, //"贸易产品描述",
                aaaId: this.code, //"贸易产品描述code",
                companyCountry: 'CN', // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        this.getPowerMenu();
                        resolve(true);
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                        }, 1000);

                        resolve(false);
                    }
                });
            });
        },
        // 跳转国家
        gotocountry(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.company_country,
            });

            window.open(routeData.href, '_blank');
        },
        // 跳转国家
        gotocountry1(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.countryCode,
            });
            window.open(routeData.href, '_blank');
        },
        //公司详情跳转
        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));

            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaa_id + '&companyCountry=' + row.company_country + '&information=Search Products',
            });
            window.open(routeData.href, '_blank');
        },
        setid1(row) {
            sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaid + '&companyCountry=' + row.countryEn + '&information=Search Products',
            });
            window.open(routeData.href, '_blank');
        },
        async init(seachname) {
            this.loading = true;
            this.searchName = this.$route.query.searchcode ? this.$route.query.searchcode : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            // console.log(`this.searchcode`, this.searchCode);
            this.searchCode = this.searchCode == undefined ? '' : this.searchCode;
            let params = {
                page: this.page.pageNo,
                productKeyword: this.$route.query.searchcode == '' ? this.$route.query.searchname : this.$route.query.searchcode,
                // productThirdKeyword: this.state?this.state:this.$route.query.searchname,
                productThirdKeyword: '',
                pageSize: this.page.pageSize,
                company_country: this.searchCode,
                province_en: this.province,
                city_en: this.city,
                isic_code: this.industryCheck,
                company_class_code: this.companyTypeCheck,
                // searchType:this.keywords,
                scale_code: this.companySizeCheck,
                reg_status: this.companyStatusCheck,
                listing_status_code: this.listedCompanyCheck,
                years: this.timePeriodCheck,
            };

            params.productKeyword = params.productKeyword == undefined ? '' : params.productKeyword;
            // 'productThirdKeyword=' + params.productThirdKeyword + '&company_country=' + this.searchCode + '&productKeyword=' + params.productKeyword + '&page=' + params.page + '&pageSize=' + params.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&isic_code=' + this.industryCheck + '&company_class_code=' + this.companyTypeCheck + '&scale_code=' + this.companySizeCheck + '&reg_status=' + this.companyStatusCheck + '&listing_status_code=' + this.listedCompanyCheck + '&years=' + this.timePeriodCheck
            const reslist = await searchproduct(params);
            if (reslist && reslist.code) {
                this.loading = false;
                this.page.total = reslist.data.total;
                let list = (this.parentTable = reslist.data.rows);
                //查找本地图片
                let that = this;
                list = list.map((item, index) => {
                    item.productsseach = that.$route.query.seach == '' ? that.$route.query.selectlable : that.$route.query.seach;
                    let countryImg = this.getCountryImgUrl(item.company_country);
                    let flag = require('../../../static' + countryImg);
                    this.parentTable[index].flag = flag;
                    this.refresh++;
                });
            } else {
                this.loading = false;
                this.parentTable = [];

                if (reslist.ret == '1002') {
                    this.ret = true;
                } else {
                    this.ret = false;
                }
            }
        },

        // 点击products后获取后三个tab数据
        async gerpeclist() {
            const pam = {
                productCodes: this.$route.query.searchname ? this.$route.query.searchname : this.$route.query.searchcode,
            };
            const resprelist = await getProductAggregationResult(pam);
            if (resprelist.code) {
                this.TopCountry = resprelist.data.productTopCountry;
                this.CompanySize = resprelist.data.productCompanySize;
                this.players = resprelist.data.productTopPlayers;

                let map = {};
                let bjdata = [];
                this.TopCountry.map(item => {
                    bjdata.push({ name: item.countryEn, value: 9100 });
                    map[item.countryEn] = [Number(item.lng.trim()), Number(item.lat.trim())];
                });

                this.echarts1();
                this.echarts();
                this.echarts2(map, bjdata);
               
            } else {
                this.TopCountry = [];
                this.echarts1();
                this.echarts();
            }
        },

        echarts() {
            let pingdata = [];
            this.CompanySize.map(item => {
                pingdata.push({ name: item.companySizeEn, value: item.transcations });
            });

            // console.log(pingdata);
            const myChart = this.$echarts.init(this.$refs.trendChart);
            const myChart1 = this.$echarts.init(this.$refs.trendphoneChart);

            const option = {
                tooltip: {
                    trigger: 'item',
                    shadowColor: '#022955',
                    textStyle: {
                        color: '#022955',
                        fontSize: 14,
                    },
                },
                legend: {
                    top: '5%',
                    left: 'center',
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: pingdata,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                // shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };

            myChart.setOption(option);
            myChart1.setOption(option);
        },
        // 柱状
        echarts1() {
            let data = [];
            let data1 = [];
            this.TopCountry.forEach(item => {
                data.push(item.countryEn);
                data1.push(item.percent);
            });

            const myChart = this.$echarts.init(this.$refs.trendChart1);
            const myChart1 = this.$echarts.init(this.$refs.trendChartphone1);

            const option = {
                tooltip: {
                    // trigger: 'axis',
                    borderWidth: 1,
                    borderColor: '#ccc',
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#022955',
                        fontSize: 14,
                    },
                    // axisPointer: {
                    //   type: 'shadow',
                    // },
                    formatter: '{b}<br/> {c0}%',
                },
                grid: {
                    left: '9%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                    max: 100,
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            color: '#8497AB',
                        },
                    },
                    axisLabel: {
                        show: true,
                        width: 1,
                        color: '#8497AB',
                        formatter: '{value}%',
                    },
                    axisTick: {
                        show: false,
                    },
                    // data: ['0%', '20%', '40%', '60%', '80%', '100%']
                },

                yAxis: [
                    {
                        type: 'category',
                        data: data,
                        splitLine: {
                            lineStyle: {
                                width: 1,
                                type: 'dashed',
                                color: '#8497AB',
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#8497AB',
                            },
                        },
                        axisLabel: {
                            fontSize: 10,
                            color: '#8497AB',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        type: 'bar',
                        barWidth: '20',
                        itemStyle: { color: '#8497AB' },
                        emphasis: {
                            itemStyle: {
                                color: '#FF7600',
                            },
                        },
                        data: data1,
                    },
                ],
            };

            myChart.setOption(option);
            myChart1.setOption(option);
            // myChart.resize()
        },
        // 地图显示
        echarts2(map, bjdata) {
             console.log(map);
             console.log(bjdata);
            const myChart = this.$echarts.init(this.$refs.trendChart2);
            const myChart1 = this.$echarts.init(this.$refs.trendChartphone2);

            var geoCoordMap = map;
            var BJData = [bjdata];
            var convertData = function (data) {
                var res = [];
                for (var i = 0; i < data.length; i++) {
                    var dataItem = data[i];
                    var fromCoord = geoCoordMap[dataItem[0].name];
                    var toCoord = geoCoordMap[dataItem[1].name];
                    if (fromCoord && toCoord) {
                        res.push([
                            {
                                coord: fromCoord,
                                value: dataItem[0].value,
                            },
                            {
                                coord: toCoord,
                            },
                        ]);
                    }
                }
                return res;
            };

            var series = [];
            [[BJData]].forEach(function (item, i) {
                let ite = item[0];
                series.push({
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    zlevel: 2,
                    rippleEffect: {
                        //涟漪特效

                        period: 4, //动画时间，值越小速度越快
                        brushType: 'fill', //波纹绘制方式 stroke, fill
                        scale: 0, //波纹圆环最大限制，值越大波纹越大
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'right', //显示位置
                            offset: [5, 0], //偏移设置
                            formatter: '{b}', //圆环显示文字
                        },
                        emphasis: {
                            show: true,
                        },
                    },
                    symbol: 'circle',

                    symbolSize: function (val) {
                        return 4 + val[2] / 1000; //圆环大小
                    },
                    itemStyle: {
                        normal: {
                            show: false,
                        },
                    },
                    data: ite[0].map((dataItem, index) => {
                        return { name: dataItem.name, value: geoCoordMap[dataItem.name].concat([dataItem.value]) };
                    }),
                });
            });
            console.log(series);
            const option = {
                backgroundColor: '#B0CCE2',

                visualMap: {
                    //图例值控制
                    min: 0,
                    max: 10000,
                    show: false,
                    calculable: true,
                    color: ['#ff9500'],
                    textStyle: {
                        color: '#fff',
                    },
                },
                geo: {
                    map: 'world',
                    label: {
                        emphasis: {
                            show: false,
                        },
                    },
                    roam: true, //是否允许缩放
                    layoutCenter: ['50%', '50%'], //地图位置
                    layoutSize: '180%',
                    itemStyle: {
                        normal: {
                            areaColor: '#edf3f3',
                            borderColor: '#9d9f9f',
                        },
                        emphasis: {
                            areaColor: '#2a333d',
                        },
                    },
                },

                series: series,
            };
            myChart.setOption(option);
            myChart1.setOption(option);
            // myChart.resize()
        },
        // 产品tab切换
        tabcard(index) {
            if (this.kaiguan) {
                this.message();
            } else {
                this.show = index;
            }
        },

        //分页
        onPageChangeList(pageNo) {
            let power = this.powerResult[0].list[4].power;
            if (power) {
                this.page.pageNo = pageNo;
                this.init();
            } else {
                this.message();
            }
        },
        currtentPageChange(pageSize) {
            let power = this.powerResult[0].list[4].power;
            if (power) {
                this.page.pageSize = pageSize;
                this.init();
            } else {
                this.message();
            }
        },
        onChildPageChange() {},
        // 收藏
        async addToList(row) {
            let power = this.powerResult[0].list[3].power;
            if (power) {
                let collIds = [];
                const params = {
                    crefoNo: row.aaa_id,
                };
                await getIsCollect(params).then(res => {
                    if (res.code) {
                        collIds = res.data;
                        this.collecObj.isFavorite = true;
                    }
                });
                this.collecObj = row;
                this.collecObj.collectionIdList = collIds;
                this.addPopIndex = true;
            } else {
                this.message();
            }

            // this.getCollData()
        },
        async getCollData() {
            await selectGroup().then(res => {
                if (res && res.code) {
                    this.addList = res.data.rows;
                }
            });
        },
        closeDialog1(value) {
            this.addPopIndex = value[0];
            this.collecObj.isFavorite = value[1];
        },
        //导出按钮
        exportResult() {
            let power = this.powerResult[0].list[2].power;
            if (power) {
                this.routeLoad = true;
                this.getRouteList();
                this.exportDialog = true;
            } else {
                this.message();
            }
        },
        // 滚动加载方法

        // 获取数据
        async getRouteList() {
            if (this.show == 3) {
                let hasPower = this.powerResult[3].list[0].power;
                // if (hasPower || (!hasPower && (await this.getOrdersAdd()))) {
                const pam = {
                    productCodes: this.$route.query.searchname ? this.$route.query.searchname : this.$route.query.searchcode,
                };

                const resprelist = await getProductAggregationResult(pam);
                if (resprelist.code) {
                    this.companyList = [];
                    this.players = resprelist.data.productTopPlayers;
                }
                this.companyList = this.players;
                // }
            } else {
                this.exportLoding = true;
                this.searchName = this.$route.query.searchcode ? this.$route.query.searchcode : '';
                this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
                this.searchCode = this.searchCode == undefined ? '' : this.searchCode;
                let params1 = {
                    productKeyword: this.$route.query.searchcode == '' ? this.$route.query.searchname : this.$route.query.searchcode,
                    productThirdKeyword: '',
                    page: 1,
                    pageSize: 100,
                    company_country: this.searchCode,
                    province_en: this.province,
                    city_en: this.city,
                    isic_code: this.industryCheck,
                    company_class_code: this.companyTypeCheck,
                    // searchType:this.keywords,
                    scale_code: this.companySizeCheck,
                    reg_status: this.companyStatusCheck,
                    listing_status_code: this.listedCompanyCheck,
                    years: this.timePeriodCheck,
                };

                params1.productKeyword = params1.productKeyword == undefined ? '' : params1.productKeyword;
                let arr1 = [];
                let arr2 = [];
                let that = this;
                // 'productThirdKeyword=' + params.productThirdKeyword + '&company_country=' + this.searchCode + '&productKeyword=' + params.productKeyword + '&page=' + this.pageNo + '&pageSize=' + this.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&industry_cn=' + this.industryCheck + '&company_type_code=' + this.companyTypeCheck + '&scale_code=' + this.companySizeCheck + '&reg_status=' + this.companyStatusCheck + '&listing_status_code=' + this.listedCompanyCheck + '&years=' + this.timePeriodCheck
                searchproduct(params1)
                    .then(res => {
                        if (res.code) {
                            this.companyList = [];
                            arr1 = res.data.rows;
                            //查找本地图片
                            arr1.map((item, index) => {
                                item.productsseach = that.$route.query.seach == '' ? that.$route.query.selectlable : that.$route.query.seach;
                            });
                        }
                        params1.page = 2;

                        searchproduct(params1)
                            .then(Res => {
                                if (res.code) {
                                    arr2 = Res.data.rows;
                                    arr2.map((item, index) => {
                                        item.productsseach = that.$route.query.seach == '' ? that.$route.query.selectlable : that.$route.query.seach;
                                    });
                                }
                                this.companyList = [...arr1, ...arr2];
                            })
                            .cath(res => {
                                this.exportLoding = false;
                            });
                    })
                    .catch(res => {
                        this.exportLoding = false;
                    });
            }
        },
        //模糊查询

        //弹窗全选公司
        // checkedAllCompany(val) {
        //     this.checkedCompanyList = val ? this.companyList : [];
        // },
        //展开当前关闭其他展开表格
    },
};
</script>
<style scoped lang="less">
.table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 46px !important;
}
.table_box .el-table .cell a {
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
    }
}

/deep/ .el-table__expanded-cell {
    padding: 20px 16px;
}

.right_cont_box1 {
    width: 100%;

    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.table_box4 {
    display: flex;
    flex: 1;

    .echart {
        width: 62%;

        background: #e7eaedff;
        border-radius: 0px 14px 14px 0px;
        border: 1px solid #e7eaed;

        padding: 20px 20px 20px 10px;

        .trendEchartBox {
            width: 560px;
            height: 640px;
            background: #ffffff;
            border-radius: 14px;
        }

        .trendEchartBox1 {
            width: 560px;
            height: 323px;
            background: #ffffff;
            border-radius: 14px;
        }
    }

    .map-main {
        width: 560px;
        height: 323px;
    }
}

.add_list {
    margin-bottom: 20px;
}

.add_item {
    display: flex;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}

.add_item:hover {
    background: #e7eaed;
}

.add_item.on {
    background: #1290c9;
}

.add_item.on .add_des_txt,
.add_item.on .add_des_title {
    color: #ffffff;
}

.add_img {
    height: 42px;
}

.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}

.add_des_title {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
}

.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}

.btn_create {
    width: 180px;
}

.btn_return {
    width: 140px;
}

.btn_export_left {
    width: 30px;
    height: 30px;
    background: #1290c9;
    border-radius: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}

.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.right_tabcard {
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    margin-bottom: 10px;

    .right_tabcard_box {
        height: 34px;
        background: #f2f5f9;
        border-radius: 8px;
        border: 1px solid #e7eaed;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: ArialMT;
        color: #8497ab;

        padding: 0 65px;

        .tabcard_box_text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 110px;
            font-size: 14px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #8497abff;
            background-color: #f2f5f9;
            margin: 0 25px;
        }

        .tabcard_box_xuan {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 24px;
            background: #ff7600;
            box-shadow: 0px 1px 2px 0px rgba(196, 67, 6, 1);
            border-radius: 4px;
            color: #ffffff;
            font-size: 14px;
        }
    }
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}
.line_3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}
.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}

// table搜索
.tbl_search_box {
    position: absolute;
    left: 555px;
    top: 1px;
}

.tbl_search {
    position: relative;
    width: 260px;
    height: 40px;
    z-index: 9;
}

.tbl_search input {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}

.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}

.table_add img {
    width: 14px;
    margin-left: 4px;
}

.table_box {
    position: relative;
}

/deep/.el-input__icon {
    font-weight: 500;
    font-size: 20px;
    color: #1290c9;
}

/deep/.el-input--suffix .el-input__inner {
    border-radius: 10px;
}

.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    // float: left;
    max-width: 888px;
}
.condition_labb /deep/ .el-tag {
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.indystry .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100% !important;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.hedr-icon-ydong {
    position: absolute;
    left: 215px;
    font-weight: 600;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/ .el-collapse-item__header {
    position: relative;
}
.company_link /deep/ .cell {
    line-height: 16px;
}
// .table_box /deep/ .el-table th>.cell:first-child {
//   padding-left: 7px !important;
// }
.table_box /deep/ .el-table__cell:first-child .cell {
    // padding-left: 10px;
    line-height: 16px;
}
// 寻找伙伴
.title {
    margin-bottom: 10px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
    display: flex;
    color: #022955;
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.block-con {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding-bottom: 10px;
    font-size: 14px;
    // overflow: hidden;
    color: #333333;
}
.block-con .block-con-head {
    // padding: 10px 30px;
}
.question-head {
    width: 100%;
    position: relative;
    text-align: center;
    line-height: 30px;
    padding: 5px 0;
    margin: 10px 0;
    border-bottom: 1px solid;
}
.block-con-body {
    padding-bottom: 0;
    min-height: 200px;
    margin-top: 15px;
}
.question-step-next {
    width: 100%;
    margin: 40px 0 20px;
}
.justify-center {
    display: flex;
    justify-content: center;
}
.huandeng {
    height: 300px;
}
.question-step-con {
    line-height: 30px;
    margin-bottom: 25px;
}
.question-step-con {
    // padding: 0 50px;
    min-height: 200px;
    color: #022955;
}
.el-autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
}
// 寻找伙伴选择国家下啦
/* 选择下拉 */
.pro_cas,
.search_left {
    width: 100%;
    // height: 150%;
    position: relative;
}
.search_left::after {
    content: '';
    position: absolute;
    right: 0;
    top: 20px;
    width: 1px;
    height: 20px;
    background-color: #dddddd;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-input__inner {
    border: none;
    font-size: 16px;
}
.country_flag {
    display: flex;
    align-items: center;
}
.country_flag_img {
    width: 36px;
    height: 22px;
    margin: 22px 6px 24px 20px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.sel_value {
    width: 74px;
    font-size: 16px;
    word-break: break-all;
}
.el-select {
    width: 843px;
}
.pro_cas,
.search_left .el-select {
    width: 843px;
    position: absolute;
    top: 0;
}
.pro_cas /deep/ .el-input__inner,
.search_left /deep/ .el-select .el-input__inner {
    background-color: transparent;
    /* height: 68px !important; */
    /* opacity: 0; */
    padding-left: 18px;
}
.search_left.on /deep/ .el-select .el-input__inner {
    padding-left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pro_cas /deep/ .el-input__inner::placeholder,
.search_left /deep/ .el-select .el-input__inner::placeholder {
    color: #022955;
}
.sel_place {
    width: 100%;
    line-height: 68px;
    text-align: center;
    display: inline-block;
}
.el-select-dropdown__wrap {
    max-height: 375px !important;
}
.el-select-dropdown__list {
    padding: 10px 0 !important;
}
.search_sel_container {
    width: 1200px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
    width: 240px;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after {
    width: 1px;
    background-color: #eee;
}
.search_sel_container /deep/ .el-tabs__active-bar {
    display: none;
}
.search_sel_container .right {
    width: 959px;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-height: 350px;
    align-content: flex-start;
    margin-right: 1px;
    padding: 0 12px;
    overflow: auto;
}
.search_sel_container /deep/ .el-tabs--left .el-tabs__item.is-left {
    height: 42px;
    line-height: 42px;
    margin-bottom: 8px;
}
.search_sel_container /deep/ .el-tabs__item.is-left.is-active {
    background: rgba(18, 144, 201, 0.1);
}
.el-select-dropdown__item:hover {
    background: rgba(18, 144, 201, 0.1);
}

.search_sel_container /deep/ .el-tabs__item:hover {
    color: #1290c9;
    font-weight: normal;
}
.search_sel_container .left_txt {
    font-size: 16px;
}
.search_sel_container .right_txt {
    font-size: 12px;
    color: #8497ab;
}
.search_sel_container .right .el-select-dropdown__item {
    width: 222px;
    height: 56px;
    padding: 0 10px;
    margin: 10px 5px;
    border: 1px solid #fff;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected,
.search_sel_container .right /deep/ .el-select-dropdown__item.hover {
    background: rgba(18, 144, 201, 0.1);
    border-radius: 14px;
    border: 1px solid #1290c9;
}
.search_sel_container .right /deep/ .el-select-dropdown__item.selected {
    color: #022955;
    font-weight: normal;
}
.right_item {
    height: 100%;
    width: 100%;
}
.search_sel_container .right .img {
    width: 54px;
    height: 100%;
    margin-right: 5px;
}
.search_sel_container .right .img img {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.search_sel_container .right .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.search_sel_container .right .rBox .coun_title {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search_sel_container .right .rBox .coun_num {
    font-size: 12px;
    line-height: 1;
    color: #8497ab;
}
.el-select__tags {
    flex-wrap: nowrap;
}
.create_item /deep/ .el-input__inner {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #8497ab;
}
.create_item /deep/ .el-autocomplete .el-input__inner {
    background-image: url(../../assets/img/head_search_ic.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 98%;
    padding-right: 60px;
}
.create_item {
    margin-bottom: 20px;
}
.cop_title img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.name_ser {
    position: relative;
}
.ser_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 40px;
}
.create_item_lab {
    font-size: 14px;
    color: #022955;
    margin-bottom: 28px;
}
.name_ser {
    position: relative;
}
.ser_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 40px;
}
.question-head-step {
    display: flex;
    position: absolute;
    right: 0;
    top: 20px;
}
.question-head-step img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
.question-step-question {
    margin-bottom: 25px;
}
.pageBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
    height: 40px;
    background: #f2f5f9;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid #e7eaed;
}
.isactive-tag {
    background-color: #1290c9 !important;
    color: #ffffff !important;
}
// 更多精准推送
.detail_cont-one {
    font-size: 14px;
    margin-bottom: 20px;
    color: #022955;
}
.detail_cont-one h1 {
    font-size: 14px;
    line-height: 45px;
}
.detail_cont-one .el-checkbox {
    width: 215px;
    line-height: 30px;
}
.detail_cont-one .el-radio__inner {
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
}

.detail_cont-one /deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
    border-color: #409eff;
}
.detail_cont-one /deep/ .el-radio__inner::after {
    width: 12px;
    height: 12px;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner {
    // border-color: #1290C9;
    border-color: #409eff !important;
    background: #fff;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    background: #409eff;
}
.detail_cont-one /deep/ .el-radio__input.is-checked + .el-radio__label {
    // color: #1290C9;
    font-size: 14px;
    color: #409eff !important;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    color: #409eff !important;
}
.form-email {
    display: flex;
    width: 100%;
}
.koudian {
    font-size: 14px;
    padding: 10px;
    padding-left: 0;
    // border: 1px solid #ccc;
    display: flex;
    // align-items: center;
    color: #022955 !important;
}
.koudian-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
// .koudian-cont:nth-of-type(1){
//     border-right:1px solid #ccc;
// }
.block-con-body-text {
    word-break: keep-all;
    text-align: justify;
    font-size: 14px;
    color: #022955;
}
.block-con-body-text p {
    margin-top: 25px;
}
.el-textarea {
    width: 810px;
}
/deep/ .el-textarea__inner:focus {
    border-color: #ccc !important;
}
/deep/ .el-input__inner:focus {
    border-color: #ccc !important;
}
.dialog_detail_title-order,
.dialog_detail_title {
    font-size: 20px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 23px;
    // margin-bottom: 20px;
    text-align: center;
    padding-bottom: 20px;
    /* border: 1px solid; */
    border-bottom: 1px solid;
    text-align: left;
    // margin-left: 10px;
}
.dialog_detail_title-order {
    border-bottom: none;
    padding-bottom: 0px;
    text-align: center;
}
.koudian-title {
    font-size: 14px;
    width: 200px;
}
.link {
    color: #1290c9;
    text-decoration: underline;
}
.casca_count {
    position: inherit;
    // left: 160px;
    // bottom: 0px;
}
.table_box /deep/ .el-table__empty-block .el-table__empty-text {
    width: auto;
    /* justify-content: flex-start !important; */
}
.company-head {
    /* line-height: 35px; */
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px;
}
.company-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
    margin-left: 15px;
}
.company-item {
    width: 48%;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #333333;
    flex-wrap: wrap;
}
.company-item span {
    margin-left: 20px;
}
.company-item .el-tag {
    border: none;
    margin: 0;
    /* padding: 0; */
    padding-right: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.dialog_detail_export {
    text-align: center;
    border: none;
    padding-bottom: 0;
}
.zhankai {
    width: 0px;
}
.koudianwidth{
    width: 500px ;
}
@media (max-width: 821px) {
    .koudianwidth{
    width: 100% !important ;
}
    .phone_right_cont_box {
        display: block !important;
    }
    .computer_right_cont_box {
        display: none;
    }
    .phone_right_cont_box {
        width: 100%;
        /* padding: 15px; */
        padding: 0px 5px;
        font-size: 14px;
        /* box-shadow: 0px 2px 6px 1px rgba(10,33,57,0.14); */
        background: #ffffff;
        margin-bottom: 20px;
    }
  
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 25px;
        height: 15px;

        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .line_1 {
        white-space: nowrap !important;
    }
    .phone_left_menu {
        display: block !important;
    }
    .zhankai {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1290c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zhankai img {
        height: 15px;
        width: 15px;
    }
    .phoneappl_filters .left_menu {
        display: block !important;
    }
    .right_tabcard {
        height: 60px !important;
    }
    .right_tabcard .right_tabcard_box {
        height: 44px !important;
        padding: 0px !important;
    }
    .right_tabcard .right_tabcard_box .tabcard_box_xuan {
        width: auto !important;
        font-size: 12px !important;
        height: 34px !important;
    }
    .right_tabcard .right_tabcard_box .tabcard_box_text {
        font-size: 12px !important;
        width: auto !important;
        height: 34px !important;
    }
    .table_box4 {
        display: flex;
        flex-wrap: wrap !important;
        flex: 1;

        .echart {
            width: 360px !important;

            // background: #e7eaedff;
            border-radius: 0px;
            border: none !important;

            padding: 0px !important;

            .trendEchartBox {
                width: 360px !important;
                height: 640px;
                background: #ffffff;
            }

            .trendEchartBox1 {
                width: 360px !important;
                height: 323px;
                background: #ffffff;
            }
        }

        .map-main {
            width: 360px !important;
            height: 323px;
        }
    }
    .title_txt {
        display: none;
    }
    .el-textarea {
        width: 100% !important;
    }
    .el-select {
        width: 100% !important;
    }
    .pro_cas,
    .search_left .el-select {
        width: 100% !important;
    }
    /deep/ .el-carousel__container {
        height: 340px !important;
    }
    .huandeng {
        height: 340px !important;
    }
    .koudian {
        flex-wrap: wrap;
        font-size: 12px !important;
    }
    .koudian-title {
        width: 100% !important;
        line-height: 27px !important;
        font-size: 12px !important;
    }
    .phone_find_export {
        margin-right: 0px !important;
        margin-top: 10px !important;
    }
    .phone_find_more {
        margin-left: 0px !important;
    }
    .detail_cont-one h1{
       font-size: 12px !important;
           word-break: keep-all;
    }
    /deep/ .el-radio__label {
        font-size: 12px !important;
    }
    /deep/ .el-carousel__indicator--vertical {
        height: 0px;
    }
}
</style>