// 公司功能模块/国家功能模块

import { Loading } from 'element-ui'
import axios from './request'
//定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./;
const phone =mobil.test(currentUrl) 

const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.91.97:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.219.235:8088/test-api' //测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://3.101.104.100:8088/prod-api' 
let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
if(phone){
  api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
}

export const getoptions = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/search/advanced/options',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const getoptionsProduct= params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/search/advanced/optionsProduct',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
     
    }
  })
}
//收藏-是否收藏
export const getIsCollect = params => {
  return axios.post(api + '/gleagle/collection/getCollection' , params)
}
//Company Snapshot
export const getCompanySnap = params => {
  return axios.get(api + '/gleagle/csiv4/getCompanySnapshot?' + params)
}
//talbe栏_v2
export const getCompanyDetailMenuList = params => {
  // return axios.get(api + '/gleagle/csiv4/getTableTagList?' + params)

  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getTableTagList?'+params,
    method: 'GET',
    
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


//Faq
//获取问题集问题
export const getQuestionList = params => {
  return axios.get(api + '/gleagle/csiv4/getQuestionList?' + params)
}
//获取问题集答案
export const getAnswerList = params => {
  return axios.get(api + '/gleagle/csiv4/getAnswer?' + params)
}
//获取相似公司
export const getSimilarCompanies = params => {
  return axios.get(api + '/gleagle/csiv4/getSimilarCompanies?' + params)
}

//公司简介
export const getCompanyProfile = params => {
  return axios.get(api + '/gleagle/csiv4/getCompanyProfile?' + params)
}

//公司历史
export const getHistory = params => {
  return axios.get(api + '/gleagle/csiv4/getHistory?' + params)
}

//公司管理层
export const getManagement = params => {
  return axios.get(api + '/gleagle/csiv4/getManagement?' + params)
}
//联系方式
export const getContactInfo = params => {
  return axios.get(api + '/gleagle/csiv4/getContactInfo?' + params)
}
//获取进出口状况
export const getImportExport = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getImportExport?'+params,
    method: 'GET',

  })
  // return axios.get(api + '/gleagle/csiv4/getImportExport?' + params)
}
//获取进口状况
export const getImportExportTransaction = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getImportorExportTransaction?'+params,
    method: 'GET',

  })

}
//获取出口状况
export const getImportOrExport = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getImportorExport?'+params,
    method: 'GET',

  })
  // return axios.get(api + '/gleagle/csiv4/getImportorExport?' + params)
}
//获取供应链数据
export const getSupplyChain = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getSupplyChain?' + params,
    method: 'GET',

  })
  // return axios.get(api + '/gleagle/csiv4/getSupplyChain?' + params)
}
//获取公司制裁名单
export const getPublications = params => {
  return axios.get(api + '/gleagle/csiv4/getPublications?' + params)
}
//获取公司新闻
export const getNewsMedia = params => {
  return axios.get(api + '/gleagle/csiv4/getNewsMedia?' + params)
}
//企业对比
export const getCompanyCompare = params => {
  return axios.get(api + '/gleagle/csiv4/getCompanyCompare?' + params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
}
//企业更新
export const getUpdateDataCompare = params => {
  return axios.put(api + '/gleagle/creditService/updateData' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
}
// 数据反馈
export const getUpdatdataFeedback = params => {
  return axios.put(api + '/gleagle/creditService/dataFeedback' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
}
//文件上传
export const uploadFile = params => {
  return axios.post(api + '/gleagle/file/ossfile/upload', params, { type: "upload" })
}
//文件删除
export const deleteFile = params => {
  return axios.delete(api + '/gleagle/file/ossfile/delete', {params:params})
}
//企业报错
export const getReportError = params => {
  return axios.put(api + '/gleagle/creditService/reportError' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"}})
}

//国家集合
export const getCountryCollection = params => {
  return axios.post(api + '/gleagle/country/getContinentCollection', params)
}

//获取充值画面
export const getList = params => {
  return axios.post(api + '/gleagle/product/getList', params)
}
//获取价格
export const getPrice = params => {
  return axios.post(api + '/gleagle/product/getPrice', params,{isLoading:true})
}
//用户购买报告
export const ordersAdd = data => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/orders/add',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  
  // return axios.post(api + '/gleagle/orders/add', params)
}

//获取PublicClientKey
export const getPublicClientKey = params => {
  return axios.get(api + '/gleagle/pay/getPublicClientKey', params)
}
// //支付接口
// export const cardAuthorize = params => {
//   return axios.post(api + '/gleagle/pay/cardAuthorize', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",istrue: "zhifu"} },)
// }
export const cardAuthorize = params => {
  // return axios.post(api + '/gleagle/pay/cardAuthorize', params, { headers: { 'Content-Type': "application/x-www-form-urlencoded",istrue: "zhifu"} },)
  return axios.post(api + '/gleagle/pay/cardAuthorize', params)
}

//国家详情
export const getCountryData = params => {
  return axios.get(api + '/gleagle/csiv4/getCountryData?'+ params)
}
//国家信息相关
export const searchCountryInfo = params => {
  return axios.post(api + '/gleagle/company/searchCountryInfo', params,)
}
//国家信息详情
export const searchCountryInfoWithExcel = data => {
  return axios({
    url: api + '/gleagle/company/searchCountryInfoWithExcel',
    method: 'POST',
    data,
    responseType: 'arraybuffer'
    // responseType: 'blob'
    // url:post(, params)
  })
}
//国家相关信息-图表
export const searchCountryInfoChart = params => {
  return axios.post(api + '/gleagle/company/searchCountryInfoChart', params)
}

//Ownership
//所有权
export const getOwnership = params => {
  return axios({
    url: api + '/gleagle/csiv4/getOwnership',
    method: 'GET',
    params
  })
}
// 股东
export const getInvestment = params => {
  return axios({
    url: api + '/gleagle/csiv4/getInvestment',
    method: 'GET',
    params
  })
}
// 关联公司
export const getCompanies = params => {
  return axios({
    url: api + '/gleagle/csiv4/getRelatedCompanies',
    method: 'GET',
    params
  })
}
// 运营内容
export const getBusinessOperations = params => {
  return axios({
    url: api + '/gleagle/csiv4/getBusinessOperations',
    method: 'GET',
    params
  })
}
// 行业内容
export const getIndustry = params => {
  return axios({
    url: api + '/gleagle/csiv4/getIndustry',
    method: 'GET',
    params
  })
}
//公司业绩和公司信用评估
export const getPerformance = params => {

  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getPerformance',
    method: 'GET',
    params
  })
}
// 关联公司财务数据
export const getrelatedCompanylist= params => {
  return axios({
    url: api + '/gleagle/csiv4/relatedCompany/list',
    method: 'GET',
    params
  })
}
// 财务数据
export const getFinancials = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/csiv4/getFinancials',
    method: 'GET',
    params
  })
}
// 股东穿透echartlist
export const getCompanyChart = params => {
  return axios({
    url: api + '/gleagle/csiv4/getCompanyChart',
    method: 'GET',
    params
  })
}
// 股东穿透echartlist2
export const getCompanyCharttwo = params => {
  return axios({
    url: api + '/gleagle/csiv4/getCompanyChartV2',
    method: 'GET',
    params
  })
}
//修改核实状态
export const updateContractInfo = params => {
  return axios.get(api + '/gleagle/csiv4/updateEsContract?' + params)
}
// 添加足迹

export const updateSysComLogAndDe = params => {
  return axios.post(api + '/gleagle/csiv4/updateSysComLogAndDe',params)
}
// 贸易导出
export const exportTradeDetail = params =>{
  return axios.get(api + '/gleagle/trade/tradeInformation/excel?' + params,{  isLoading: true})

}
