// 检索功能模块

import axios from './request'
//定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./
const phone =mobil.test(currentUrl) 
const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.91.97:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.219.235:8088/test-api' //测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://3.101.104.100:8088/prod-api' 
let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
if(phone){
  api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
}
// URLSearchParams 把对象转为？拼接传参数
//公司检索条件
export const searchAdvanced = params => {
  return axios.get(api + '/gleagle/search/advanced/options', params)
}

//公司检索
export const searchCompany = params => {
  return axios({
    url: api + '/gleagle/search',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
// 产品检索
export const searchproduct = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/search/searchProduct',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//新闻检索
export const searchNews= params => {
  return axios({
    url: api + '/gleagle/csiv4/news/search',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//风险合规列表
export const searchriskAndComp= params => {
  return axios({
    url: api + '/gleagle/csiv4/riskAndComp/search',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
// 风险合规详情
export const searchDetailsById= params => {
  return axios({
    url: api + '/gleagle/csiv4/riskAndComp/searchDetailsById',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//新闻检索条件
export const searchNewsConditions= params => {
  return axios({
    url: api + '/gleagle/csiv4/news/filterConditions',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
//新闻检索条件
export const getPdfLink= params => {
  return axios({
    url: api + '/gleagle/csiv4/news/getPdfLink',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
  // return axios.get(api + '/gleagle/search' + params)
}
// export const searchop = params => {
//   return axios.get(api + '/gleagle/search/op?' + params)
// }
export const searchop = params => {
  return axios({
    url: api + '/gleagle/search/op',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const hksearchop = params => {
  return axios({
    url: api + '/gleagle/search/hk',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 产品检索
// export const searchProdeuct = params => {
//   return axios.get(api + '/gleagle/search' + params)
// }
export const searchProdeuct = params => {
  return axios({
    url: api + '/gleagle/search',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const getProductThirdCodeList = params => {
  return axios({
    url: api + '/gleagle/product/getProductThirdCodeList',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const getProductAggregationResult = params => {
  return axios({
    url: api + '/gleagle/product/getProductAggregationResult',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export const selectProductTypeCode = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/product/selectProductTypeCode',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    
    },
 

  })
}
// 查询供应商
export const opDeail = params => {
  return axios.post(api + '/gleagle/search/opDeail' , params)
}
// 查询供应商
export const hkDeail = params => {
  return axios.post(api + '/gleagle/search/hkDeail' , params)
}
//商品分类
export const getProductTreeList = params => {
  return axios.get(api + '/gleagle/product/getTreeList?' + params)
}


// 获取消息数量
export const getofflineDatacount = params => {
  return axios({
    isLoading: true,
    url: api + '/gleagle/orders/offlineData/count',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': '',
      'istoken':false
    }
  })

}

//收藏组列表 
export const selectGroup = params => {
  return axios.post(api + '/gleagle/collection/selectGroup' , params)
}
//添加收藏组
export const addGroup = params => {
  return axios.post(api + '/gleagle/collection/addGroup' , params)
}
//添加收藏
export const collectionSave = params => {
  return axios.post(api + '/gleagle/collection/collectionSave' , params)
}
export const delCollection = params => {
  return axios.post(api + '/gleagle/collection/delMoreCollection' , params)
}
//行业检索(Search Results)
export const searchTrade = params => {
  return axios.get(api + '/gleagle/trade/search?' + params,{  isLoading: true})
}

//行业检索条件
export const getFilterConditions = params => {
  return axios.get(api + '/gleagle/trade/filterConditions?' + params)
}
//产品聚合信息 product
export const getTradeAggre = params => {
  return axios.get(api + '/gleagle/trade/getTradeAggregationResult?' + params)
}
//人员检索
export const filterConditions = params => {
  return axios.get(api + '/gleagle/people/filterConditions?' + params)
}
//人员-检索
export const psersonSearch = params => {
  return axios.get(api + '/gleagle/people/search?'+ params)
}
// 贸易-详情
export const tradedetails = params => {
  // URLSearchParams 把对象转为？拼接传参数
  return axios.get(api + '/gleagle/trade/details?'+ new URLSearchParams(params).toString())
}
// 寻找伙伴-结果
export const findPartnerDetail = params => {
  return axios.post(api + '/gleagle/search/findPartnerDetail',params,{  isLoading: true})
}
// 寻找伙伴-新增
export const saveFindPartner = params => {
  return axios.post(api + '/gleagle/findPartner/saveFindPartner',params)
}
// 寻找伙伴-保存列表
export const getFindPartner = params => {
  return axios.get(api + '/gleagle/findPartner/getFindPartner?'+ new URLSearchParams(params).toString())
}
// 寻找伙伴-删除
export const deletePartnerById = params => {
  return axios.delete(api + '/gleagle/findPartner/deletePartnerById?'+ new URLSearchParams(params).toString())
}
// 寻找伙伴-更新
export const updateFindPartnerById = params => {
  return axios.put(api + '/gleagle/findPartner/updateFindPartnerById' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"} })
}
// 精准推送
export const precisePush = params => {
  return axios.put(api + '/gleagle/creditService/precisePush' , params,{ headers: { 'Content-Type': "application/json",istrue: "genxin"} })
}
// 
export const delGroup = params => {
  return axios.post(api + '/gleagle/collection/delGroup',params)
}
//制裁详情
export const companyProfile = params => {
  return axios.post(api + '/gleagle/csiv4/riskAndComp/companyProfile',params)
}
// export const tradedetails = params => {
//   return axios({
//     isLoading: true,
//     url: api + '/gleagle/trade/details',
//     method: 'GET',
//     params,
  
//   })

// }