
import { createWebSocketConnection } from '@/api/userApi';

const state = {
    socket: null,
    websocketConnected: false, // WebSocket连接状态
    messages: [
        // 
    ],
};

const getters = {
    isWebSocketConnected: state => state.websocketConnected,
    issocket: state => state.socket,
};

const actions = {
    connectWebSocket({ commit, state }, usercode) {
        return new Promise((resolve, reject) => {
            createWebSocketConnection(usercode)
                .then(socket => {

                    socket.onopen = () => {
                        commit('SET_WEBSOCKET_CONNECTED', true);
                        commit('setSocket', socket);
                        resolve();
                        //   let messagess=[{
                        //     title: 'date up',
                        //     content: 'Security Alert for Apple Users: Urgent Call for Serious Vulnerability in Devices',
                        //     aaaId: 121212312,
                        // }]
                        // commit('setmessage', messagess);
                    };
                    socket.onmessage = event => {
                        const receivedMessage = event.data;
                        console.log('收到消息:', JSON.parse(receivedMessage));
                        const message = {
                            userCode: usercode,
                            isRead: true,
                            contentType: 4,
                            // content: JSON.parse(receivedMessage).title,
                        };

                        // setTimeout(() => {
                        socket.send(JSON.stringify(message));
                        const messages = state.messages.slice(); // 复制一份websocketMessages数组
                        messages.push(JSON.parse(receivedMessage)); // 将接收到的消息存储在复制的数组中
                        commit('setmessage', messages); // 提交mutation更新websocketMessages数组
                    };
                    socket.onclose = () => {
                        // console.log('WebSocket连接已关闭');
                        socket.close()
                        commit('setSocket',null)
                        commit('setmessage',[])
                        // 连接关闭后的处理逻辑
                      };
                })
                .catch(error => {
                    console.error('WebSocket连接错误:', error);
                    reject(error);
                });
        });
    },
};



const mutations = {
    setSocket(state, socket) {
        state.socket = socket;
    },
    SET_WEBSOCKET_CONNECTED(state, connected) {
        state.websocketConnected = connected;
    },
    setmessage(state, messages) {
        state.messages = messages
    }
};
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
