<template>
    <div class="container">
        <!-- 电脑端 -->
        <div class="sna_box flex_between_box computer_right_cont_box">
            <div class="left_mess">
                <div class="left_mess_bottom">
                    <div class="mess_item flex_box">
                        <label class="lab">CompanyNumber:</label>
                        <div class="txt">{{ obj.RegID }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Address:</label>
                        <div v-show="!showTranslate" class="txt">{{ obj.Address }}</div>
                        <div v-show="showTranslate" class="txt">{{ obj.addressCn }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Location:</label>
                        <div v-show="!showTranslate" class="txt">{{ obj.City }}</div>
                        <div v-show="showTranslate" class="txt">{{ obj.cityCn }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Country:</label>
                        <div class="txt flex_align_center" style="cursor: pointer" @click="gotocountry1(obj)">
                            <img :src="obj.img" />
                            {{ obj.Country }}
                        </div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Website:</label>

                        <div class="txt">
                            <span style="text-decoration: underline; color: #1290c9; cursor: pointer" v-for="(item, index) in obj.Website.split(';')" :key="index" @click="setid(item)">
                                {{ item }}
                                <!-- <router-link :to="'http://'+obj.Website">{{ obj.Website }}</router-link> -->
                            </span>
                        </div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Email:</label>
                        <div class="txt">{{ obj.Email }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Last Update:</label>
                        <div class="txt">{{ obj.lastUpdate }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Data Source:</label>

                        <div class="txt" style="width: 400px">
                            <div style="text-decoration: underline; color: #1290c9; cursor: pointer; height: 25px" v-for="(item, index) in obj.dataSource" :key="index" @click="setid(item.website)">{{ item.register }}</div>
                        </div>
                    </div>
                </div>
                <div class="echarts">
                    <i class="ddd" @click="gotocreadmen()"></i>
                    <b v-if="DataQuality == 100" class="aaa1">{{ DataQuality == 0 ? 'n.a' : DataQuality }}</b>
                    <b v-else-if="DataQuality < 10" class="aaa2">{{ DataQuality == 0 ? 'n.a' : DataQuality }}</b>
                    <b v-else class="aaa">{{ DataQuality == 0 ? 'n.a' : DataQuality }}</b>
                    <div class="ecahrt">
                        <div id="dataQuality" ref="dataQuality" style="width: 300px; height: 134px"></div>
                        <el-tooltip class="casca_countone" effect="dark" placement="top-start">
                            <div class="credittext" slot="content">
                                Data Quality Index (DQI) utilizes algorithms to measure various aspects of data such as completeness, validity, reliability of sources, consistency, timeliness, and bilingual support. The index is designed to evaluate the quality of data on a scale from 1 to 100, where a higher score indicates better data quality.
                                <br />

                                Algorithms can analyze data based on predefined rules or criteria, allowing for objective and standardized measurements. Our DQI is a good indicator that enables users to have a quantitative assessment of data quality, facilitating data-driven decision-making and enhancing the overall value and utility of the data.
                            </div>
                            <i class="el-icon-warning" style="color: #1290c9; margin-left: 3px"></i>
                        </el-tooltip>
                    </div>
                    <b v-if="CreditScore == 100" class="bbb1">{{ CreditScore == 0 ? 'n.a' : CreditScore }}</b>
                    <b v-else-if="CreditScore < 10" class="bbb2">{{ CreditScore == 0 ? 'n.a' : CreditScore }}</b>
                    <b v-else class="bbb">{{ CreditScore == 0 ? 'n.a' : CreditScore }}</b>

                    <div class="ecahrt" style="margin-top: 20px">
                        <div ref="creditScore" style="width: 300px; height: 134px"></div>
                        <el-tooltip class="casca_countone" effect="dark" placement="top-start">
                            <div class="credittext" slot="content">
                                Credit evaluation is provided by 3ACredit. Driven by big data, 3ACredit developed a quantitative rating system "3ARating" providing credit evaluation for companies based on systematic analysis of the company's non-financial factors, financial performance, market conditions, and industry specification. 3ARating ranges from 3A1, the best rating, to 3A19, the worst rating class.
                                <br />
                                These credit evaluations also include Credit Score, Possibility of Default (PD), Solvency Index (SI) and Risk Indicator (RI). They serve as indicators of the company's ability to meet its financial obligations.
                                <br />
                                t's important to note that credit evaluations for companies are primarily used as a 3rd party opinion. It's for your reference only. In addition to company data analysis, many other factors are considered when evaluating companies. These may include the company's industry position, management team, overall business performance, geographic difference and macro business environment and so on.
                            </div>
                            <i class="el-icon-warning" style="color: #1290c9; margin-left: 3px"></i>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="right_coll">
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img1 flex_center">
                                <img src="../../assets/img/detail/shenfenzheng.png" />
                            </div>
                            <div class="des">
                                <div class="title">Legal Status</div>
                                <div class="txt">{{ obj.GoodStanding }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(1)" v-show="checkIn1">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(1)" v-show="!checkIn1">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn1 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Form:</label>
                                <div class="txt">{{ obj.Form }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Year:</label>
                                <div class="txt">{{ obj.Year }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Status:</label>
                                <div class="txt">{{ obj.Status }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Listed:</label>
                                <div class="txt">{{ obj.Listed }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img2 flex_center">
                                <img src="../../assets/img/detail/tuandui.png" />
                            </div>
                            <div class="des">
                                <div class="title">Current Operations</div>
                                <div class="txt">{{ obj.GCaution }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(2)" v-show="checkIn2">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(2)" v-show="!checkIn2">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn2 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <el-popover placement="top-start" popper-class="pop_sic" width="380" trigger="hover" :visible-arrow="false" :content="'ISIC Code Description:' + obj.SICCont">
                                <div class="item flex_between_box" slot="reference">
                                    <label class="lab">SIC:</label>
                                    <div class="txt">{{ obj.SIC }}</div>
                                </div>
                            </el-popover>
                            <div class="item flex_between_box">
                                <label class="lab">Activity:</label>
                                <div class="txt">{{ obj.Activity }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Products:</label>
                                <div class="txt">{{ obj.Products }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Business:</label>
                                <div class="txt">{{ obj.Business }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img3 flex_center">
                                <img src="../../assets/img/detail/data .png" />
                            </div>
                            <div class="des">
                                <div class="title">Performance</div>
                                <div class="txt">{{ obj.Average }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(3)" v-show="checkIn3">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(3)" v-show="!checkIn3">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn3 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Revenue:</label>
                                <div class="txt">{{ obj.Revenue }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Assets:</label>
                                <div class="txt">{{ obj.Assets }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Profit/Loss:</label>
                                <div class="txt">{{ obj.ProfitLoss }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Employee:</label>
                                <div class="txt">{{ obj.Employee }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img4 flex_center">
                                <img src="../../assets/img/detail/gonggao.png" />
                            </div>
                            <div class="des">
                                <div class="title">Publication</div>
                                <div class="txt">{{ obj.clear }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(4)" v-show="checkIn4">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(4)" v-show="!checkIn4">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn4 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Bankruptcy:</label>
                                <div class="txt">{{ obj.Bankruptcy }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Litigations:</label>
                                <div class="txt">{{ obj.Litigations }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Sanctions:</label>
                                <div class="txt">{{ obj.Sanctions }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Lien:</label>
                                <div class="txt">{{ obj.Lien }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 手机端 -->
        <div v-if="window.innerWidth < 821" class="sna_box flex_between_box right_cont_box phone_page_bg">
            <div class="left_mess">
                <div class="left_mess_bottom">
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_addresss2.png" alt="" /></label>
                        <div v-show="!showTranslate" class="txt">{{ obj.Address }}</div>
                        <div v-show="showTranslate" class="txt">{{ obj.addressCn }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_city.png" alt="" /></label>
                        <div v-show="!showTranslate" class="txt">{{ obj.City }}</div>
                        <div v-show="showTranslate" class="txt">{{ obj.cityCn }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_guojia.png" alt="" /></label>
                        <div class="txt flex_align_center" style="cursor: pointer">
                            <img :src="obj.img" />
                            {{ obj.Country }}
                        </div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_userico20.png" alt="" /></label>

                        <div class="txt">
                            <span style="text-decoration: underline; color: #1290c9; cursor: pointer" v-for="(item, index) in obj.Website.split(';')" :key="index" @click="setid(item)">
                                {{ item }}
                                <!-- <router-link :to="'http://'+obj.Website">{{ obj.Website }}</router-link> -->
                            </span>
                        </div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_email.png" alt="" /></label>
                        <div class="txt">{{ obj.Email }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_update.png" alt="" /></label>
                        <div class="txt">{{ obj.lastUpdate }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab"><img src="../../assets/img/phone_VueFlyEnumSelector.png" alt="" /></label>

                        <div class="txt" style="width: 400px">
                            <div style="text-decoration: underline; color: #1290c9; cursor: pointer; min-height: 25px" v-for="(item, index) in obj.dataSource" :key="index" @click="setid(item.website)">{{ item.register }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="echarts">
                <div class="echaet_item echaet_item_bg1">
                    <i class="ddd" @click="gotocreadmen()"></i>
                    <b v-if="DataQuality == 100" class="aaa1">{{ DataQuality == 0 ? 'n.a' : DataQuality }}%</b>
                    <b v-else-if="DataQuality < 10" class="aaa2">{{ DataQuality == 0 ? 'n.a' : DataQuality }}%</b>
                    <b v-else class="aaa">{{ DataQuality == 0 ? 'n.a' : DataQuality }}%</b>
                    <span style="margin-left: 5px; color: #d87b36">Data Quality</span>
                    <div class="ecahrt">
                        <div id="dataQuality" ref="dataQuality" style="width: 91px; height: 100px"></div>
                        <!-- <el-tooltip class="casca_countone" effect="dark" placement="top-start">
                            <div class="credittext" slot="content">
                                Data Quality Index (DQI) utilizes algorithms to measure various aspects of data such as completeness, validity, reliability of sources, consistency, timeliness, and bilingual support. The index is designed to evaluate the quality of data on a scale from 1 to 100, where a higher score indicates better data quality.
                                <br />

                                Algorithms can analyze data based on predefined rules or criteria, allowing for objective and standardized measurements. Our DQI is a good indicator that enables users to have a quantitative assessment of data quality, facilitating data-driven decision-making and enhancing the overall value and utility of the data.
                            </div>
                            <i class="el-icon-warning" style="color: #1290c9; margin-left: 3px"></i>
                        </el-tooltip> -->
                    </div>
                </div>
                <div class="echaet_item echaet_item_bg2">
                    <b v-if="CreditScore == 100" class="bbb1">{{ CreditScore == 0 ? 'n.a' : CreditScore }}%</b>
                    <b v-else-if="CreditScore < 10" class="bbb2">{{ CreditScore == 0 ? 'n.a' : CreditScore }}%</b>
                    <b v-else class="bbb">{{ CreditScore == 0 ? 'n.a' : CreditScore }}%</b>
                    <span style="  margin-left: 5px;color #74bef0">Credit Score</span>
                    <div class="ecahrt">
                        <div ref="creditScore" style="width: 90px; height: 100px"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="window.innerWidth < 821" class="sna_box right_cont_box phone_page_bg">
            <h4 style="margin-bottom: 10px; font-family: 'Arial Bold'">Snapshot</h4>
            <div class="right_coll">
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img1 flex_center">
                                <img src="../../assets/img/detail/shenfenzheng.png" />
                            </div>
                            <div class="des">
                                <div class="title">Legal Status</div>
                                <div class="txt">{{ obj.GoodStanding }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(1)" v-show="checkIn1">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(1)" v-show="!checkIn1">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn1 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Form:</label>
                                <div class="txt">{{ obj.Form }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Year:</label>
                                <div class="txt">{{ obj.Year }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Status:</label>
                                <div class="txt">{{ obj.Status }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Listed:</label>
                                <div class="txt">{{ obj.Listed }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img2 flex_center">
                                <img src="../../assets/img/detail/tuandui.png" />
                            </div>
                            <div class="des">
                                <div class="title">Current Operations</div>
                                <div class="txt">{{ obj.GCaution }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(2)" v-show="checkIn2">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(2)" v-show="!checkIn2">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn2 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <el-popover placement="top-start" popper-class="pop_sic" width="380" trigger="hover" :visible-arrow="false" :content="'ISIC Code Description:' + obj.SICCont">
                                <div class="item flex_between_box" slot="reference" style="flex-wrap: wrap">
                                    <label class="lab">SIC:</label>
                                    <div class="txt">{{ obj.SIC }}</div>
                                </div>
                            </el-popover>
                            <div class="item flex_between_box" style="flex-wrap: wrap">
                                <label class="lab">Activity:</label>
                                <div class="txt">{{ obj.Activity }}</div>
                            </div>
                            <div class="item flex_between_box" style="flex-wrap: wrap">
                                <label class="lab">Products:</label>
                                <div class="txt">{{ obj.Products }}</div>
                            </div>
                            <div class="item flex_between_box" style="flex-wrap: wrap">
                                <label class="lab">Business:</label>
                                <div class="txt">{{ obj.Business }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img3 flex_center">
                                <img src="../../assets/img/detail/data .png" />
                            </div>
                            <div class="des">
                                <div class="title">Performance</div>
                                <div class="txt">{{ obj.Average }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(3)" v-show="checkIn3">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(3)" v-show="!checkIn3">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn3 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Revenue:</label>
                                <div class="txt">{{ obj.Revenue }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Assets:</label>
                                <div class="txt">{{ obj.Assets }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Profit/Loss:</label>
                                <div class="txt">{{ obj.ProfitLoss }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Employee:</label>
                                <div class="txt">{{ obj.Employee }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img4 flex_center">
                                <img src="../../assets/img/detail/gonggao.png" />
                            </div>
                            <div class="des">
                                <div class="title">Publication</div>
                                <div class="txt">{{ obj.clear }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" @click="hanldLegalStatus(4)" v-show="checkIn4">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" @click="hanldLegalStatus(4)" v-show="!checkIn4">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                    <div class="coll_cont" :class="checkIn4 ? '' : 'on'">
                        <div class="coll_cont_box">
                            <div class="item flex_between_box">
                                <label class="lab">Bankruptcy:</label>
                                <div class="txt">{{ obj.Bankruptcy }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Litigations:</label>
                                <div class="txt">{{ obj.Litigations }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Sanctions:</label>
                                <div class="txt">{{ obj.Sanctions }}</div>
                            </div>
                            <div class="item flex_between_box">
                                <label class="lab">Lien:</label>
                                <div class="txt">{{ obj.Lien }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont_text">
                        • Financial performance is a paid feature, this allows you to view all the available data including financial details and credit assessment on the company. The cost of this update is 10 units per company.
                        <br />
                        Subject company's financial performance data is often collected from various public data sources as well as local credit reporting agencies. This data is also calculated, modeled and forecasted based on market and industry research and a variety of available data. This data is for your reference only.
                        <br />
                        If the available online data is incomplete or out of date, an update for a fresh investigated business credit report will be automatically ordered via our local credit reporting agencies. It normally takes about 72 hours for an update report delivery. A business credit report often contains details about the subject company's actual operations, financial performance, credit analysis and assessment.
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export computer_right_cont_box" @click="onSubmitquxiao()">Buy credits</el-button>
                <el-button class="btn_export phone_page_bg" @click="newDialog = false">Not Now</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getCompanySnap } from '@/api/companyApi.js';
import { mixins1 } from '@/mixins/index';
import crypto from '../../utils/crypto';
import bus from '../../components/bus';
import { mapActions } from 'vuex';

export default {
    mixins: [mixins1],

    data() {
        return {
            obj: {
                RegID: '',
                Address: '',
                addressCn: '',
                cityCn: '',
                City: '',
                Country: '',
                img: '',
                Website: '',
                Email: '',
                GoodStanding: '',
                Form: '',
                Year: '',
                Status: '',
                Listed: '',
                GCaution: '',
                SIC: '',
                SICCont: '',
                Activity: '',
                Products: '',
                Average: '',
                Business: '',
                Revenue: '',
                Assets: '',
                ProfitLoss: '',
                Employee: '.',
                clear: '',
                Bankruptcy: '',
                Litigations: '',
                Sanctions: '',
                Lien: '',
                lastUpdate: '',
                dataSource: [],
            },
            window: window,
            checkIn1: true,
            checkIn2: true,
            checkIn3: true,
            checkIn4: true,
            DataQuality: 0,
            CreditScore: 0,
            newDialog: false,
            usercode: JSON.parse(localStorage.getItem('userInfo'))?.userInfo.userCode,
            ceaddittext: "'Credit evaluation is provided by 3ACredit. Driven by big data, 3ACredit developed a quantitative rating system '3ARating' providing credit evaluation for companies based on systematic analysis of the company's non-financial factors, financial performance, market conditions, and industry specification. 3ARating ranges from 3A1, the best rating, to 3A19, the worst rating class.'",
            // showTranslate: false, //翻译
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    mounted() {
        this.getContentData();
        window.addEventListener('setItem', () => {
            this.token = sessionStorage.getItem('token');
        });
    },
    created() {},
    methods: {
        ...mapActions({
            connectWebSocket: 'websocket/connectWebSocket',
        }),
        gotocountry1(row) {
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
        },
        async gotocreadmen() {
            // this.$parent.getMenuList();

            // 跳转权限需不需要扣点
            let power = this.$parent.menuTitleList[5].result[4].power;
            // 判断有没有performance
            let status = this.$parent.menuTitleList[5].result[4].status;
            if (!status && this.CreditScore != 'n.a.') {
                if (power) {
                    this.$emit('updateSubLink', 5);
                    this.$router.push({
                        path: '/company/creditassessment?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
                    });
                } else {
                    this.newDialog = true;
                }
            }
        },
        closeDialog() {
            this.newDialog = false;
        },
        onSubmitquxiao() {
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        setid(row) {
            if (row.includes('http')) {
                let routeData = row;
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row;
                window.open(routeData, '_blank');
            }
        },
        onSubmitkoudian() {
            this.newDialog = false;
            this.$parent.getOrdersAdd('2').then(res => {
                if (res) {
                    //处理线下报告扣完点加锁的状态
                    if (!this.$parent.menuTitleList[3].power) this.$parent.getOrdersAdd('1');
                    this.$parent.getMenuList();
                    this.$emit('updateSubLink', 5);

                    this.$router.push({
                        path: '/company/creditassessment?id3a=' + this.$route.query.id3a + '&companyCountry=' + this.$route.query.companyCountry,
                    });
                }
            });
        },

        async getContentData() {
            this.$parent.getMenuList();
            // const company = JSON.parse(sessionStorage.getItem('company'));
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            // await getCountryCollection().then(res => {
            getCompanySnap('id3a=' + id3a + '&companyCountry=' + companyCountry).then(
                res => {
                    console.log(`res`, res);
                    //解密
                    this.connectWebSocket(this.usercode);
                    let result = JSON.parse(crypto.decrypt(res));
                    console.log(`result`,result );
                    if (result && result.code) {
                     
                        sessionStorage.setItem('base', JSON.stringify(result.data));
                        localStorage.setItem('base', JSON.stringify(result.data));
                        let data = result.data;
                           console.log(data);
                        //获取本地图片路径
                        let countryImg = this.getCountryImgUrl(data.base.countryFlag);

                        let companyBase = {
                            aaaId: data.base.aaaId,
                            name: data.base.name,
                            nameEn: data.base.nameEn,
                            companyCountry: data.base.countryFlag,
                            lastUpdate: data.base.lastUpdate,
                            // lableActive: data.lables ? data.lables.lableActive : '',
                            // lableCode: data.lables.lableCode,
                            // lableDate: data.lables.lableDate,
                            tags: data.lables ? data.lables : '',
                        };
                        localStorage.setItem('companyBase1', JSON.stringify(companyBase));
                        sessionStorage.setItem('companyBase1', JSON.stringify(companyBase));
                        //子路由里的事件绑定
                        bus.$emit('toRightHeadObj', companyBase);

                        this.obj = {
                            RegID: data.base.regId,
                            Address: data.base.address,
                            addressCn: data.base.addressCn,
                            countryCode: data.base.countryFlag,
                            cityCn: data.base.cityCn,
                            City: data.base.city,
                            Country: data.base.country,
                            Website: data.base.website,
                            img: require('../../../static' + countryImg),
                            Email: data.base.email,
                            GoodStanding: data.legalStatus.legalStatus,
                            Form: data.legalStatus.form,
                            Year: data.legalStatus.year,
                            Status: data.legalStatus.status,
                            Listed: data.legalStatus.listed,
                            GCaution: data.current.currentOper,
                            SIC: data.current.isicCode,
                            SICCont: data.current.isicDes,
                            dataSource: data.base.dataSource,
                            Activity: data.current.activity,
                            Products: data.current.products,
                            Business: data.current.business,
                            Average: data.performance.performance,
                            Revenue: data.performance.revenue,
                            Assets: data.performance.assets,
                            ProfitLoss: data.performance.profitability,
                            clear: data.negative.neRecords,
                            Employee: data.performance.employee,
                            Bankruptcy: data.negative.bankruptcy,
                            Litigations: data.negative.litigations,
                            Sanctions: data.negative.sanctions,
                            Lien: data.negative.lien,
                            lastUpdate: data.base.lastUpdate,
                        };
                        this.DataQuality = data.base.dataQuality != 'n.a.' ? parseInt(data.base.dataQuality) : 'n.a.';
                        this.CreditScore = data.base.creditScore != 'n.a.' ? parseInt(data.base.creditScore) : 'n.a.';
                        this.init();
                        let params = {
                            companyName: data.base.nameEn,
                            aaaId: this.$route.query.id3a,
                        };
                        // this.getliulantime(params,'CompanySnapshot')
                        // });
                    } else {
                        sessionStorage.setItem(
                            'base',
                            JSON.stringify({base:{
                                name: '',
                                nameEn: '',
                                aaaId: '',
                                companyCountry: '',
                                lastUpdate: '',
                                // lableActive: '',
                                // lableCode: '',
                                // lableDate: '',
                                tags: [],
                            }})
                        );
                        localStorage.setItem('base', JSON.stringify({base:{
                                name: '',
                                nameEn: '',
                                aaaId: '',
                                companyCountry: '',
                                lastUpdate: '',
                                // lableActive: '',
                                // lableCode: '',
                                // lableDate: '',
                                tags: [],
                            }}));
                    }
                },
                err => {
                    console.log('err', err.msg);
                }
            );
        },

        //展开 legal status
        hanldLegalStatus(index) {
            let powerObj = this.curPagePower;
            switch (index) {
                case 1:
                    if (powerObj[0].power) {
                        this.checkIn1 = !this.checkIn1;
                    } else {
                        this.$parent.getOrdersAdd(1).then(res => {
                            if (res) this.checkIn1 = !this.checkIn1;
                        });
                    }
                    break;
                case 2:
                    if (powerObj[1].power) {
                        this.checkIn2 = !this.checkIn2;
                    } else {
                        this.$parent.getOrdersAdd(1).then(res => {
                            if (res) this.checkIn2 = !this.checkIn2;
                        });
                    }
                    break;
                case 3:
                    if (powerObj[2].power) {
                        this.checkIn3 = !this.checkIn3;
                    } else {
                        this.$parent.getOrdersAdd(1).then(res => {
                            if (res) this.checkIn3 = !this.checkIn3;
                        });
                    }
                    break;
                case 4:
                    if (powerObj[3].power) {
                        this.checkIn4 = !this.checkIn4;
                    } else {
                        this.$parent.getOrdersAdd(1).then(res => {
                            if (res) this.checkIn4 = !this.checkIn4;
                        });
                    }
                    break;
            }
        },
        color(num) {},
        init() {
            if (this.$refs.dataQuality && this.$refs.creditScore) {
                this.$nextTick(() => {
                    // console.log(`this.DataQuality`, this.DataQuality);
                    let color = '';
                    if (0 < this.DataQuality && this.DataQuality <= 20) {
                        color = '#EE2424';
                    } else if (20 < this.DataQuality && this.DataQuality <= 40) {
                        color = '#F79024';
                    } else if (40 < this.DataQuality && this.DataQuality <= 60) {
                        color = '#F79024';
                    } else if (60 < this.DataQuality && this.DataQuality <= 80) {
                        color = '#87C547';
                    } else if (80 < this.DataQuality && this.DataQuality <= 100) {
                        color = '#01B152';
                    }
                    let color2 = '';
                    if (0 < this.CreditScore && this.CreditScore <= 20) {
                        color2 = '#EE2424';
                    } else if (20 < this.CreditScore && this.CreditScore <= 40) {
                        color2 = '#F79024';
                    } else if (40 < this.CreditScore && this.CreditScore <= 60) {
                        color2 = '#F79024';
                    } else if (60 < this.CreditScore && this.CreditScore <= 80) {
                        color2 = '#87C547';
                    } else if (80 < this.CreditScore && this.CreditScore <= 100) {
                        color2 = '#01B152';
                    }
                    if (this.window.innerWidth < 821) {
                        color = '#d87b36';
                        color2 = '#74bef0';
                        this.initPic('', this.$refs.dataQuality, this.DataQuality, color, '50%', '48%');
                        this.initPic('', this.$refs.creditScore, this.CreditScore, color2, '50%', '48%');
                    } else {
                        this.initPic('Data Quality', this.$refs.dataQuality, this.DataQuality, color, '40%', '38%');
                        this.initPic('Credit Score', this.$refs.creditScore, this.CreditScore, color2, '40%', '38%');
                    }
                });
            }
        },
        initPic(text, obj, num, color, left, x) {
            const myChart = this.$echarts.init(obj);
            let data = num;

            let option = {
                title: {
                    subtext: text,
                    //  text: num=='n.a.'?'n.a.':'',
                    // left: left,
                    top: num == 'n.a.' ? '68%' : '75%',
                    left: '20%',
                    // x: 'center',
                    //  y: 'center',
                    textStyle: {
                        fontWeight: 'normal',
                        fontSize: 16,
                        color: '#8497AB',
                        // align: 'center',
                        // left: 'center',
                    },
                    subtextStyle: {
                        fontSize: 16,
                        color: '#022955',
                        // fontWeight: '300',
                    },
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{d}%',
                    show: true,
                },
                legend: {
                    top: 20,
                    right: 20,
                },

                series: [
                    {
                        type: 'pie',
                        // 圆环大小粗细
                        radius: ['50%', '67%'],
                        center: [x, left],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,

                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [
                            {
                                value: data,
                                itemStyle: {
                                    // color: ,
                                    color: color,
                                    // colorStops: data.ringColor
                                },
                                // name: 'Data Quality',
                            },
                            {
                                value: 100 - data,
                                itemStyle: {
                                    color: '#E9EEF4',
                                },
                                // name: 'Credit Score',
                            },
                        ],
                    },
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: [x, left],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,

                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },

                        data: [
                            {
                                value: data,
                                itemStyle: {
                                    color: color,
                                },
                                // name: 'Data Quality',
                            },
                            {
                                value: 100 - data,

                                itemStyle: {
                                    color: 'transparent',
                                },
                                // name: 'Credit Score',
                            },
                        ],
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.flex_align_center {
    display: flex;
    align-items: center;
}
.sna_box {
    margin-top: 60px;
}
.right_coll {
    width: 440px;
}
.coll_item {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
    margin-bottom: 20px;
}
.coll_top {
    padding: 12px 30px;
}
.coll_top .img {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #e6eeff;
    border-radius: 50%;
    text-align: center;
    margin-right: 20px;
}
.coll_top .img2 {
    background: #eee8ff;
}
.coll_top .img3 {
    background: #ffe6f8;
}
.coll_top .img4 {
    background: #fff3e8;
}
.coll_top .img img {
    width: 28px;
}
.coll_top .des .title {
    font-size: 16px;
    color: #8497ab;
    line-height: 18px;
    margin-bottom: 8px;
}
.coll_top .des .txt {
    width: 280px;
    font-size: 14px;
    color: #022955;
    line-height: 18px;
}
.btn_show div {
    display: block;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    line-height: 24px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    text-align: center;
    cursor: pointer;
}
.btn_show img {
    width: 8px;
}
.check_out {
    display: none;
}

.coll_cont {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;
}
.coll_cont_box {
    position: relative;
    padding-top: 20px;
    margin: 0 30px;
    border-top: 1px solid #e7eaed;
}
.coll_cont.on {
    max-height: 666px;
}
.item {
    font-size: 14px;
    margin-bottom: 14px;
}
.item .lab {
    color: #8497ab;
}
.coll_cont .item {
    position: relative;
}

.coll_cont .item .txt {
    width: 280px;
    text-align: right;
}
.left_mess {
    width: 540px;
    display: flex;
}
.left_mess_bottom {
    margin-top: 20px;
}
.mess_item {
    font-size: 14px;
    margin-bottom: 20px;
}
.mess_item .lab {
    width: 108px;
    color: #8497ab;
}
.mess_item .txt {
    width: 350px;
    color: #022955;
    margin-left: 20px;
}
.mess_item .txt img {
    width: 52px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    margin-right: 5px;
}
.mess_item .txt a {
    color: #1290c9;
    text-decoration: underline;
}

.pop_sic.el-popover--plain {
    padding: 10px !important;
    font-size: 14px;
    color: #022955;
}
.flex_center_between_box {
    display: flex !important;
    /* align-items: center; */

    justify-content: space-between !important;
}
.dialog_normalsuo .flex_center_between_box {
    padding: 0 20px;
}
.echarts {
    position: relative;
}
.aaa {
    position: absolute;
    left: 105px;
    top: 46px;
    font-size: 18px;
}
.aaa2 {
    position: absolute;
    left: 109px;
    top: 46px;
    font-size: 18px;
}
.aaa1 {
    position: absolute;
    left: 99px;
    top: 46px;
    font-size: 18px;
}
.bbb {
    position: absolute;
    left: 105px;
    top: 200px;
    font-size: 18px;
}
.bbb2 {
    position: absolute;
    left: 109px;
    top: 200px;
    font-size: 18px;
}
.bbb1 {
    position: absolute;
    left: 99px;
    top: 200px;
    font-size: 18px;
}
.ddd {
    position: absolute;
    left: 39px;
    top: 140px;
    height: 150px;
    width: 150px;
    z-index: 20;
    cursor: pointer;
}
.detail_cont {
    padding: 0px 20px 20px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
.casca_countone {
    position: absolute;
    left: 160px;
    bottom: 10px;
    z-index: 29;
}
.ecahrt {
    position: relative;
}
.credittext {
    width: 400px;
}
@media (max-width: 821px) {
    .left_mess {
        width: 100% !important;
    }
    .left_mess_bottom {
        width: 100%;
    }
    .mess_item {
        margin-bottom: 12px !important;
    }
    .mess_item .txt {
        width: 95% !important;
        margin-left: 5px !important;
        line-height: 20px;
        border-bottom: 1px solid #ccc;
        font-size: 12px !important;
    }
    .mess_item .txt img {
        width: 25px !important;
    }
    .mess_item .lab {
        width: 8% !important;
    }
    .mess_item .lab img {
        height: 20px;
        width: 20px;
    }
    .sna_box {
        margin-top: 20px !important;
    }
    .coll_top .des .title {
        font-size: 12px !important;
        color: #022955 !important;
    }
    .coll_top .des .txt {
        width: auto !important;
        font-size: 12px !important;
        color: #8497ab !important;
    }
    .item .lab{
     color: #022955 !important;
    }
    .right_coll {
        width: 100% !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .coll_item {
        width: 98% !important;
        border-radius: 8px !important;
    }
    .coll_top .img {
        width: 22px !important;
        height: 22px !important;
        margin-right: 15px !important;
    }
    .coll_top .img img {
        width: 20px !important;
    }
    .coll_top {
        padding: 6px !important;
        height: 65px;
    }
    .flex_box {
        align-items: center;
    }
    .echarts {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bbb,
    .bbb1,
    .bbb2 {
        left: 65%;
        top: 40%;
        font-size: 12px !important;
    }
    .echaet_item {
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        margin: 15px 0px 10px;
        height: 80px;
        font-size: 12px !important;
        position: relative;
        border-radius: 8px !important;
    }
    .echaet_item_bg1 {
        background: #fef3e8;
    }
    .echaet_item_bg2 {
        background: #e9f3ff;
    }
    .aaa,
    .aaa1,
    .aaa2 {
        left: 65%;
        top: 40%;
        font-size: 12px !important;
    }
    .ddd {
        left: 60% !important;
        top: 23% !important;
        height: 50px !important;
        width: 50px !important;
    }
    .coll_cont .item .txt {
        width: auto !important;
    }
    .coll_cont_box {
        margin: 0 10px !important;
    }
    .coll_cont .item {
        font-size: 12px !important;
    }
}
</style>
