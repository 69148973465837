
<template>
    <div class="container">
        <div class="title">
            <span class="title-left">
                <img src="../../../assets/img/company/permaicon.png" alt="" style="margin-right: 8px" />
                Performance
            </span>
        </div>
        <div class="footer" v-loading="loading" v-show="profitablitylist.length > 0">
            <div class="ecahrts-header">
                <span class="title-left">
                    <img src="../../../assets/img/company/performac.png" alt="" style="margin-right: 8px" />
                    Credit Assessment
                </span>

                <div class="head_loging">
                    <el-switch active-color="#13ce66" v-model="loginStatus"></el-switch>
                </div>
            </div>
            <div v-show="loginStatus" class="ecahrtstu">
                <div class="table" v-loading="loading">
                    <div class="table_box">
                        <span class="aaa">High Risk</span>
                        <span class="bbb">Medium</span>
                        <span class="ccc">Moderate</span>
                        <span class="ddd">Average</span>
                        <span class="fff">Lower Than Avg.</span>
                        <span class="ggg">Low Risk</span>
                        <div class="echarts" ref="riskRef"></div>
                        <el-card class="box-card">
                            <div v-for="(item, index) in cardlist" :key="index" class="text item ">
                                <div :style="[bc, { backgroundColor: item.class }]" class="computer_right_cont_box">
                                    <img style="height: 20px; width: 20px" :src="item.icon" alt="" />
                                </div>

                                <div class="card-text">
                                    <span class="text-head">{{ item.title }}</span>
                                    <h2 class="text-foot">{{ item.text }}</h2>
                                </div>
                            </div>
                        </el-card>
                    </div>

                    <tableVue :yearslist="yearslist1" :profitablitylist="profitablitylist" :untia="untia"></tableVue>
                </div>
            </div>
        </div>

        <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="title" style="margin-top: 20px">
                <span class="title-left">
                    <img src="../../../assets/img/company/performac.png" style="margin-right: 8px" />
                    Related Credit Assessment
                </span>
            </div>

            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                    <div class="ecahrts-header">
                        <span class="title-left">
                            <img src="../../../assets/img/company/performac.png" alt="" style="margin-right: 8px" />
                            <span v-show="!showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px;width: 580px;" class="line_1" @click="setid(item)">{{ item.nameEn }}</span>
                            <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px;width: 580px;" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>
                            Credit Assessment
                        </span>
                        <span style="display: flex;justify-content: flex-start; width: 280px;">relations:{{ item.typeEn }}</span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                        </div>
                    </div>
                    <div v-show="loginStatus1[index]" class="ecahrtstu">
                        <div class="table" v-loading="loading">
                            <div class="table_box">
                                <span class="aaa">High Risk</span>
                                <span class="bbb">Medium</span>
                                <span class="ccc">Moderate</span>
                                <span class="ddd">Average</span>
                                <span class="fff">Lower Than Avg.</span>
                                <span class="ggg">Low Risk</span>
                                <div class="echarts" ref="chartRef"></div>
                                <el-card class="box-card">
                                    <div v-for="(item, index) in item.relatedcardlist" :key="index" class="text item">
                                        <div :style="[bc, { backgroundColor: item.class }]">
                                            <img style="height: 20px; width: 20px" :src="item.icon" alt="" />
                                        </div>

                                        <div class="card-text">
                                            <span class="text-head">{{ item.title }}</span>
                                            <h2 class="text-foot">{{ item.text }}</h2>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                            <tableVue :yearslist="item.yearslist" :profitablitylist="item.profitablitylist1" :ida="id3a" :untia="untia"></tableVue>
                        </div>
                        <!-- <tableVue :profitablitylist="item.profitablitylist1" :yearslist="yearslist" :untia="untia"></tableVue> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class="el-icon-top gaodu"></button>
        </div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text">
                            • Financial performance is a paid feature, this allows you to view all the available data including financial details and credit assessment on the company. The cost of this update is 10 units per company.<br> Subject company's financial performance data is often collected from various public data sources as well as local credit reporting agencies. This data is also calculated, modeled and forecasted based on market and industry research and a variety of available data. This data is for your reference only. <br>If the available online data is incomplete or out of date, an update for a fresh investigated business credit report will be automatically ordered via our local credit reporting agencies. It normally takes about 72 hours for an update report delivery. A business credit
                        report often contains details about the subject company's actual operations, financial performance, credit analysis and assessment.
                        </div>
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import crypto from '@/utils/crypto';
import { getFinancials, getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd } from '../../../api/companyApi';
import tableVue from './components/table.vue';
import { mixins1 } from '@/mixins/index';
export default {
    components: { tableVue },
    mixins: [mixins1],
    data() {
        return {
            untia: '',
            id3a: [],
            loading: true,
            creditOpinion: 'D',
            showBackToTop: false,
            creditScore: '0',
            yearslist: [],
            yearslist1: [],
            profitablitylist: [],

            cardlist: [
                {
                    icon: require('../../../assets/img/company/LIqui.png'),
                    title: 'Local Credit Score ',
                    text: '',
                    class: '#F56B6BFF',
                },
                {
                    icon: require('../../../assets/img/company/finan.png'),
                    title: 'Possibility Default (PD)',
                    text: '',
                    class: '#FF7600FF',
                },
                {
                    icon: require('../../../assets/img/company/crea.png'),
                    title: 'Solvency index (SI)',
                    text: '',
                    class: '#1290C9FF',
                },
                {
                    icon: require('../../../assets/img/company/risk.png'),
                    title: 'Credit Line',
                    text: '',
                    class: '#55B419FF',
                },
            ],
            relatedcardlist: [
                {
                    icon: require('../../../assets/img/company/LIqui.png'),
                    title: 'Local Credit Score ',
                    text: '',
                    class: '#F56B6BFF',
                },
                {
                    icon: require('../../../assets/img/company/finan.png'),
                    title: 'Possibility Default (PD)',
                    text: '',
                    class: '#FF7600FF',
                },
                {
                    icon: require('../../../assets/img/company/crea.png'),
                    title: 'Solvency index (SI)',
                    text: '',
                    class: '#1290C9FF',
                },
                {
                    icon: require('../../../assets/img/company/risk.png'),
                    title: 'Credit Line',
                    text: '',
                    class: '#55B419FF',
                },
            ],
            loginStatus: true, //登录状态
            window:window,
            pageChild: {
                pageNo: 1,
                pageSize: 5,
                total: 0,
            },
            bc: {
                height: window.innerWidth>821?'40px':'20px',
                width: window.innerWidth>821?'40px':'20px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },

            icon: '',
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            parentTablelist: [],
            profitablitylist1: [],
            loading1: false,
            loginStatus1: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
                 //提示弹框
            newDialog: false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
            onepower:""
        };
    },
    computed: {
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.curPagePower1);
            },
            set() {},
        },
    },
    watch: {},
    mounted() {
        // window.addEventListener('resize', this.riskEchart)
        this.getlist();
        this.riskEchart();
        this.getrelateList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;
                    this.getOrdersAdd(2, id, nameEn).then(res => {
                        if (res) {
                            if(!that.onepower){
                        that.getOrdersAdd(1, id, nameEn).then(res => {});
                    } 
                            let params = this.params;
                            let index = that.Obj.index;
                            that.getparamslist(params, index);
                        } else {
                            let index = that.Obj.index;
                    that.loginStatus1[index] = false;
                    that.parentTablelist[index].loading2 = false;
                    that.$set(this.parentTablelist, index, that.parentTablelist[index]);
                        }
                    });
            this.newDialog = false;
        },
        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        getlist() {
            const params = {
                // id3a: '156107587326',
                // companyCountry: 'CN',
                // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
                // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
            };
            getFinancials(params).then(res => {
                this.loading = false;
                let data = JSON.parse(crypto.decrypt(res));
                console.log(data);
                if (data.code) {
                    this.loading = false;
                    let { ca, creditBO, yearList, unit } = data.data;
                    this.untia = unit;
                    this.profitablitylist = ca;
                    console.log(  this.profitablitylist);
                    console.log(yearList);
                    this.yearslist1 = yearList;
                    //  console.log(creditBO);
                    this.cardlist.map((item, index) => {
                        if (item.title == 'Local Credit Score ') {
                            item.text = creditBO.creditScore;
                        }
                        if (item.title == 'Possibility Default (PD)') {
                            item.text = creditBO.pd;
                        }
                        if (item.title == 'Solvency index (SI)') {
                            item.text = creditBO.si;
                        }
                        if (item.title == 'Credit Line') {
                            item.text = creditBO.creditLine;
                        }
                    });
                    console.log(this.cardlist);
                    this.creditOpinion = creditBO.creditOpinion;
                    this.creditScore = creditBO.creditScore / 100;
                 
                } else {
                    this.loading = false;
                    this.creditOpinion = 'unrated';
                    this.creditScore = 0;
                }
                console.log(  this.creditOpinion);
                    console.log( this.creditScore);
                this.$nextTick(() => {
                    this.riskEchart();
                });
            });
        },
        onPageChangeList() {},
        tab(item) {},
        handleChange(val) {
            this.icon = val;
        },

        riskEchart() {
            const that = this;

            const option = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,

                        center: ['50%', '65%'],
                        radius: '90%',
                        // radius: '145%',
                        clockwise: true,
                        show: true,
                        min: 0,
                        max: 1,
                        splitNumber: 5,

                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 20,

                                color: [
                                    [
                                        0.25,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 1,
                                                    color: '#F56B6BFF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        0.5,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#FA6666FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        1,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#55B419FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],
                                ],
                            },
                        },

                        pointer: {
                            icon: 'circle',

                            length: '20%',
                            width: 30,
                            offsetCenter: [0, '-82%'],
                            itemStyle: {
                                color: 'auto',
                            },
                        },
                        axisTick: {
                            length: 5,
                            distance: -28,
                            lineStyle: {
                                color: 'auto',
                                width: 2,
                            },
                        },
                        // 刻度线
                        splitLine: {
                            show: false,
                            length: 10,
                            // distance:-20,
                            lineStyle: {
                                color: 'auto',
                                width: 8,
                            },
                        },
                        axisLabel: {
                            show: false,
                            rotate: 'tangential',
                            fontWeight: 'normal',
                            fontSize: 0,
                            distance: -62,
                            width: 70,
                            height: 40,
                            fontFamily: 'Arial',
                            overflow: 'truncate',
                            color: [
                                [
                                    0.25,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 10,

                                        x2: 30,

                                        y2: 11,

                                        colorStops: [
                                            {
                                                offset: 1,
                                                color: '#F56B6BFF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],

                                [
                                    0.5,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 0,

                                        x2: 0,

                                        y2: 1,

                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: '#FFDC17FF',
                                            },
                                            {
                                                offset: 1,
                                                color: '#FA6666FF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],

                                [
                                    1,
                                    {
                                        type: 'linear',

                                        x: 0,

                                        y: 0,

                                        x2: 0,

                                        y2: 1,

                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: '#FFDC17FF',
                                            },
                                            {
                                                offset: 1,
                                                color: '#55B419FF',
                                            },
                                        ],

                                        global: false,
                                    },
                                ],
                            ],

                            formatter: function (value) {
                                if (value === 1) {
                                    return 'Low Risk';
                                } else if (value === 0.8) {
                                    return 'Lower Than Avg.';
                                } else if (value === 0.6) {
                                    return 'Average';
                                } else if (value === 0.4) {
                                    return 'Moderate';
                                } else if (value === 0.2) {
                                    return 'Medium';
                                } else if (value === 0.0) {
                                    return 'High Risk';
                                }
                                return '';
                            },
                        },

                        title: {
                            offsetCenter: [0, '20%'],
                            fontSize: 15,
                            color: '#ccc',
                        },
                        // 仪表盘数字
                        detail: {
                            lineHeight: 126,
                            fontSize: 35,
                            offsetCenter: [1, '-22'],
                            valueAnimation: true,

                            borderColor: '#55B419FF',

                            width: 200,
                            formatter: function (value) {
                                var a = 'd';
                                if (Math.round(value * 100)) {
                                    a = 'A';
                                }
                                return that.creditOpinion;
                            },
                            color: '#55B419FF',
                        },
                        data: [
                            {
                                value: that.creditScore,
                                name: 'Credit Opinion',
                            },
                        ],
                    },
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '65%'],
                        radius: '59%',
                        min: 0,
                        max: 1,
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 1,
                                color: [
                                    [
                                        0.5,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#FA6666FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        1,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#55B419FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],
                                ],
                            },
                        },
                        pointer: {
                            icon: 'triangle',
                            length: '12%',
                            width: 20,
                            offsetCenter: [0, '-105%'],
                            itemStyle: {
                                color: '#55B419FF',
                            },
                        },

                        data: [
                            {
                                value: that.creditScore,
                                detail: {
                                    show: false,
                                },
                            },
                        ],
                    },
                ],
            };
            this.riskChart = this.$echarts.init(this.$refs.riskRef);
            this.riskChart.setOption(option);
        },

        // 关联公司
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);
            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;
                const params = {
                    id3a: id,
                };

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[5].list[2].power;
                this.onepower=res.data[5].list[0].power
                if (power) {
                    this.getparamslist(params, index);
                    //  获取数据
                } else {
                    const okButton = document.getElementById('ok');
                    this.newDialog = true;
                    this.params = params;
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
                    // this.getOrdersAdd(2, id, nameEn).then(res => {
                    //     if (res) {
                    //         this.getparamslist(params, index);
                    //     } else {
                    //         this.loginStatus1[index] = false;
                    //         this.parentTablelist[index].loading2 = false;
                    //         this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //     }
                    // });
                }
            }
            console.log(``, id);
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    //  getCompanyDetailMenuList('code=' + aid + '&type=' + 7).then(res=>{
                    //  console.log(res);
                    //  });
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            // 记录浏览时间
          this.getliulantime(params,'CreditAssessment')
            return status;
        },
        infiniteScroll() {
            console.log(`111`, 111);
            this.routeLoad = false;
            // this.page.pageNo += 1; // 页码每次滚动+1
            // this.getrelateList();
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 1 }).then(res => {
                if (res && res.code) {
                    let list = res.data.rows;
                    this.parentTablelist = res.data.rows;
                    this.routeLoad = false;
                    // this.parentTablelist = res.data.rows;

                    this.parentTablelist.map(item => {
                        item.profitablitylist1 = [];
                        item.loading2 = false;
                        item.yearslist =[]
                        item.chartInstance = null;
                        item.relatedcardlist = this.relatedcardlist;
                    });
                    console.log(`this.parentTablelist`, this.parentTablelist);
                }
            });
        },
        // 获取数据
        getparamslist(params, index) {
            getFinancials(params).then(res => {
                if (!res.code) {
                    this.parentTablelist[index].profitablitylist1 = [];
                    this.parentTablelist[index].loading2 = false;
                }

                this.parentTablelist[index].loading2 = false;
                let data = JSON.parse(crypto.decrypt(res));
                console.log(data);

                let { ca, creditBO, yearList, unit } = data.data;
                this.parentTablelist[index].yearslist = yearList;
                        this.id3a = yearList;
                this.parentTablelist[index].profitablitylist1 = ca;
                console.log(this.parentTablelist);
                this.untia = unit;
                let that = this;
                that.loginStatus1[index] = true;
                this.parentTablelist[index].relatedcardlist.map((item, index) => {
                    if (item.title == 'Local Credit Score ') {
                        item.text = creditBO.creditScore;
                    }
                    if (item.title == 'Possibility Default (PD)') {
                        item.text = creditBO.pd;
                    }
                    if (item.title == 'Solvency index (SI)') {
                        item.text = creditBO.si;
                    }
                    if (item.title == 'Credit Line') {
                        item.text = creditBO.creditLine;
                    }
                });

                this.creditOpinion = creditBO.creditOpinion;
                this.creditScore = creditBO.creditScore / 100;
                // const chartDom = this.$refs.chartRef[index];
                // const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
                const option = {
                    series: [
                        {
                            type: 'gauge',
                            startAngle: 180,
                            endAngle: 0,

                            center: ['50%', '65%'],
                            radius: '90%',
                            // radius: '145%',
                            clockwise: true,
                            show: true,
                            min: 0,
                            max: 1,
                            splitNumber: 5,

                            axisLine: {
                                roundCap: true,
                                lineStyle: {
                                    width: 20,

                                    color: [
                                        [
                                            0.25,
                                            {
                                                type: 'linear',

                                                x: 0,

                                                y: 0,

                                                x2: 0,

                                                y2: 1,

                                                colorStops: [
                                                    {
                                                        offset: 1,
                                                        color: '#F56B6BFF',
                                                    },
                                                ],

                                                global: false,
                                            },
                                        ],

                                        [
                                            0.5,
                                            {
                                                type: 'linear',

                                                x: 0,

                                                y: 0,

                                                x2: 0,

                                                y2: 1,

                                                colorStops: [
                                                    {
                                                        offset: 0,
                                                        color: '#FFDC17FF',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#FA6666FF',
                                                    },
                                                ],

                                                global: false,
                                            },
                                        ],

                                        [
                                            1,
                                            {
                                                type: 'linear',

                                                x: 0,

                                                y: 0,

                                                x2: 0,

                                                y2: 1,

                                                colorStops: [
                                                    {
                                                        offset: 0,
                                                        color: '#FFDC17FF',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#55B419FF',
                                                    },
                                                ],

                                                global: false,
                                            },
                                        ],
                                    ],
                                },
                            },

                            pointer: {
                                icon: 'circle',

                                length: '20%',
                                width: 30,
                                offsetCenter: [0, '-82%'],
                                itemStyle: {
                                    color: 'auto',
                                },
                            },
                            axisTick: {
                                length: 5,
                                distance: -28,
                                lineStyle: {
                                    color: 'auto',
                                    width: 2,
                                },
                            },
                            // 刻度线
                            splitLine: {
                                show: false,
                                length: 10,
                                // distance:-20,
                                lineStyle: {
                                    color: 'auto',
                                    width: 8,
                                },
                            },
                            axisLabel: {
                                show: false,
                                rotate: 'tangential',
                                fontWeight: 'normal',
                                fontSize: 0,
                                distance: -62,
                                width: 70,
                                height: 40,
                                fontFamily: 'Arial',
                                overflow: 'truncate',
                                color: [
                                    [
                                        0.25,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 10,

                                            x2: 30,

                                            y2: 11,

                                            colorStops: [
                                                {
                                                    offset: 1,
                                                    color: '#F56B6BFF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        0.5,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#FA6666FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],

                                    [
                                        1,
                                        {
                                            type: 'linear',

                                            x: 0,

                                            y: 0,

                                            x2: 0,

                                            y2: 1,

                                            colorStops: [
                                                {
                                                    offset: 0,
                                                    color: '#FFDC17FF',
                                                },
                                                {
                                                    offset: 1,
                                                    color: '#55B419FF',
                                                },
                                            ],

                                            global: false,
                                        },
                                    ],
                                ],

                                formatter: function (value) {
                                    if (value === 1) {
                                        return 'Low Risk';
                                    } else if (value === 0.8) {
                                        return 'Lower Than Avg.';
                                    } else if (value === 0.6) {
                                        return 'Average';
                                    } else if (value === 0.4) {
                                        return 'Moderate';
                                    } else if (value === 0.2) {
                                        return 'Medium';
                                    } else if (value === 0.0) {
                                        return 'High Risk';
                                    }
                                    return '';
                                },
                            },

                            title: {
                                offsetCenter: [0, '20%'],
                                fontSize: 15,
                                color: '#ccc',
                            },
                            // 仪表盘数字
                            detail: {
                                lineHeight: 126,
                                fontSize: 35,
                                offsetCenter: [1, '-22'],
                                valueAnimation: true,

                                borderColor: '#55B419FF',

                                width: 200,
                                formatter: function (value) {
                                    var a = 'd';
                                    if (Math.round(value * 100)) {
                                        a = 'A';
                                    }
                                    return that.creditOpinion;
                                },
                                color: '#55B419FF',
                            },
                            data: [
                                {
                                    value: that.creditScore,
                                    name: 'Credit Opinion',
                                },
                            ],
                        },
                        {
                            type: 'gauge',
                            startAngle: 180,
                            endAngle: 0,
                            center: ['50%', '65%'],
                            radius: '59%',
                            min: 0,
                            max: 1,
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 1,
                                    color: [
                                        [
                                            0.5,
                                            {
                                                type: 'linear',

                                                x: 0,

                                                y: 0,

                                                x2: 0,

                                                y2: 1,

                                                colorStops: [
                                                    {
                                                        offset: 0,
                                                        color: '#FFDC17FF',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#FA6666FF',
                                                    },
                                                ],

                                                global: false,
                                            },
                                        ],

                                        [
                                            1,
                                            {
                                                type: 'linear',

                                                x: 0,

                                                y: 0,

                                                x2: 0,

                                                y2: 1,

                                                colorStops: [
                                                    {
                                                        offset: 0,
                                                        color: '#FFDC17FF',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#55B419FF',
                                                    },
                                                ],

                                                global: false,
                                            },
                                        ],
                                    ],
                                },
                            },
                            pointer: {
                                icon: 'triangle',
                                length: '12%',
                                width: 20,
                                offsetCenter: [0, '-105%'],
                                itemStyle: {
                                    color: '#55B419FF',
                                },
                            },

                            data: [
                                {
                                    value: that.creditScore,
                                    detail: {
                                        show: false,
                                    },
                                },
                            ],
                        },
                    ],
                };

                const chartDom = this.$refs.chartRef[index];
                // console.log(this.$refs.chartRef[index]);
                const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
                chart.setOption(option);
                this.parentTablelist[index].chartInstance = chart;
                //  console.log(this.profitablitylist);
            });
        },
        // 点击跳转
        setid(item) {
            console.log(item);
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            // console.log(` this.showBackToTop`, this.showBackToTop );
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style scoped lang="less">
.aaa {
    font-size: 10px;
    color: #fa6666;
    position: absolute;
    left: 30px;
    top: 164px;
}
.bbb {
    font-size: 10px;
    color: #fa755c;
    position: absolute;
    left: 52px;
    top: 84px;
}
.ccc {
    font-size: 10px;
    color: #fc9249;
    position: absolute;
    left: 110px;
    top: 34px;
}
.ddd {
    font-size: 10px;
    color: #ffd81a;
    position: absolute;
    left: 258px;
    top: 34px;
}
.fff {
    font-size: 10px;
    color: #aec918;
    position: absolute;
    left: 325px;
    width: 80px;
    height: 30px;
    top: 84px;
}
.ggg {
    font-size: 10px;
    color: #63b819;
    position: absolute;
    left: 345px;

    top: 164px;
}
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.container {
    // background: #ffffff;
    // box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    // border-radius: 14px;
    // padding: 20px 17px;
    margin-top: 69px;
}

.zhongjian {
    display: flex;
    justify-content: center;
}

.td-width-span {
    flex: 1;
}

.td-width-txt {
    display: flex;
    justify-content: center;
}

.Company Snapshot {
}

.Country Data {
}

.update {
    width: 90px;
    height: 30px;
    background: #1290c9;
    border-radius: 8px;
    color: white;
    position: relative;
    top: 73px;
    right: 20px;
}

.title {
    // margin-bottom: 10px;
    display: flex;
    align-items: center;

    justify-content: space-between;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 31px;
}

.el-card.is-always-shadow {
    box-shadow: none;
    padding-top: 20px;
}

// 卡片
/deep/ .el-card {
    // border: 1px solid #EBEEF5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    border: none;

    .el-card__body {
        padding: 3px;
        display: flex;
        flex-wrap: wrap;
        width: 508px;
        justify-content: space-between;

        .item {
            display: flex;
            padding: 15px 35px 15px 20px;

            height: 70px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
            border-radius: 14px;
            margin: 0 0px 20px 0;

            .card-text {
                margin-left: 10px;
                display: flex;
                width: 135px;
                flex-wrap: wrap;

                .text-head {
                    width: 135px;
                    height: 16px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8497ab;
                }

                .text-foot {
                    width: 110px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Arial-BoldMT, Arial;
                    font-weight: normal;
                    color: #022955;
                    margin-top: 8px;
                }
            }
        }
    }
}

.td {
    margin: 15px 0 0 25px;
}

// 表格

.table_box {
    display: flex;
    justify-content: space-between;
    position: relative;

    .echarts {
        width: 420px;
        height: 260px;
    }
}
.footer {
    margin-top: 20px;
    padding: 20px;

    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}

.ecahrtstu {
    margin-top: 16px;
}
/deep/ .table-biao {
    height: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #e7eaed;
}
.infinite-list-wrapper {
    // height: 500px;
    padding: 4px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
@media (max-width: 821px){
.aaa{
    left: -10px !important;
}
.bbb{
    left: 22px !important;
}
.ccc{
    left: 80px !important;
}
.ddd{
    left: 228px !important;
}
.fff{

    left: 285px !important; 
}
.ggg{

    left: 310px !important; 
}
.echarts {
        width: 375px !important;
        height: 260px;
        position: relative;
        left: -10px !important;
    }
 .table_box{
    flex-wrap: wrap ;
 }  
.item{
    padding: 5px !important;
    align-items: center;
}
  .footer{
    padding: 10px !important;
  }
 /deep/ .el-card .el-card__body {
    width: 100% !important;
   }
 .card-text
   {
       margin-left: 5px !important;
     }
.container{
  margin-top: 20px !important;
}
   /deep/ .el-card .el-card__body{
    justify-content: space-between ;
        width: 99% !important;
   
    padding: 10px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
       margin: auto;
    margin-bottom: 20px !important;
     


}
 /deep/ .el-card .el-card__body .item {
      width: 100%;
    padding: 10px 2px !important;
    margin: 0px !important;
    /* border: 1px; */
    border-bottom: 1px solid #e0dce5;
    border-radius: 0px !important;
    box-shadow: none !important;
    height: auto !important;

 }
 /deep/ .el-card .el-card__body .item .card-text{
        margin-left: 0px !important; 
    display: flex;
    /* width: 110px; */
   flex-wrap: nowrap !important; 
    width: 100% !important;
    justify-content: space-between;
    align-items: center;
    
  

    text-align: right !important;
 }
  /deep/  .el-card .el-card__body .item .card-text .text-head  {
    width: 150px !important;
text-align: left;
color: #022955 !important;
font-size: 14px !important;
  }
}
</style>