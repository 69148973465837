// 用户中心模块

import axios from './request'
//定义跨域代理路由
const currentUrl = window.location.href;
const regex = /www\./;
const mobil = /mobil\./
const phone =mobil.test(currentUrl) 
const iswww= regex.test(currentUrl) 
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://140.179.61.9:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.91.97:8099/dev-api'//new-测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://52.81.219.235:8088/test-api' //测试
// let  api = process.env.NODE_ENV == 'development' ? '/devapi' : 'http://3.101.104.100:8088/prod-api' 
let api = process.env.NODE_ENV == 'development' ? '/devapi' :iswww?'https://www.csibizsearch.com/prod-api': 'https://csibizsearch.com/prod-api' //生产
if(phone){
  api= process.env.NODE_ENV == 'development' ? '/devapi' : 'https://mobil.csibizsearch.com/prod-api' //生产
}


//个人中心统计仪表板
export const getDashboard = params => {
  return axios.get(api + '/gleagle/csiv4/getDashboard', params)
}
//个人中心工作台列表
export const getLoginGlance = params => {
  return axios.get(api + '/gleagle/csiv4/getLoginGlance?'+ params)
}
export const getOrdersPayList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersPayList', params)
}
export const editUserBalan = params => {
  return axios.post(api + '/gleagle/userBalance/editUserBalanceIntegral', params)
}
export const applyUserBalan = params => {
  return axios.post(api + '/gleagle/userBalance/applyUserBalanceIntegral', params)
}
export const getOrderList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersList', params)
}
export const getOrderview = params => {
  return axios.post(api + '/gleagle/orders/view', params)
}

export const getRechargeList = params => {
  return axios.post(api + '/gleagle/orders/getUserPayList', params)
}
export const getLoginDuration = params => {
  return axios.get(api + '/gleagle/csiv4/getLoginDuration?'+ params)
}
export const getLogDurDetails = params => {
  return axios.get(api + '/gleagle/csiv4/getLogDurDetails?'+ params)
}
//线上报告pdf
export const getPdfAddress = params => {
  return axios.get(api + '/gleagle/file/getPdfAddress/'+ params)
}
// 香港pdf
export const getPdfhk_pdf = params => {
  return axios.get(api + '/gleagle/file/pdf/' + params)
}
//是否购买报告
export const getPdfPurchase = params => {
  return axios.get(api + '/gleagle/orders/purchase?'+ params,{  isLoading: true})
}

//个人中心账户设置
export const settingAccount = params => {
  return axios.post(api + '/gleagle/csiv4/settingsAccount', params)
}
//个人中心用户公司信息
export const updateAccount = params => {
  return axios.post(api + '/gleagle/csiv4/updateAccount', params)
}
// 个人用户账单信息
export const selectInfo = params => {
  return axios.get(api + '/gleagle/common/verifyCompanyBilling/selectInfo' )
}
// 个人用户账单新增信息
export const saveInfo = params => {
  return axios.post(api + '/gleagle/common/verifyCompanyBilling/saveInfo', params)
}
// 个人用户账单修改信息
export const updateInfo = params => {
  return axios.post(api + '/gleagle/common/verifyCompanyBilling/updateInfo', params)
}
//个人中心联系人信息
export const updateContact = params => {
  return axios.post(api + '/gleagle/csiv4/updateContact', params)
}

export const getpersonnel = params => {
  return axios({
    url: api + '/gleagle/people/searchDetail',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export const getCommonAssist = params => {
  return axios({
    url: api + '/gleagle/common/assist',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 个人中心工作台列表3和我的数据页面
export const register = params => {
  return axios.post(api + '/gleagle/user/addUser', params)
}
export const getOrdersList = params => {
  return axios.post(api + '/gleagle/orders/getOrdersList', params)
}
// 个人中心我的足迹
export const getPortfolio = params => {
  return axios({
    url: api + '/gleagle/csiv4/getPortfolio',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 收藏列表

export const getCollectionList = params => {
  return axios({
    url: api + '/gleagle/csiv4/getCollectionList',
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 获取发票pdg
export const getPdf = params => {
  return axios.get(api + '/gleagle/file/getPdfAddress/'+ params)
}
//
export const getOrderInvoiceList = params => {
  return axios.post(api + '/gleagle/orders/getOrderInvoiceList', params)
}
//个人中心-groups-分页列表
export const getGroupList = params => {
  return axios.post(api + '/gleagle/common/deptInfo/getGroupList', params)
}
export const getPermissionList = params => {
  return axios.get(api + '/gleagle/common/role/getPermissionList', params)
}
export const getRoleList = params => {
  return axios.post(api + '/gleagle/common/role/getRoleList', params)
}
export const addRole = params => {
  return axios.post(api + '/gleagle/common/role/addRole', params)
}
export const editRoleInfo = params => {
  return axios.post(api + '/gleagle/common/role/editRoleInfo', params)
}
export const getaddGroup = params => {
  return axios.post(api + '/gleagle/common/deptInfo/addGroup', params)
}
export const getGroupTreeList = params => {
  return axios.get(api + '/gleagle/common/deptInfo/getGroupTreeList', params)
}
// 修改状态
export const editSonUserInfoStatus = params => {
  return axios.post(api + '/gleagle/userInfo/editSonUserInfoStatus', params)
}
export const editSonUserInfo = params => {
  return axios.post(api + '/gleagle/userInfo/editSonUserInfo', params)
}
export const addSonUserInfo = params => {
  return axios.post(api + '/gleagle/userInfo/addSonUserInfo', params)
}
export const getUserList = params => {
  return axios.post(api + '/gleagle/userInfo/getSonUserInfoList', params)
}
export const getRoleById = params => {
  return axios.get(api + '/gleagle/common/role/getRoleById?'+ params)
}
// 我的服务列表
export const creditServiceList = params => {
  return axios.post(api + '/gleagle/creditService/creditServiceList', params)
}
// 服务列表查询条件
export const getTypeAndStatusList = params => {
  return axios.post(api + '/gleagle/creditService/getTypeAndStatusList', params)
}
// dabase统计
export const getMyDataBaseCount = params => {
  return axios.get(api + '/gleagle/orders/getMyDataBaseCount', params )
}
// Portfolio统计
export const getPortfolioCount = params => {
  return axios.get(api + '/gleagle/collection/getPortfolioCount', params)
}
// 封装websouct连接接口
export const createWebSocketConnection = (usercode) => {
  return new Promise((resolve, reject) => {
// 测试
    // const url = `ws://52.81.219.235:8088/webSocket/gleagle/${usercode}`;
    // 生产
    const url = `wss://csibizsearch.com/webSocket/gleagle/${usercode}`;
    const socket = new WebSocket(url);

    // socket.onopen = () => {
    //   console.log('WebSocket连接已建立');
      // window.addEventListener('beforeunload', (item) => {
        // socket.close()
     
     
     
      // });
   
      resolve(socket);

    // };
    const socketInfo = {
      url: socket.url,
      readyState: socket.readyState,
      // 其他需要存储的信息
    };
    // console.log(socketInfo);
        sessionStorage.setItem('socket',JSON.stringify(socketInfo))
   

    socket.onmessage = (event) => {
      console.log('收到消息:', event.data);
    };

    socket.onerror = (event) => {
      console.error('WebSocket错误:', event);
      reject(event);
    };
    // socket.close() 关闭连接
  
  });
};